import i18next from 'i18next'

import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { ResponseError, dashboardClient, getErrorText } from '../../../api'
import { Button, FieldValidationMessage, Modal, ModalFooter, ModalProps, ModalTitle } from '@/ui'
import StyledDatePicker from '../../../components/StyledDatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import { InfoCircleIcon } from '../../../icons/Icons'
import styled from '@emotion/styled'

const Info = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 24px;
  color: ${p => p.theme.palette.text.secondary};
`

const InfoText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`

const Step1 = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  flex-direction: column;
  gap: 20px;
  text-align: center;
`

export interface GenerateReportDialogProps extends ModalProps {}

const GenerateReportDialog = (props: GenerateReportDialogProps) => {
  const { ...rest } = props
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const theme = useTheme()
  const [from, setFrom] = useState<Dayjs>(dayjs(new Date()).add(-1, 'month'))
  const [to, setTo] = useState<Dayjs>(dayjs(new Date()))
  const [step, setStep] = useState<number>(0)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const okClick = async () => {
    try {
      if (from.toDate().getTime() > to.toDate().getTime()) {
        setErrorMessage(i18next.t('validation.start_date'))
        return
      }

      await dashboardClient.v1.exportTransactions(companyId, gameId, {
        start: from.format('YYYY-MM-DD'),
        end: to.format('YYYY-MM-DD'),
      })
      setStep(1)
    } catch (e) {
      let b = e as ResponseError
      if (b.status == 429) {
        setErrorMessage(i18next.t('generate-transaction.error.429'))
      } else {
        setErrorMessage(getErrorText(b))
      }
      setStep(1)
    }
  }

  const renderStep0 = () => {
    return (
      <>
        <div className="mt-6 flex flex-col">
          <div className="flex gap-4">
            <StyledDatePicker
              label={i18next.t('transactions-table.generate-report.from')}
              value={from.format('YYYY-MM-DD')}
              onChange={value => setFrom(dayjs(value))}
            />

            <StyledDatePicker
              label={i18next.t('transactions-table.generate-report.to')}
              value={to.format('YYYY-MM-DD')}
              onChange={value => setTo(dayjs(value))}
            />
          </div>
          {errorMessage && <FieldValidationMessage>{errorMessage}</FieldValidationMessage>}
          <Info>
            <div>
              <InfoCircleIcon />
            </div>
            <InfoText>{i18next.t('generate-transaction.desc')}</InfoText>
          </Info>
        </div>

        <ModalFooter>
          <Button onClick={() => props.onClose?.()} style={{ width: '50%', height: '46px' }}>
            {i18next.t('Cancel')}
          </Button>
          <Button onClick={okClick} size="md" variant="primary" style={{ width: '50%', height: '46px' }}>
            {i18next.t('transactions-table.generate-report')}
          </Button>
        </ModalFooter>
      </>
    )
  }

  const renderStep1 = () => {
    let color = errorMessage ? theme.palette.warning.dark : theme.palette.primary.main

    return (
      <>
        <div className="mt-6">
          <Step1>
            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.3">
                <path
                  d="M6 23C6 13.6112 13.6112 6 23 6C32.3888 6 40 13.6112 40 23C40 32.3888 32.3888 40 23 40C13.6112 40 6 32.3888 6 23Z"
                  stroke={color}
                  strokeWidth="2"
                />
              </g>
              <g opacity="0.1">
                <path
                  d="M1 23C1 10.8497 10.8497 1 23 1C35.1503 1 45 10.8497 45 23C45 35.1503 35.1503 45 23 45C10.8497 45 1 35.1503 1 23Z"
                  stroke={color}
                  strokeWidth="2"
                />
              </g>
              <path
                d="M22.9999 18.3333V22.9999M22.9999 27.6666H23.0116M34.6666 22.9999C34.6666 29.4432 29.4432 34.6666 22.9999 34.6666C16.5566 34.6666 11.3333 29.4432 11.3333 22.9999C11.3333 16.5566 16.5566 11.3333 22.9999 11.3333C29.4432 11.3333 34.6666 16.5566 34.6666 22.9999Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <div>
              {errorMessage ? (
                <>
                  <div style={{ fontWeight: '600' }}>{i18next.t('generate-transaction.error.429')}</div>
                  <div>{i18next.t('generate-transaction.error.429.desc')}</div>
                </>
              ) : (
                <>
                  <div style={{ fontWeight: '600' }}>{i18next.t('generate-transaction.generating-report')}</div>
                  <div>{i18next.t('generate-transaction.check-email')}</div>
                </>
              )}
            </div>
          </Step1>
        </div>

        <ModalFooter>
          <Button
            onClick={() => props.onClose?.()}
            variant="primary"
            size="md"
            style={{ width: '100%', height: '46px' }}
          >
            Ok
          </Button>
        </ModalFooter>
      </>
    )
  }

  return (
    <Modal {...rest}>
      <ModalTitle>{i18next.t('generate-transaction-registry')}</ModalTitle>

      {step == 0 && renderStep0()}
      {step == 1 && renderStep1()}
    </Modal>
  )
}

export default GenerateReportDialog
