import { AriaRole, useContext, useEffect, useState } from 'react'
import { CalcPlayerAttributes, CheckoutUser, User, UserPaymentMethod } from '@/api/dashboard'
import { dashboardClient } from '@/api'
import { Link, generatePath, useParams } from 'react-router-dom'
import i18next from 'i18next'
import { PropColumn } from './components/TableProperty'
import { usePlayerSegmentQuery } from './api/usePlayerSegmentQuery'
import AnimatedDots from '../../components/animated-dots/AnimatedDots'
import UserCountry from './components/UserCountry'
import { MenuButton, ToastSeverity, useModal, useToast } from '@/ui'
import SegmentBadge from '@/layouts/segment/components/SegmentBadge'
import { Country, DotsHorizontal, Edit03, Email, GamePad, Segment, SlashCircle02, User as UserIcon } from '@/icons'
import { AuthContext } from '@/Context'
import { canEdit } from '@/security'
import EditPlayerModal from '@/layouts/player/widgets/EditPlayerModal'
import { usePlayerUpdateMutate } from '@/layouts/player/api'
import { LabelCopyButton } from '@/components/ui/LabelCopyButton/LabelCopyButton'
import BigPayMethodView from '@/layouts/player/components/BigPayMethodView'
import { BillingAddress } from '@/layouts/player/components/BillingAddress'
import { GAME_PLAYERS_DETAILS_PATH } from '@/libs'
import { useTranslation } from 'react-i18next'
import { PlayerMeasureValue, PlayerMeasureValueType } from '@/layouts/player/components/PlayerMeasureValue'
import RPBalanceView from '@/layouts/player/components/RPBalanceView'
import { PlayerStatus } from '@/layouts/player/components'

export default function PlayerCard(props: {
  user: User
  fullUser?: User
  checkoutUsers?: CheckoutUser[]
  calc_attributes?: CalcPlayerAttributes
  showMeasures?: boolean
  payLinkUserId?: string | undefined
}) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const showToast = useToast()
  const { t } = useTranslation()
  const { mutateAsync } = usePlayerUpdateMutate(companyId, gameId)
  const context = useContext(AuthContext)
  const canUserEdit = canEdit(context?.customer)
  const [payMethods, setPayMethods] = useState<UserPaymentMethod[]>([])
  const { data: userSegments = [], isLoading: isSegmentLoading } = usePlayerSegmentQuery(
    companyId,
    gameId,
    props.user.id,
  )

  const loadData = async () => {
    try {
      if (props.payLinkUserId) {
        let { data } = await dashboardClient.v1.getUserPaymentMethods(props.payLinkUserId, companyId, gameId)
        setPayMethods(data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const openEditPlayerModal = useModal(p => <EditPlayerModal userId={props.user.id} {...p} />)

  useEffect(() => {
    loadData()
  }, [props.payLinkUserId, props.user])

  const onClickBan = async () => {
    await mutateAsync({ userId: props.user.id, update: { banned: !props.user.banned } })
    props.user.banned = !props.user.banned // need to update full user object
    showToast({ message: t('saved'), severity: ToastSeverity.success })
  }

  const renderDivider = () => {
    return <div className="my-4 border-t border-border-primary" />
  }

  const renderPaymentMethods = () => {
    if (!props.payLinkUserId || !payMethods.length) {
      return <></>
    }
    return (
      <>
        {renderDivider()}
        <div className="ph-no-capture">
          <div className="text-paragraph-lg font-semibold text-text-secondary">
            {i18next.t('transaction-details.saved-payment-methods')}
          </div>
          <div className="mt-3 flex flex-wrap gap-3">
            {payMethods.map(it => (
              <BigPayMethodView method={it} key={it.id} />
            ))}
          </div>
        </div>
      </>
    )
  }

  const renderCheckoutProfiles = () => {
    if (!props.checkoutUsers?.length) {
      return <></>
    }
    return (
      <>
        {renderDivider()}
        <div className="ph-no-capture">
          <div className="text-paragraph-lg font-semibold text-text-secondary">
            {i18next.t('player.card.bonus-funds')}
          </div>
          <div className="mt-3 flex flex-wrap gap-3">
            {props.checkoutUsers.map(it => (
              <RPBalanceView user={it} />
            ))}
          </div>
        </div>
      </>
    )
  }

  const renderMeasures = () => {
    if (!props.showMeasures) {
      return <></>
    }

    const attr = props.calc_attributes

    return (
      <>
        <div className="flex gap-6">
          <PlayerMeasureValue
            label={t('player.card.ltv')}
            value={attr?.ltv || 0}
            type={PlayerMeasureValueType.money}
            isLoading={!attr}
          />
          <PlayerMeasureValue
            label={t('player.card.average_check')}
            value={attr?.average_check || 0}
            type={PlayerMeasureValueType.money}
            isLoading={!attr}
          />
          <PlayerMeasureValue
            label={t('player.card.total_order_count')}
            value={props.fullUser?.total_order_count || 0}
            isLoading={!attr}
          />
          <PlayerMeasureValue
            label={t('player.card.purchases_count')}
            value={attr?.purchases_count || 0}
            isLoading={!attr}
          />
          {props.user.lp_balance != null && (
            <PlayerMeasureValue
              label={t('player.card.lp')}
              value={props.user.lp_balance}
              isLoading={!attr}
              type={PlayerMeasureValueType.lp}
            />
          )}
        </div>
        <div className="my-4 border-t border-border-primary" />
      </>
    )
  }

  const renderGeneral = () => {
    return (
      <div className="flex items-center gap-6">
        <img
          src={props.user.avatar_url || '/icons/default-avatar.svg'}
          onError={e => ((e.target as HTMLImageElement).src = '/icons/default-avatar.svg')}
          alt="avatar"
          style={{ height: '98px', width: '98px', overflow: 'hidden', borderRadius: '100%' }}
        />

        <div className="flex flex-col gap-1">
          <div className="font-medium text-text-tertiary">{t('player.name')}</div>
          <div className="flex items-center gap-3 text-title-t3 font-semibold text-text-primary">
            <Link to={generatePath(GAME_PLAYERS_DETAILS_PATH, { companyId, gameId, userId: props.user.id })}>
              {props.user.email || props.user.name || props.user.player_id}
            </Link>
            {props.user.banned && <PlayerStatus isBanned={true} />}
          </div>
        </div>

        <div className="ml-auto flex gap-2">
          {canUserEdit && (
            <MenuButton
              className="w-[38px]"
              onChange={v => {
                switch (v) {
                  case 'edit':
                    openEditPlayerModal()
                    break
                  case 'ban':
                    onClickBan()
                    break
                }
              }}
              options={[
                {
                  value: 'edit',
                  children: i18next.t('player.card.edit'),
                  icon: <Edit03 />,
                },
                {
                  icon: <SlashCircle02 />,
                  value: 'ban',
                  children: i18next.t(props.user?.banned ? 'players.unban' : 'players.ban'),
                },
              ]}
            >
              <DotsHorizontal />
            </MenuButton>
          )}
        </div>
      </div>
    )
  }

  const renderUserProps = () => {
    let segments = (
      <div className="flex flex-wrap gap-1.5">
        {userSegments
          .filter(it => it.segment)
          .map(it => (
            <SegmentBadge key={it.segment_id} segment={it.segment!} />
          ))}
      </div>
    )

    let tableProps = [
      {
        icon: <UserIcon size={17} />,
        label: i18next.t('transaction-details.user-id'),
        value: <LabelCopyButton value={props.user.id} />,
      },
      {
        icon: <GamePad size={17} />,
        label: i18next.t('transaction-details.player-id'),
        value: <LabelCopyButton value={props.user.player_id} />,
      },
      {
        icon: <Country size={17} />,
        label: i18next.t('transaction-details.country'),
        value: <UserCountry country={props.user.country} />,
      },
      {
        icon: <Email size={17} />,
        label: i18next.t('transaction-details.email'),
        value: props.user.email,
      },
      {
        icon: <Segment size={17} />,
        label: i18next.t('transaction-details.segments'),
        value: isSegmentLoading ? <AnimatedDots /> : segments,
      },
    ] as PropColumn[]

    return (
      <div>
        <div className="text-paragraph-lg font-semibold text-text-tertiary">{i18next.t('player-details.title')}</div>
        <table className="ph-no-capture mt-4">
          <tbody>
            {tableProps
              .filter(it => !!it)
              .map((row, idx) => (
                <tr key={idx} className="h-[44px]">
                  <td className="w-[200px] align-middle text-caption-md font-semibold text-text-secondary">
                    <div className="flex h-full items-center gap-3">
                      {row.icon}
                      <div className="leading-tight">{row.label}</div>
                    </div>
                  </td>
                  <td className="w-[450px] whitespace-normal align-middle font-[14px]">
                    <div
                      className="flex h-full items-center font-medium text-text-secondary"
                      role={row.label as AriaRole}
                    >
                      {row.value}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className="rounded-2xl border border-border-primary bg-fg-secondary p-6">
      {renderGeneral()}
      {renderDivider()}
      {renderMeasures()}

      {renderUserProps()}

      {props.payLinkUserId && <BillingAddress payMethods={payMethods} />}

      {renderPaymentMethods()}

      {renderCheckoutProfiles()}
    </div>
  )
}
