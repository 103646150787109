import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Button, Skeleton } from '@dashboard/ui'
import { LocaleStatus } from '@/api/dashboard'
import { AuthContext, IAuthContext } from '@/Context'
import { canEdit } from '@/security'
import { Page, PageHeader, PageToolbar, Sidebar, SidebarLinkSkeleton, TableZeroState, useModal } from '@/ui'
import { AiTranslateOutline, Plus } from '@/icons'
import { LOCALIZATION_LOCALE_PATH, useGenerateCompanyPath } from '@/libs'
import { localeQueryOptions, localesQueryOptions } from '../api'
import { TransactionRowItemSkeleton } from '../components'
import { useLocaleTranslateAllListener } from '../hooks'
import { L10nLocaleModalAdd, L10nLocaleModalConfirmTranslateAll, L10nLocaleModalPublish, L10nSidebar } from '../widgets'

export const L10nOutlet = () => {
  useLocaleTranslateAllListener()

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { companyId, gameId, localeId, generatePath } = useGenerateCompanyPath<{
    localeId: string
  }>()
  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = canEdit(context.customer)

  const { data: locales = [], isLoading: isLoadingLocales } = useQuery(localesQueryOptions(companyId, gameId))

  const { data: locale } = useQuery({
    ...localeQueryOptions(companyId, gameId, localeId),
    enabled: !!localeId,
  })

  useEffect(() => {
    if (!localeId && locales.length) {
      navigate(
        generatePath(LOCALIZATION_LOCALE_PATH, {
          localeId: locales.at(0)?.id || '',
        }),
      )
    }
  }, [navigate, localeId, generatePath, locales])

  const openLocalModalAdd = useModal(props => <L10nLocaleModalAdd {...props} />)

  const openModalConfirmTranslateAll = useModal(
    props => locale && <L10nLocaleModalConfirmTranslateAll locale={locale} {...props} />,
  )

  const openModalPublish = useModal(props => locale && <L10nLocaleModalPublish locale={locale} {...props} />)

  const isDisabledLocaleButtons =
    !locale || locale.status === LocaleStatus.Synchronization || locale.status === LocaleStatus.Translating

  if (isLoadingLocales) {
    return (
      <Page>
        <PageHeader>{t('localization.title')}</PageHeader>
        <div className="grid h-full grid-cols-[calc(232px+0.75rem*2)_1fr] items-start">
          <div>
            <Sidebar>
              <SidebarLinkSkeleton />
              <SidebarLinkSkeleton />
              <SidebarLinkSkeleton />
              <SidebarLinkSkeleton />
              <SidebarLinkSkeleton />
            </Sidebar>
          </div>
          <div className="relative h-full rounded-md border border-border-secondary">
            <PageToolbar>
              <Skeleton className="my-1 h-6 w-1/3" />
            </PageToolbar>
            <div className="h-[44px]" />
            {new Array(1).fill('').map((_, index) => (
              <TransactionRowItemSkeleton key={`skeleton-${index}`} />
            ))}
          </div>
        </div>
      </Page>
    )
  }

  if (locales.length === 0) {
    return (
      <Page>
        <PageHeader>{t('localization.title')}</PageHeader>
        <TableZeroState
          className="w-full"
          title={t('localization.zero.title')}
          message={t('localization.zero.text')}
          buttons={
            canUserEdit && (
              <Button type="button" onClick={() => openLocalModalAdd()}>
                <Plus size={18} />
                <span>{t('localization.zero.add')}</span>
              </Button>
            )
          }
        />
      </Page>
    )
  }

  return (
    <Page>
      <PageHeader
        extra={
          <>
            <Button type="button" disabled={isDisabledLocaleButtons} onClick={() => openModalConfirmTranslateAll()}>
              <AiTranslateOutline size={18} />
              <span>{t('localization.locale.translate-all')}</span>
            </Button>
            <Button
              variant="outline"
              type="button"
              disabled={isDisabledLocaleButtons}
              onClick={() => openModalPublish()}
            >
              {t('localization.locale.publish')}
            </Button>
          </>
        }
      >
        {t('localization.title')}
      </PageHeader>

      <div className="grid h-full grid-cols-[calc(232px+0.75rem*2)_1fr] items-start">
        <L10nSidebar />
        <div className="relative h-full rounded-md border border-border-secondary">
          <Outlet />
        </div>
      </div>
    </Page>
  )
}
