import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { Spinner, useSearchFilter } from '@dashboard/ui'
import { Locale, LocaleStatus } from '@/api/dashboard'
import { Page, PaginationInfiniteObserver } from '@/ui'
import { getMotionProps, groupBy, useGenerateCompanyPath } from '@/libs'
import { TranslationsQueryParams, localeQueryOptions, translationsInfiniteQueryOptions } from '../api'
import {
  LocaleZeroState,
  TransactionRowItemSkeleton,
  TranslationRowGroup,
  TranslationRowHeader,
  TranslationRowItem,
  TranslationRowType,
} from '../components'
import { getGroupPreview, mapTypeIcon } from '../libs'
import { L10nLocaleModalAiTranslate, L10nLocaleToolbar } from '../widgets'
import { L10nListFilter } from '../types'

const listMotionProps = getMotionProps(
  {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  },
  { duration: 0.1, delay: 0.1 },
)

const spinnerMotionProps = getMotionProps(
  {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  },
  { delay: 0.15 },
)

export const L10nLocalePage = () => {
  const { t } = useTranslation()
  const { companyId, gameId, localeId } = useGenerateCompanyPath<{ localeId: string }>()
  const filter = useSearchFilter<L10nListFilter>()

  const { data: locale, isLoading: isLoadingLocale } = useQuery(localeQueryOptions(companyId, gameId, localeId))

  const {
    data = { pages: [] },
    isLoading: isLoadingTranslations,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    translationsInfiniteQueryOptions(companyId, gameId, {
      ...(filter.values as Partial<TranslationsQueryParams>),
      locale_id: localeId,
      limit: 50,
    }),
  )

  const groupedTranslations = useMemo(
    () => groupBy(data?.pages.map(p => p.data).flat(), item => `${item.object_id}`),
    [data],
  )

  const featuredTranslations = useMemo(
    () => Object.entries(groupBy(Object.entries(groupedTranslations), ([_, item]) => item.at(0)?.object_type || '')),
    [groupedTranslations],
  )

  const onClickClearFilter = () => {
    filter.onChange({})
  }

  return (
    <Page>
      <Outlet />

      <L10nLocaleToolbar filter={filter} />

      <TranslationRowHeader
        locale={t(`locales.${Locale.En}`)}
        targetLocale={locale ? t(`locales.${locale.locale}`) : ''}
      />

      <AnimatePresence key={`l10n-locale-${localeId}-${JSON.stringify(filter)}`} initial={false} mode="popLayout">
        {isLoadingLocale || isLoadingTranslations || !locale ? (
          <motion.ul {...listMotionProps} key="skeleton">
            {new Array(1).fill('').map((_, index) => (
              <TransactionRowItemSkeleton key={`skeleton-${index}`} />
            ))}
          </motion.ul>
        ) : (
          <motion.ul {...listMotionProps} key="list" role="listbox" className="relative flex h-full flex-col">
            {locale.status === LocaleStatus.Translating && <L10nLocaleModalAiTranslate locale={locale} />}

            {featuredTranslations.length === 0 && <LocaleZeroState key="zero-state" onClear={onClickClearFilter} />}

            {featuredTranslations.map(([type, groups]) => (
              <TranslationRowType
                key={`translation-row-type-${type}`}
                icon={type ? mapTypeIcon.get(type) : mapTypeIcon.get(undefined)}
                label={t(`localization.translation.type.${type.toLowerCase()}`, { count: Infinity })}
              >
                {Object.entries(groups).map(([_, [objectId, translations]]) => {
                  const first = translations.at(0)
                  return (
                    <TranslationRowGroup
                      key={`group-${objectId}`}
                      group={t(`localization.translation.type.${first?.object_type.toLowerCase()}`, { count: 1 })}
                      preview={getGroupPreview(translations)}
                    >
                      {translations.map(translation => (
                        <TranslationRowItem
                          key={`translation-${translation.cursor_id}`}
                          translation={translation}
                          locale={locale}
                        />
                      ))}
                    </TranslationRowGroup>
                  )
                })}
              </TranslationRowType>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>

      {!isLoadingTranslations && isFetchingNextPage && (
        <motion.div {...spinnerMotionProps} className="flex shrink-0 justify-center py-4 text-text-disabled">
          <Spinner size={16} />
        </motion.div>
      )}

      {!isLoadingTranslations && <PaginationInfiniteObserver onNextPage={fetchNextPage} />}
    </Page>
  )
}
