import { useContext, useState } from 'react'
import { Skeleton } from '@mui/material'
import { useParams } from 'react-router-dom'
import LeaderBoardDialog from './LeaderBoardDialog'
import { canEdit } from '../../security'
import { LeaderboardRead, LeaderboardVisibility } from '../../api/dashboard'
import {
  Badge,
  Button,
  ConfirmDialog,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableRowEditButton,
  TableZeroState,
  Tooltip,
  usePagination,
} from '@/ui'
import { useLeaderboardQuery } from './api'
import { EditIcon } from '../../icons/Icons'
import { Plus, Trash } from '@/icons'
import { useLeaderboardDeleteMutate } from './api/useLeaderboardDeleteMutate'
import { DateTimeValue } from '@/components/ui/DateTimeValue'
import { AuthContext, IAuthContext } from '@/Context'
import { useTranslation } from 'react-i18next'

export default function LeaderBoardTable() {
  const { t } = useTranslation()
  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = canEdit(context.customer)
  const [editItem, setEditItem] = useState<LeaderboardRead | null>(null)
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  const { page, query, onPageChange } = usePagination(20)
  const { data: items = [], isLoading } = useLeaderboardQuery(companyId, gameId, query)
  const { mutateAsync: deleteMutateAsync } = useLeaderboardDeleteMutate(companyId, gameId)
  const [confirmDeleteItem, setConfirmDeleteItem] = useState<LeaderboardRead | null>(null)

  const onDeleteGameItemClick = async () => {
    if (confirmDeleteItem) {
      setConfirmDeleteItem(null)
      await deleteMutateAsync({ id: confirmDeleteItem.id })
    }
  }

  return (
    <div className="flex h-full flex-col">
      {editItem && (
        <LeaderBoardDialog
          item={editItem}
          onClose={() => {
            setEditItem(null)
          }}
        />
      )}

      <PageHeader
        extra={
          canUserEdit &&
          !!items.length && (
            <Button variant="primary" type="button" onClick={() => setEditItem({} as LeaderboardRead)}>
              {t('leaderboard.add-button')}
            </Button>
          )
        }
      >
        {t('sidebar.leaderboard')}
      </PageHeader>

      {confirmDeleteItem && (
        <ConfirmDialog
          color={'error'}
          confirmButtonText={t('remove')}
          subMessage={t('confirm.text', { name: confirmDeleteItem.name })}
          onCancel={() => setConfirmDeleteItem(null)}
          onConfirm={onDeleteGameItemClick}
        />
      )}

      {isLoading ? (
        new Array(query.limit).fill('').map((_, index) => (
          <TableRow key={index}>
            <TableCell width="200%">
              <Skeleton variant="rounded" width="100%" height={24} style={{ margin: '7px 0' }} />
            </TableCell>
            <TableCell width="100%">
              <Skeleton variant="rounded" width="100%" height={24} />
            </TableCell>
            <TableCell width="50%">
              <Skeleton variant="rounded" width="100%" height={24} />
            </TableCell>
            <TableCell width="10%">
              <Skeleton variant="rounded" width="100%" height={24} />
            </TableCell>
          </TableRow>
        ))
      ) : items.length === 0 ? (
        <TableZeroState
          title={t('leaderboard.zero.title')}
          message={t('leaderboard.zero.message')}
          buttons={
            <>
              {canUserEdit && (
                <Button variant="primary" onClick={() => setEditItem({} as LeaderboardRead)}>
                  <Plus size={14} />
                  <span>{t('leaderboard.zero.button-add')}</span>
                </Button>
              )}
              <a href="https://docs.aghanim.com/advanced/leaderboards/overview" target="_blank">
                <Button variant="tertiary-gray">{t('leaderboard.zero.learn-more')}</Button>
              </a>
            </>
          }
        />
      ) : (
        <>
          <Table>
            <TableRow variant="header">
              <TableCell width="180%">{t('leaderboard.name')}</TableCell>
              <TableCell width="60%">{t('leaderboard.starts_at')}</TableCell>
              <TableCell width="60%">{t('leaderboard.ends_at')}</TableCell>
              <TableCell width="35%">{t('leaderboard.visibility')}</TableCell>
              <TableCell width="15%" />
            </TableRow>

            {items.map((it, idx) => (
              <TableRow key={idx}>
                <TableCell width="180%">
                  <div className="flex flex-col gap-1">
                    <span className="text-caption-md text-text-secondary">{it.name}</span>
                    <span className="text-caption-sm text-text-tertiary">{it.description}</span>
                  </div>
                </TableCell>
                <TableCell width="60%">
                  <DateTimeValue value={it.start_at} />
                </TableCell>
                <TableCell width="60%">
                  <DateTimeValue value={it.end_at} />
                </TableCell>
                <TableCell width="35%">
                  {it.visibility === LeaderboardVisibility.General && (
                    <Tooltip message={t('leaderboard.general.tooltip')}>
                      <div>
                        <Badge variant="lime-secondary">{t('leaderboard.general')}</Badge>
                      </div>
                    </Tooltip>
                  )}
                  {it.visibility === LeaderboardVisibility.Personal && (
                    <Tooltip message={t('leaderboard.personal.tooltip')}>
                      <div>
                        <Badge variant="violet-secondary">{t('leaderboard.personal')}</Badge>
                      </div>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell width="15%">
                  <div className="text-right">
                    {canUserEdit && (
                      <TableRowEditButton
                        onChange={v => {
                          switch (v) {
                            case 'edit':
                              setEditItem(it)
                              break
                            case 'remove':
                              setConfirmDeleteItem(it)
                              break
                          }
                        }}
                        options={[
                          {
                            icon: <EditIcon />,
                            children: t('grid.edit'),
                            value: 'edit',
                          },
                          {
                            icon: <Trash className="text-text-error-primary" />,
                            children: <span className="text-text-error-primary"> {t('grid.remove')} </span>,
                            value: 'remove',
                          },
                        ]}
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </Table>

          {!isLoading && (page > 1 || items.length == query.limit) && (
            <Pagination
              hasNext={items.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={items.length}
            />
          )}
        </>
      )}
    </div>
  )
}
