import { Settings } from '@/components/ui/Settings'
import i18next, { TFunction } from 'i18next'
import { CheckBoxContainer } from '@/components/ui/CheckBoxContainer'
import StyledDatePicker from '@/components/StyledDatePicker'
import DurationSelectorDHMS from '@/components/DurationSelectorDHMS'
import { SelectTime } from '@/components/ui/SelectTime'
import { FieldGroup, Label } from '@/ui'
import dayjs from 'dayjs'

export const validateInterval = (start_at: number, end_at: number, t: TFunction) => {
  if (start_at >= end_at) {
    return t('validation.start_date')
  }

  if (start_at <= 0 || end_at <= 0) {
    return t('validation.invalid_date')
  }

  if (new Date(start_at * 1000).getFullYear() > 2037 || new Date(end_at * 1000).getFullYear() > 2037) {
    return t('validation.invalid_date')
  }
}

export const ItemTimelimit = ({
  start_at,
  end_at,
  duration,
  setValue,
  children,
  showDuration = true,
  useSettingsContainer = true,
}: {
  useSettingsContainer?: boolean
  showDuration?: boolean
  start_at?: number
  end_at?: number
  duration?: number
  children?: React.ReactNode
  setValue: (prop: 'duration' | 'start_at' | 'end_at', value?: number) => void
}) => {
  const onDateChanged = (value: string, prop: 'start_at' | 'end_at') => {
    const currentDate = new Date((prop == 'start_at' ? start_at : end_at)! * 1000)

    const date = new Date(value + ' 00:00:00')
    date.setHours(currentDate.getHours())
    date.setMinutes(0)
    date.setSeconds(0)

    setValue(prop, date.getTime() / 1000)
  }

  const onTimeChanged = (hours: number, prop: 'start_at' | 'end_at') => {
    const date = new Date((prop == 'start_at' ? start_at : end_at)! * 1000)
    date.setHours(hours)
    date.setMinutes(0)
    date.setSeconds(0)

    setValue(prop, date.getTime() / 1000)
  }

  const getStartDate = () => {
    return dayjs().startOf('day').toDate().getTime() / 1000
  }

  const getDateValue = (value: number) => {
    let d = new Date(value * 1000)

    let ds = dayjs(d)

    return ds.format('YYYY-MM-DD')
  }

  const getTimeValue = (value: number) => {
    let d = new Date(value * 1000)
    return d.getHours()
  }

  const renderTimezoneName = () => {
    let offset = new Date().getTimezoneOffset()
    let sign = offset >= 0 ? '+' : '-'
    let hours = Math.floor(Math.abs(offset) / 60)
    let minutes = Math.abs(offset) % 60

    let formattedOffset = `UTC ${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`

    let formatter = new Intl.DateTimeFormat([], { timeZoneName: 'long' })
    let arr = formatter.format(new Date()).split(',')

    return (
      <div className="text-caption-sm font-normal text-text-quarterary-hover">{`(${formattedOffset}) ${arr[arr.length - 1].trim()}`}</div>
    )
  }

  const renderEditor = () => {
    return (
      <>
        <CheckBoxContainer
          checked={start_at != undefined && end_at != undefined}
          onChange={v => {
            if (v.target.checked) {
              setValue('duration', null as unknown as undefined)
              setValue('start_at', getStartDate())
              setValue('end_at', getStartDate() + 86400 * 30)
            } else {
              setValue('start_at', null as unknown as undefined)
              setValue('end_at', null as unknown as undefined)
            }
          }}
          extraRight={start_at != undefined && end_at != undefined && renderTimezoneName()}
          label={i18next.t('campaign.virtual-sku-editor.limit-by-dates')}
        >
          <div className="flex flex-col justify-end gap-2">
            {start_at && (
              <div>
                <Label $size="md">{i18next.t('datetime.start_at')}</Label>
                <div className="flex gap-2">
                  <StyledDatePicker
                    value={getDateValue(start_at)}
                    onChange={value => value && onDateChanged(value, 'start_at')}
                  />
                  <SelectTime
                    onChange={value => onTimeChanged(value as number, 'start_at')}
                    value={getTimeValue(start_at)}
                  />
                </div>
              </div>
            )}

            {end_at && (
              <FieldGroup label={i18next.t('datetime.end_at')}>
                <div className="flex gap-2">
                  <StyledDatePicker
                    value={getDateValue(end_at)}
                    onChange={value => value && onDateChanged(value, 'end_at')}
                  />
                  <SelectTime
                    onChange={value => onTimeChanged(value as number, 'end_at')}
                    value={getTimeValue(end_at)}
                  />
                </div>
              </FieldGroup>
            )}
          </div>
        </CheckBoxContainer>

        {showDuration && (
          <CheckBoxContainer
            className="mt-4"
            checked={duration != undefined}
            onChange={v => {
              setValue('duration', v.target.checked ? 3600 : undefined)
              if (v.target.checked) {
                setValue('start_at', null as unknown as undefined)
                setValue('end_at', null as unknown as undefined)
              }
            }}
            label={i18next.t('campaign.virtual-sku-editor.limit-by-timer')}
          >
            <DurationSelectorDHMS
              duration={duration || 0}
              size="small"
              onChange={v => {
                setValue('duration', v)
              }}
            />
          </CheckBoxContainer>
        )}
      </>
    )
  }

  if (useSettingsContainer) {
    return (
      <Settings text={i18next.t('campaign.settings.limit-limitations')}>
        {renderEditor()}
        {children}
      </Settings>
    )
  }

  return renderEditor()
}
