import { useTranslation } from 'react-i18next'
import { InputSearch, SearchFilterGeneric, SelectFilter } from '@dashboard/ui'
import { ObjectType, State } from '@/api/dashboard'
import { PageToolbar } from '@/ui'
import { L10nListFilter } from '../types'

interface L10nLocaleToolbarProps {
  filter: SearchFilterGeneric<L10nListFilter>
}

export const L10nLocaleToolbar = ({ filter }: L10nLocaleToolbarProps) => {
  const { t } = useTranslation()

  return (
    <PageToolbar>
      <InputSearch {...filter.registerInput('q')} data-testid="l10n/toolbar/filter-search" placeholder={t('search')} />
      <div className="flex gap-2">
        <SelectFilter
          {...filter.registerSelect('type')}
          data-testid="l10n/toolbar/filter-type"
          data-testvalue={`l10n/toolbar/filter-type/${filter.values.type}`}
          items={Object.values(ObjectType)
            .sort((a, b) => a.localeCompare(b))
            .map(type => ({
              children: t(`localization.translation.type.${type.toLowerCase()}`, { count: Infinity }),
              value: type,
            }))}
        >
          {t('localization.locale.filter.type')}
        </SelectFilter>
        <SelectFilter
          {...filter.registerSelect('state')}
          data-testid="l10n/toolbar/filter-status"
          data-testvalue={`l10n/toolbar/filter-status/${filter.values.state}`}
          items={Object.values(State).map(state => ({
            children: t(`localization.translation.status.${state}`),
            value: state,
          }))}
        >
          {t('localization.locale.filter.status')}
        </SelectFilter>
      </div>
    </PageToolbar>
  )
}
