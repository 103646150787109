import { ChangeEvent, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { debounce, pickBy } from 'es-toolkit';

export type SearchFilterQueryValue = string | number | null | undefined;

export interface SearchFilterGeneric<T extends string = string> {
  values: Partial<Record<T, SearchFilterQueryValue>>;
  onChange: (filter: SearchFilterGeneric<T>['values']) => void;
  registerInput: (field: T) => {
    ref: (ref: HTMLInputElement) => void;
    defaultValue: string | number | undefined;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  registerSelect: (field: T) => {
    value: SearchFilterQueryValue;
    onChange: (value: SearchFilterQueryValue) => void;
  };
}

const shouldPick = (value: SearchFilterQueryValue) => !!value;

export const useSearchFilter = <T extends string>(): SearchFilterGeneric<T> => {
  const [searchParams, setSearchParams] = useSearchParams();
  const registeredRefs = useMemo(() => new Map<T, HTMLInputElement>(), []);

  const values = useMemo(
    () => pickBy(Object.fromEntries(searchParams.entries()) as SearchFilterGeneric<T>['values'], shouldPick),
    [searchParams],
  );

  const onChange: SearchFilterGeneric<T>['onChange'] = useCallback(
    filter => {
      const filteredValues = pickBy(filter, shouldPick);

      for (const [field, ref] of registeredRefs.entries()) {
        if (filteredValues[field] !== ref.value) {
          ref.value = filteredValues[field]?.toString() || '';
        }
      }

      setSearchParams(filteredValues as unknown as URLSearchParams);
    },
    [registeredRefs, setSearchParams],
  );

  const debouncedOnChange = debounce(onChange, 350);

  const registerInput: SearchFilterGeneric<T>['registerInput'] = useCallback(
    field => {
      return {
        ref: (ref: HTMLInputElement) => {
          registeredRefs.set(field, ref);
        },
        defaultValue: values[field]?.toString() || '',
        onChange: e => {
          debouncedOnChange({ ...values, [field]: e.target.value });
        },
      };
    },
    [registeredRefs, values, debouncedOnChange],
  );

  const registerSelect: SearchFilterGeneric<T>['registerSelect'] = useCallback(
    field => ({
      value: values[field]?.toString() || '',
      onChange: v => {
        onChange({ ...values, [field]: v });
      },
    }),
    [values, onChange],
  );

  return {
    values,
    registerInput,
    registerSelect,
    onChange,
  };
};
