import { ImageSize, KeyValue } from './types'
import { CompanyGame, Currency, CustomerCompany } from './api/dashboard'

export function getPriceMinorUnitFactor(currency: string, price_minor_unit: number = 2): number {
  if (price_minor_unit == null) {
    price_minor_unit = 2
  }

  switch (currency) {
    case 'IQD':
    case 'JOD':
      price_minor_unit = 3
      break
    case 'JPY':
    case 'KRW':
    case 'PYG':
    case 'VND':
      price_minor_unit = 0
      break
  }

  return Math.pow(10, price_minor_unit)
}

/**
 * 1999 -> 19.99
 * @param value
 * @param currency
 * @param price_minor_unit
 */
export function formatMoney(
  value: number | undefined,
  currency = 'USD',
  price_minor_unit: number | undefined = undefined,
) {
  if (value == undefined) {
    return ''
  }

  if (currency == Currency.RP) {
    return '✦' + value.toString()
  }

  if (!currency) {
    return value
  }

  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  })

  const factor = getPriceMinorUnitFactor(currency, price_minor_unit)

  return formatter.format(value / factor)
}

/**
 * 19.99 -> 1999
 * @param value
 * @param currency
 */
export function priceStringToMoney(value: string, currency = 'USD'): number {
  const factor = getPriceMinorUnitFactor(currency)
  const n = parseFloat(value) * factor
  return Math.round(n)
}

function isObject(item: object) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepMerge(target: any, ...sources: any[]): any {
  if (!sources.length) {
    return target
  }

  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} })
        }
        deepMerge(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }
  return deepMerge(target, ...sources)
}

export function rgbToHex(rgbStr: string) {
  // Remove the "rgb" and parentheses
  rgbStr = rgbStr.slice(4, -1)

  // Split the string into r, g, and b values
  let rgb = rgbStr.split(',')

  let r = parseInt(rgb[0]).toString(16)
  let g = parseInt(rgb[1]).toString(16)
  let b = parseInt(rgb[2]).toString(16)

  // Ensure each value is two characters long, pad with 0 if not
  if (r.length === 1) {
    r = '0' + r
  }
  if (g.length === 1) {
    g = '0' + g
  }
  if (b.length === 1) {
    b = '0' + b
  }

  return '#' + r + g + b
}

export function getContrastColor(color: string) {
  if (color.indexOf('rgb') === 0) {
    color = rgbToHex(color)
  }

  if (color.indexOf('#') === 0) {
    color = color.slice(1)
  }

  // Convert the hex color to RGB
  let r = parseInt(color.slice(0, 2), 16)
  let g = parseInt(color.slice(2, 4), 16)
  let b = parseInt(color.slice(4, 6), 16)

  // Apply the formula from the WCAG
  let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  // If the color is dark, return white, otherwise return black
  return luminance > 0.5 ? 'black' : 'white'
}

let emailTester =
  // eslint-disable-next-line no-useless-escape,
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/

export function isEmail(email: string | undefined) {
  if (!email) {
    return false
  }
  let emailParts = email.split('@')

  if (emailParts.length !== 2) {
    return false
  }
  return emailTester.test(email)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getByPath(obj: any, path: string) {
  try {
    let props = path.split('.')
    let v = obj[props[0]]
    for (let i = 1; i < props.length; i++) {
      if (typeof v != 'object') {
        return v
      }
      v = v[props[i]]
    }
    return v
  } catch {
    // handle non-existent path
    return
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setByPath(obj: any, path: string, value: string | number | null) {
  let schema = obj // a moving reference to internal objects within obj
  let pList = path.split('.')
  let len = pList.length
  for (let i = 0; i < len - 1; i++) {
    let elem = pList[i]
    if (!schema[elem]) {
      schema[elem] = {}
    }
    schema = schema[elem]
  }

  schema[pList[len - 1]] = value
  return schema
}

export const formatNumber = (n: number) =>
  n
    .toLocaleString('en-US', {
      maximumFractionDigits: 0,
    })
    .replace(/,/g, ',')

export function prepareSLUG(name: string): string {
  let res: string[] = []
  const allowSymbols: Set<string> = new Set<string>('abcdefghijklmnopqrstuvwxyz0123456789_-.'.split(''))
  name = name.trim().toLowerCase()
  for (let i = 0; i < name.length; i++) {
    let c: string = name[i].toLowerCase()
    if (!allowSymbols.has(c)) {
      if (c == ' ') {
        res.push('-')
      }
    } else {
      res.push(name[i])
    }
  }

  return res.join('')
}

export function capitalizeFirstLetter(str: string | undefined) {
  if (!str) {
    return ''
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function removeFromArray<T>(arr: T[], item: T) {
  let idx = arr.indexOf(item)
  if (idx > -1 && idx < arr.length) {
    arr.splice(idx, 1)
  }
}

export function uuid4() {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  )
}
const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

export function generateUid(prefix: string, randomPartLength: number = 5): string {
  const lettersLength = letters.length
  const randomPart = Array.from(
    { length: randomPartLength },
    () => letters[Math.floor(Math.random() * lettersLength)],
  ).join('')
  const now = Math.floor(Date.now() / 1000)

  const logBase = (x: number, base: number) => Math.log(x) / Math.log(base)

  const timestampPart = Array.from({ length: Math.ceil(logBase(now, lettersLength)) }, (_, i) => {
    return letters[Math.floor(now / lettersLength ** (i + 1)) % lettersLength]
  })
    .reverse()
    .join('')

  return `${prefix}_${timestampPart}${randomPart}`
}

export function calcImageSizeByUrl(url: string): Promise<ImageSize> {
  let p = new Promise<ImageSize>(resolve => {
    let img = new Image()
    img.src = url
    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }
  })
  return p
}

export function dataURL2File(dataUrl: string, filename: string) {
  let arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)![1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export function isSuperAdmin(customer: CustomerCompany | undefined) {
  return customer?.role == 'super_admin'
}

export function uniqueList<T>(arr: T[], propName: string) {
  let res = []

  for (let i = 0; i < arr.length; i++) {
    let item = arr[i]
    if (res.findIndex(x => (x as KeyValue)[propName] == (item as KeyValue)[propName]) == -1) {
      res.push(item)
    }
  }

  return res
}

const FORMATTER_LOCALE = 'en-US'

export const currencySymbol = (currency: string): string | undefined => {
  const formatter = new Intl.NumberFormat(FORMATTER_LOCALE, {
    style: 'currency',
    currency,
  })

  let symbol
  formatter.formatToParts(0).forEach(({ type, value }) => {
    if (type === 'currency') {
      symbol = value
    }
  })

  return symbol
}

export const getNow = () => {
  return Math.trunc(new Date().getTime() / 1000)
}

export const FLAGS = `${import.meta.env.VITE_STATIC_IMAGES_URL}/country-flags`

export const LOCALE_FLAGS = `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/locale-flags`

export function goToLogout() {
  window.location.href = '/logout'
}

export function hasOnlyDemoCompany(companies: CompanyGame[] | undefined) {
  if (!companies?.length) {
    return false
  }

  if (companies.length == 1 && companies[0].is_demo) {
    return true
  }

  return false
}
