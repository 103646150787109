import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const PlayerCommunicationInfo: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 16 16" ref={ref}>
      <g clip-path="url(#clip0_3489_630)">
        <path
          d="M0 5.71077C0 3.71181 0 2.71233 0.389023 1.94883C0.731217 1.27724 1.27724 0.731217 1.94883 0.389023C2.71233 0 3.71181 0 5.71077 0H10.2892C12.2882 0 13.2877 0 14.0512 0.389023C14.7228 0.731217 15.2688 1.27724 15.611 1.94883C16 2.71233 16 3.71181 16 5.71077V10.2892C16 12.2882 16 13.2877 15.611 14.0512C15.2688 14.7228 14.7228 15.2688 14.0512 15.611C13.2877 16 12.2882 16 10.2892 16H5.71077C3.71181 16 2.71233 16 1.94883 15.611C1.27724 15.2688 0.731217 14.7228 0.389023 14.0512C0 13.2877 0 12.2882 0 10.2892V5.71077Z"
          fill="url(#paint0_linear_3489_630)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.77696 13.3844C3.56039 13.6637 3.68255 14.0001 4.08792 14.0001C4.72652 14.0001 6.28135 13.2989 7.19204 12.6319C7.28644 12.5635 7.36418 12.535 7.44193 12.5407C7.50856 12.5464 7.5752 12.5464 7.64183 12.5464C11.429 12.5464 14 10.3629 14 7.6094C14 4.87299 11.329 2.66675 8.00278 2.66675C4.67099 2.66675 2 4.87299 2 7.6094C2 9.34247 3.03286 10.8646 4.72652 11.7995C4.81536 11.8509 4.83758 11.9307 4.79315 12.0162C4.57931 12.3807 4.24526 12.7964 4.00191 13.0992C3.91034 13.2132 3.83161 13.3111 3.77696 13.3844ZM7.99903 4.66675C8.80535 4.66675 9.45976 5.29059 9.45976 6.05926C9.45976 6.82793 8.80535 7.45178 7.99903 7.45178C7.19271 7.45178 6.5383 6.82793 6.5383 6.05926C6.5383 5.29059 7.19271 4.66675 7.99903 4.66675ZM10.3406 9.84273C9.67448 10.2786 8.86816 10.5334 8.00049 10.5334C7.13282 10.5334 6.32503 10.2786 5.65894 9.84273C5.36533 9.65056 5.23971 9.28294 5.41062 8.98494C5.76557 8.36666 6.49448 7.98093 8.00049 7.98093C9.5065 7.98093 10.2354 8.36666 10.5904 8.98494C10.7598 9.28433 10.6342 9.65056 10.3406 9.84273Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_3489_630" x1="8" y1="0" x2="8" y2="16" gradientUnits="userSpaceOnUse">
          <stop stop-color="#38BDF8" />
          <stop offset="1" stop-color="#0EA5E9" />
        </linearGradient>
        <clipPath id="clip0_3489_630">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </IconBase>
  )
})
