import i18next from 'i18next'
import { useContext } from 'react'
import { CampaignContext, ICampaignContext } from '../Context'
import { AddToSegmentNode, Node, SegmentRead } from '../../../api/dashboard'
import { BlockError } from '../types'
import EditSegmentDialog from '../../segment/widgets/EditSegmentDialog'
import { Button, COLORS, useModal } from '@/ui'
import { SelectSegment } from '../../components/SelectSegment/SelectSegment'
import { useSegmentsQuery } from '../../segment/api/useSegmentsQuery'
import { useParams } from 'react-router-dom'

export default function AddToSegmentEditor(props: {
  node: AddToSegmentNode
  error?: BlockError | null
  setNode: (node: Node) => void
}) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { data: segments = [] } = useSegmentsQuery(companyId, gameId, {
    limit: 100,
  })
  const context = useContext(CampaignContext) as ICampaignContext

  const selectedSegment = segments.find(s => s.slug === props.node.segment_slug)

  const openAddSegmentModal = useModal<{ item: SegmentRead }>(props => <EditSegmentDialog {...props} />)

  return (
    <div>
      <SelectSegment
        segmentGroupId={context.segmentGroupId}
        onChange={v => {
          let s = segments.find(s => s.id === v)
          props.setNode({
            ...props.node,
            model_type: 'AddToSegmentNode',
            segment_slug: s?.slug || '',
          })
        }}
        value={selectedSegment?.id || ''}
        label={i18next.t('campaign.block.AddToSegmentNode.add-user-to-segment')}
      />

      {context.segmentGroupId && (
        <Button
          variant="primary"
          onClick={() =>
            openAddSegmentModal({
              item: {
                segment_group_id: context.segmentGroupId,
                name: 'New Segment',
                color: COLORS[0],
              } as SegmentRead,
            })
          }
        >
          {i18next.t('campaign.type.add_segment')}
        </Button>
      )}
    </div>
  )
}
