import i18next from 'i18next'
import { useContext, useEffect } from 'react'
import Divider from '../../../components/Divider'
import CampaignIntegrationBlock from './CampaignIntegrationBlock'
import { Campaign, CampaignEventType, CustomAttribute } from '@/api/dashboard'
import DurationSelectorDHMS from '../../../components/DurationSelectorDHMS'
import { isCustomEvent, isManualLaunchCampaign } from '../Settings'
import { Settings } from '@/components/ui/Settings'
import CustomAttributeTable from './CustomAttributeTable'
import { FieldGroup, FieldValidationMessage, Input } from '@/ui'
import { CheckBoxContainer } from '@/components/ui/CheckBoxContainer'
import { CampaignContext, ICampaignContext } from '../Context'
import { ItemTimelimit } from '@/layouts/components/ItemTimelimit'
import SettingsHeader from '@/layouts/campaigns/SettingsHeader'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

export default function CampaignSettings(props: {
  onClose?: () => void
  campaign: Campaign
  setCampaign: (c: Campaign) => void
}) {
  const attributes = props.campaign.attributes?.custom_attributes || []
  const context = useContext(CampaignContext) as ICampaignContext

  const hasForm = props.onClose != undefined

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setValue,
    control,
    watch,
    setError,
    clearErrors,
  } = useForm<Campaign>({
    values: {
      ...props.campaign,
    },
  })

  useEffect(() => {
    reset(props.campaign)
  }, [props.campaign])

  const start_at = watch('start_at')
  const end_at = watch('end_at')

  const onSaveClick = (data: Campaign) => {
    data.time_limit = !!(data.start_at || data.end_at)

    if (data.start_at && data.end_at) {
      if (data.start_at > data.end_at) {
        setError('time_limit', { message: i18next.t('validation.start_date') })
        return
      }
    }

    props.setCampaign(data)
    props.onClose?.()
  }

  const setAttributes = (v: CustomAttribute[]) => {
    props.setCampaign({
      ...props.campaign,
      attributes: { custom_attributes: v },
    })
  }

  const renderAttributes = () => {
    switch (props.campaign.type) {
      case CampaignEventType.GameHubVisit:
      case CampaignEventType.GameHubVisitStore:
      case CampaignEventType.AbandonedCart:
        break
      default:
        return <></>
    }

    return (
      <>
        <Divider />

        <Settings text={i18next.t('campaign.header.settings')}>
          {(props.campaign.type == CampaignEventType.GameHubVisitStore ||
            props.campaign.type == CampaignEventType.GameHubVisit) && (
            <Controller
              control={control}
              name="attributes.visit_duration"
              render={({ field }) => (
                <DurationSelectorDHMS
                  size={props.campaign.id ? 'small' : 'middle'}
                  duration={field.value == undefined ? 3600 : field.value}
                  label={i18next.t('campaign.editor.visit_duration')}
                  caption={i18next.t('campaign.editor.visit_duration.caption')}
                  onChange={v => {
                    field.onChange(v)
                    if (!hasForm) {
                      props.setCampaign({
                        ...props.campaign,
                        attributes: {
                          ...props.campaign.attributes,
                          visit_duration: v,
                        },
                      })
                    }
                  }}
                />
              )}
            />
          )}

          {props.campaign.type == CampaignEventType.AbandonedCart && (
            <Controller
              control={control}
              name="attributes.abandoned_cart_duration"
              render={({ field }) => (
                <DurationSelectorDHMS
                  size={props.campaign.id ? 'small' : 'middle'}
                  duration={field.value == undefined ? 3600 : field.value}
                  label={i18next.t('campaign.editor.abandoned_cart_duration')}
                  caption={i18next.t('campaign.editor.abandoned_cart_duration.caption')}
                  onChange={v => {
                    field.onChange(v)
                    if (!hasForm) {
                      props.setCampaign({
                        ...props.campaign,
                        attributes: {
                          ...props.campaign.attributes,
                          abandoned_cart_duration: v,
                        },
                      })
                    }
                  }}
                />
              )}
            />
          )}
        </Settings>
      </>
    )
  }

  const renderTimeLimitations = () => {
    return (
      <ItemTimelimit
        setValue={(prop, value) => {
          clearErrors('time_limit')
          setValue(prop as 'start_at' | 'end_at', value, { shouldDirty: true })

          if (!hasForm) {
            props.setCampaign({
              ...props.campaign,
              [prop]: value,
            })
          }
        }}
        showDuration={false}
        start_at={start_at}
        end_at={end_at}
      >
        <ErrorMessage
          name="time_limit"
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      </ItemTimelimit>
    )
  }

  const renderCustomAttributes = () => {
    return (
      isCustomEvent(props.campaign.type) && (
        <>
          <Divider />

          <Settings text={i18next.t('campaign.custom-event.custom-action')}>
            <FieldGroup label={i18next.t('campaign.custom-event.name')}>
              <Input {...register('type', { required: i18next.t('validation.required') })} />
              <ErrorMessage
                name="type"
                errors={errors}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </FieldGroup>
            <FieldGroup label={i18next.t('campaign.custom-event.desc')}>
              <Input {...register('name', { required: i18next.t('validation.required') })} />
              <ErrorMessage
                name="name"
                errors={errors}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </FieldGroup>
          </Settings>

          <Divider />

          <Settings text={i18next.t('campaign.custom-event.attributes')}>
            <CustomAttributeTable attributes={attributes} setAttributes={setAttributes} />
          </Settings>
        </>
      )
    )
  }

  return (
    <div style={{ pointerEvents: context?.readOnly ? 'none' : 'all', opacity: context?.readOnly ? 0.6 : 1 }}>
      {props.onClose && (
        <SettingsHeader
          text={i18next.t('campaign.header.settings')}
          onSave={handleSubmit(onSaveClick)}
          onClose={props.onClose}
        />
      )}
      <div className="p-6">
        <Settings text={i18next.t('campaign.editor.campaign-published')}>
          <CheckBoxContainer
            checked={hasForm ? watch('enabled') : props.campaign.enabled}
            onChange={v => {
              if (!hasForm) {
                props.setCampaign({
                  ...props.campaign,
                  enabled: v.target.checked,
                })
              } else {
                setValue('enabled', v.target.checked, { shouldDirty: true })
              }
            }}
            label={i18next.t('campaign.editor.campaign-published')}
            caption={i18next.t('campaign.editor.campaign-published.subtitle')}
          />
        </Settings>

        <Divider />

        {renderTimeLimitations()}

        {isManualLaunchCampaign(props.campaign.type) && (
          <>
            <Divider />

            <Settings text={i18next.t('campaign.custom-event.integration')}>
              <CampaignIntegrationBlock campaign={props.campaign} />
            </Settings>
          </>
        )}

        {renderAttributes()}

        {renderCustomAttributes()}
      </div>
    </div>
  )
}
