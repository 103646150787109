import { useContext, useState } from 'react'
import { PaymentStatus } from '../../pay-types'
import i18next from 'i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { InputSearch, SelectFilter } from '@dashboard/ui'
import { AuthContext } from '../../Context'
import TransactionsTableView from './components/TransactionsTableView'
import { ButtonIcon, PageHeader, Pagination, Tooltip, useModal } from '@/ui'
import { isAdmin } from '../../security'
import GenerateReportDialog, { GenerateReportDialogProps } from './widgets/GenerateReportDialog'

import { usePaymentsQuery } from './api/usePaymentsQuery'
import { useDebounce } from '../../hooks'
import { KeyValue } from '../../types'
import { Export } from '@/icons'
import { getUrlParamsObject } from '../dashboard/util'
import { useCurrentGame } from '@/api/useCompaniesQuery'
import { FilterType } from '@/api/dashboard'
import { SelectFilterDate } from '@/components'

const TransactionsTable = () => {
  const LIMIT = 20
  const context = useContext(AuthContext)
  const canUserEdit = isAdmin(context?.customer)
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  const [searchParams, setSearchParams] = useSearchParams()
  const status: PaymentStatus = (searchParams.get('status') as PaymentStatus) || null
  const page = Number(searchParams.get('page') || '1')
  const search = searchParams.get('search') || ''
  const period = searchParams.get('period') || FilterType.All
  const { game } = useCurrentGame()
  const query = { offset: (page - 1) * LIMIT, limit: LIMIT }

  const debouncedSearch = useDebounce(search, 500)
  const openReportModal = useModal<GenerateReportDialogProps>(props => <GenerateReportDialog {...props} />)

  const onParamsChanged = (page: number, status: PaymentStatus, search: string, periodParam: string | null) => {
    let newValue: KeyValue = {
      page: page.toString(),
    }

    if (status) {
      newValue.status = status
    }

    if (search?.trim()) {
      newValue.search = search
    }

    newValue.period = periodParam || 'all'

    setSearchParams(newValue as unknown as URLSearchParams)
  }

  const { data: payments = [], isLoading } = usePaymentsQuery(companyId, gameId, {
    ...query,
    status: status ? status : undefined,
    search_string: debouncedSearch,
    ...getUrlParamsObject(period),
  })

  const statusItems = [
    { children: i18next.t(`transactions-table.status.${PaymentStatus.created}`), value: PaymentStatus.created },
    { children: i18next.t(`transactions-table.status.${PaymentStatus.done}`), value: PaymentStatus.done },
    { children: i18next.t(`transactions-table.status.${PaymentStatus.rejected}`), value: PaymentStatus.rejected },
    { children: i18next.t(`transactions-table.status.${PaymentStatus.refunded}`), value: PaymentStatus.refunded },
    { children: i18next.t(`transactions-table.status.${PaymentStatus.canceled}`), value: PaymentStatus.canceled },
    { children: i18next.t(`transactions-table.status.${PaymentStatus.failed}`), value: PaymentStatus.failed },
  ]

  const [error] = useState<string>('')

  const needShowPagination = () => {
    if (isLoading) {
      return false
    }
    if (payments.length === 0 && page == 1) {
      return false
    }

    return true
  }

  const renderActions = () => {
    return (
      <>
        <div className="flex items-center gap-2">
          {canUserEdit && !game?.sandbox && (
            <Tooltip message={i18next.t('transactions-table.generate-report')}>
              <ButtonIcon onClick={() => openReportModal({})} className="size-[28px]" variant="secondary-gray">
                <Export className="text-text-secondary" />
              </ButtonIcon>
            </Tooltip>
          )}

          <SelectFilter
            items={statusItems}
            value={status}
            onChange={v => {
              onParamsChanged(1, v as PaymentStatus, search, period)
            }}
          >
            {i18next.t('transactions-table.status')}
          </SelectFilter>

          <SelectFilterDate
            value={period == FilterType.All ? null : period}
            onChange={v => onParamsChanged(1, status, search, v as string | null)}
          />
        </div>
      </>
    )
  }

  return (
    <div className="flex h-full flex-col" role="transaction-page">
      <PageHeader extra={renderActions()}>{i18next.t('dashboard.transactions')}</PageHeader>

      {(payments.length > 0 || search || page > 1) && (
        <div className="mb-4">
          <InputSearch
            placeholder={i18next.t('search')}
            value={search}
            onChange={e => {
              onParamsChanged(1, status, e.target.value, period)
            }}
          />
        </div>
      )}

      <TransactionsTableView payments={payments} isLoading={isLoading} error={!!error} search={!!search} />
      {needShowPagination() && (
        <Pagination
          hasNext={payments.length >= query.limit}
          page={page}
          onPageChange={p => onParamsChanged(p, status, search, period)}
        />
      )}
    </div>
  )
}

export default TransactionsTable
