import { ComponentProps, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, TableZeroState } from '@/ui'
import { DrawerSidebar } from '@/components/ui/DrawerSidebar'
import {
  LoyaltyTierForm,
  LoyaltyTierSubmit,
  LoyaltyTiersForm,
  LoyaltyTiersFormInput,
  Tier,
} from '@/layouts/engagement/widgets'
import { useTranslation } from 'react-i18next'

const FORM_NAME = 'engagement::loyalty::tiers'

export interface TiersConfiguration {
  isPreview: boolean
  buttons: {
    edit?: Partial<ComponentProps<typeof Button>>
    submit?: Partial<ComponentProps<typeof Button>>
    cancel?: Partial<ComponentProps<typeof Button>>
  }
}

interface LoyaltyTiersProps {
  interactive?: boolean
  configure: (config: TiersConfiguration) => void
  values: {
    settingsID: string
    tiers: Tier[]
  }
}

export const LoyaltyTiers = ({ interactive = false, configure, values }: LoyaltyTiersProps) => {
  const methods = useForm<LoyaltyTiersFormInput>({ defaultValues: values })
  const {
    watch,
    reset,
    setValue,
    formState: { isDirty, isSubmitting },
  } = methods
  const tiers = watch('tiers') || []

  const onTierSubmit = (tier: LoyaltyTierSubmit) => {
    const updated = [...tiers]
    if (modifyTierIndex !== null) {
      updated[modifyTierIndex] = { ...updated[modifyTierIndex], ...tier }
    } else {
      updated.push({ ...tier, items: [] })
    }
    setValue(
      'tiers',
      updated.sort((a, b) => a.points - b.points),
      { shouldDirty: true },
    )
  }

  const [isPreview, setIsPreview] = useState<boolean>(true)
  const [isModifyTier, setIsModifyTier] = useState<boolean>(false)
  const [modifyTierIndex, setModifyTierIndex] = useState<number | null>(null)

  useEffect(() => {
    configure({
      isPreview: isPreview,
      buttons: {
        edit: {
          onClick: () => setIsPreview(false),
        },
        submit: {
          disabled: !isDirty || tiers.length === 0,
          type: 'submit',
          form: FORM_NAME,
          isLoading: isSubmitting,
        },
        cancel: {
          onClick: () => {
            reset(values)
            setIsPreview(true)
          },
        },
      },
    })
  }, [configure, isPreview, isDirty, tiers.length, isSubmitting])

  const { t } = useTranslation()

  return (
    <div className="relative size-full">
      {interactive && (
        <DrawerSidebar open={isModifyTier} width="720px" position="right">
          <LoyaltyTierForm
            tier={
              modifyTierIndex !== null
                ? tiers[modifyTierIndex]
                : {
                    name: '',
                    image_url: '',
                    points: null,
                  }
            }
            onClose={() => setIsModifyTier(false)}
            onSubmit={onTierSubmit}
          />
        </DrawerSidebar>
      )}

      <FormProvider {...methods}>
        {tiers.length === 0 && isPreview && (
          <TableZeroState
            className="h-full"
            title={t('loyalty.level.empty.title')}
            message={t('loyalty.level.empty.message')}
            buttons={
              interactive && (
                <Button variant="primary" onClick={() => setIsPreview(false)}>
                  {t('loyalty.level.empty.button')}
                </Button>
              )
            }
          />
        )}
        <LoyaltyTiersForm
          id={FORM_NAME}
          preview={isPreview}
          togglePreview={() => setIsPreview(true)}
          setModifyTierIndex={index => {
            setIsModifyTier(true)
            setModifyTierIndex(index)
          }}
        />
      </FormProvider>
    </div>
  )
}
