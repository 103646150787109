import { forwardRef } from 'react'
import { Menu, MenuItem, MenuItemBaseProps, Popper, PopperMenuProps } from '@dashboard/ui'

type LabelFilterValue = string | null

interface LabelFilterMenuItem extends MenuItemBaseProps {
  value: LabelFilterValue
}

interface LabelFilterMenuProps extends PopperMenuProps {
  items: LabelFilterMenuItem[]
  onChange: (value: LabelFilterValue) => void
}

const LabelFilterMenu = forwardRef<HTMLDivElement, LabelFilterMenuProps>(
  ({ items, onChange, onClose, ...rest }, ref) => {
    const onClickMenuItem = (value: LabelFilterMenuItem['value']) => {
      onChange(value)
      onClose()
    }

    return (
      <Menu ref={ref} {...rest}>
        {items.map(({ value, ...item }) => (
          <MenuItem key={`label-filter-menu-item-${value}`} onClick={() => onClickMenuItem(value)}>
            {item.children}
          </MenuItem>
        ))}
      </Menu>
    )
  },
)

interface LabelFilterProps {
  items: LabelFilterMenuItem[]
  value: string
  onChange: (value: LabelFilterValue) => void
}

export const LabelFilter = ({ items, value, onChange }: LabelFilterProps) => (
  <Popper
    popper={props => <LabelFilterMenu {...props} items={items} onChange={onChange} />}
    params={{ placement: 'bottom-start' }}
  >
    <span className="cursor-pointer border-b border-dashed border-text-secondary">
      {items.find(item => item.value === value)?.children}
    </span>
  </Popper>
)
