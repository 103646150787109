import {
  Button,
  Drawer,
  DrawerContent,
  DrawerTopbar,
  FieldGroup,
  FieldValidationMessage,
  Input,
  Select,
  Textarea,
} from '@/ui'
import i18next from 'i18next'
import { ErrorMessage } from '@hookform/error-message'
import { Controller, useForm } from 'react-hook-form'
import { PeriodType, RotationSettings, RotationType, StoreRead, StoreType } from '@/api/dashboard'
import { useContext } from 'react'
import { AuthContext, IAuthContext } from '@/Context'
import { canEdit } from '@/security'
import { RefreshIcon2, Shuffle } from '@/icons'

export const GeneralStoreSettings = (props: {
  onClose: () => void
  store: StoreRead
  onApplied: (store: StoreRead) => void
}) => {
  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = canEdit(context.customer)

  const {
    control,
    setValue,
    watch,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<StoreRead>({
    values: props.store,
  })

  const type = watch('type')
  const rotation_settings = watch('rotation_settings')

  const onApplyClick = (data: StoreRead) => {
    if (data.type !== StoreType.Rotation) {
      data.rotation_settings = null as unknown as RotationSettings
    }
    props.onApplied(data)
  }

  return (
    <Drawer open={true} width={500}>
      <DrawerTopbar>
        <h2 className="text-title-t5">{i18next.t('store.settings')}</h2>
        <div className="ml-auto flex gap-2">
          <Button onClick={handleSubmit(onApplyClick)} variant="primary" data-testid="close-store-settings">
            {i18next.t('apply')}
          </Button>
          <Button onClick={props.onClose} color="secondary" data-testid="close-store-settings">
            {i18next.t('close')}
          </Button>
        </div>
      </DrawerTopbar>
      <DrawerContent className="h-full w-[500px] overflow-auto">
        <FieldGroup label={i18next.t('store.name')}>
          <Input
            maxLength={300}
            disabled={!canUserEdit}
            autoFocus
            {...register('name', { required: i18next.t('validation.required') })}
          />
          <ErrorMessage
            name="name"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('store.description')}>
          <Textarea disabled={!canUserEdit} {...register('description')} />
          <ErrorMessage
            name="description"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('store.type')}>
          <Controller
            control={control}
            name="type"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                disabled={!canUserEdit}
                value={field.value}
                onChange={v => {
                  if (v == StoreType.Rotation && !rotation_settings) {
                    setValue('rotation_settings', {
                      type: RotationType.Random,
                      count: 1,
                      period_value: 1,
                      period_type: PeriodType.Day,
                    })
                  }
                  field.onChange(v)
                }}
                options={Object.values(StoreType).map(it => ({
                  value: it,
                  children: i18next.t(`store.type.${it}`),
                  desc: i18next.t(`store.type.${it}.desc`),
                }))}
              />
            )}
          />
        </FieldGroup>

        {type == StoreType.Rotation && (
          <>
            <div className="my-[18px] w-full border border-border-secondary" />

            <FieldGroup label={i18next.t('store.rotation.type')} tooltip={i18next.t('store.rotation.type.tooltip')}>
              <Controller
                control={control}
                name="rotation_settings.type"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    disabled={!canUserEdit}
                    {...field}
                    options={Object.values(RotationType).map(it => ({
                      value: it,
                      children: i18next.t(`store.rotation.type.${it}`),
                      desc: i18next.t(`store.rotation.type.${it}.desc`),
                      icon: it == RotationType.RoundRobin ? <RefreshIcon2 /> : <Shuffle />,
                    }))}
                  />
                )}
              />
            </FieldGroup>

            <FieldGroup label={i18next.t('store.rotation.count')} tooltip={i18next.t('store.rotation.count.tooltip')}>
              <Input
                disabled={!canUserEdit}
                type="number"
                {...register('rotation_settings.count', {
                  required: i18next.t('validation.required'),
                  min: {
                    value: 1,
                    message: i18next.t('validation.min-no-field', { value: 1 }),
                  },
                })}
              />
              <ErrorMessage
                name="rotation_settings.count"
                errors={errors}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </FieldGroup>

            <div className="flex gap-[18px]">
              <div className="w-1/2">
                <FieldGroup label={i18next.t('store.rotation.change-items')}>
                  <Input
                    disabled={!canUserEdit}
                    type="number"
                    {...register('rotation_settings.period_value', {
                      required: i18next.t('validation.required'),
                      min: {
                        value: 1,
                        message: i18next.t('validation.min-no-field', { value: 1 }),
                      },
                    })}
                    extraRight={
                      <div className="flex items-center whitespace-nowrap">
                        {i18next.t('store.rotation.period_value')}
                      </div>
                    }
                  />
                </FieldGroup>
              </div>
              <div className="w-1/2">
                <FieldGroup label={i18next.t('store.rotation.period')}>
                  <Controller
                    control={control}
                    name="rotation_settings.period_type"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        disabled={!canUserEdit}
                        {...field}
                        options={Object.values(PeriodType)
                          .filter(it => it !== PeriodType.Second)
                          .map(value => ({
                            children: i18next.t(`store.rotation.period.type.${value}`),
                            value: value,
                          }))}
                      />
                    )}
                  />
                </FieldGroup>
              </div>
            </div>
            <ErrorMessage
              name="rotation_settings.period_value"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </>
        )}
      </DrawerContent>
    </Drawer>
  )
}
