import { Grid } from '@mui/material'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { useTheme } from '@mui/material/styles'
import { StyledInputLabel } from './StyledTextField'
import { XClose } from '@/icons'
import { ButtonIcon } from '@/ui'

export interface ITextFieldProps {
  label?: string
  helperText?: string
  value: string | null //2023-09-23
  margin?: boolean
  fullWidth?: boolean
  clearable?: boolean
  onChange: (value: string | null) => void
  minDate?: Dayjs
  maxDate?: Dayjs
}

export default function StyledDatePicker(props: ITextFieldProps) {
  const theme = useTheme()

  return (
    <Grid container sx={{ marginTop: props.margin ? theme.spacing(2) : '' }}>
      <Grid item xs={12}>
        {props.label && <StyledInputLabel label={props.label} />}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="flex items-center gap-1">
            <DatePicker
              minDate={props.minDate}
              maxDate={props.maxDate}
              value={props.value ? dayjs(props.value) : null}
              slotProps={{ textField: { size: 'small', fullWidth: true } }}
              onChange={newValue => {
                if (newValue?.isValid()) {
                  props.onChange((newValue as Dayjs).format('YYYY-MM-DD'))
                }
              }}
            />
            {props.clearable && (
              <ButtonIcon variant="tertiary-gray" size="sm" onClick={() => props.onChange(null)}>
                <XClose size={20} />
              </ButtonIcon>
            )}
          </div>
        </LocalizationProvider>
      </Grid>
    </Grid>
  )
}
