import { queryOptions } from '@tanstack/react-query'

/**
 * This query used for temporary translation progress.
 * @param gameId
 * @param localeId
 */
export const localeTranslateAllQueryOptions = (gameId: string, localeId: string) =>
  queryOptions({
    queryKey: [gameId, 'locales-temp', localeId],
    initialData: { total: 100, done: 0 },
  })
