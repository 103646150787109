import { forwardRef, useCallback } from 'react'
import { Menu, MenuItem, PopperMenuProps } from '@dashboard/ui'
import { SelectIconProps } from './SelectIcon'

export interface SelectIconMenuProps extends PopperMenuProps, Pick<SelectIconProps, 'items' | 'onChange'> {}

export const SelectIconMenu = forwardRef<HTMLDivElement, SelectIconMenuProps>(
  ({ items, onChange, onClose, ...rest }, ref) => {
    const onClickMenuItem = useCallback(
      (value: SelectIconProps['value']) => {
        onChange(value)
        onClose()
      },
      [onChange, onClose],
    )

    return (
      <Menu ref={ref} {...rest}>
        <div className="grid grid-cols-4 gap-1">
          {items.map(({ children, value }) => (
            <div key={`menu-item-${value}`}>
              <MenuItem onClick={() => onClickMenuItem(value)}>
                <div className="flex w-full items-center justify-center">{children}</div>
              </MenuItem>
            </div>
          ))}
        </div>
      </Menu>
    )
  },
)
