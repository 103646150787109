import { useEffect, useState } from 'react'
import i18next from 'i18next'
import { useParams } from 'react-router-dom'
import { FieldGroup, FieldValidationMessage, Input, Modal, ModalProps, ModalTitle } from '@/ui'
import { Controller, useForm } from 'react-hook-form'
import { ResponseError, getErrorText } from '../../api'
import { ErrorMessage } from '@hookform/error-message'
import { PagesSlugCaption } from './components'
import { PageRead } from '../../api/dashboard'
import { useWebsitesQuery } from '../../api/useWebsitesQuery'
import { usePageUpdateMutate } from './api/usePageUpdateMutate'
import { usePageCreateMutate } from './api/usePageCreateMutate'
import slugify from '../../libs/slugify'
import { getNow } from '../../util'
import { MAX_SLUG_LENGTH } from '@/Settings'
import { Button } from '@dashboard/ui'

interface EditCollectionDialogProps extends ModalProps {
  item: PageRead
  onCreated: (createdPage: PageRead) => void
}

const EditCollectionDialog = (props: EditCollectionDialogProps) => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: websites = [] } = useWebsitesQuery(companyId, gameId)
  const [helperText, setHelperText] = useState('')
  const website = websites[0]
  const {
    watch,
    register,
    formState: { errors },
    control,
    setValue,
    handleSubmit,
  } = useForm<PageRead>({
    defaultValues: {
      ...props.item,
    },
  })

  const { mutateAsync: updateMutateAsync } = usePageUpdateMutate(companyId, gameId)
  const { mutateAsync: createMutateAsync } = usePageCreateMutate(companyId, gameId)

  const saveClick = async (data: PageRead) => {
    setHelperText('')
    try {
      if (props.item?.id) {
        await updateMutateAsync({
          pageId: data.id,
          update: data,
        })
        props.onClose?.()
      } else {
        let { data: created } = await createMutateAsync({
          create: {
            ...data,
            published_at: getNow(),
          },
        })
        props.onClose?.()
        props.onCreated(created)
      }

      return true
    } catch (e) {
      setHelperText(getErrorText(e as ResponseError))
    }
  }

  const [slug, title] = watch(['slug', 'title'])

  useEffect(() => {
    if (props.item.id) {
      return
    }

    setValue('slug', slugify(title))
  }, [title])

  return (
    <Modal onClose={props.onClose}>
      <ModalTitle>{i18next.t(props.item.id ? 'pages.edit.edit-collection' : 'pages.collections-add.title')}</ModalTitle>

      <form className="mt-10" onSubmit={handleSubmit(saveClick)}>
        <FieldGroup label={i18next.t('pages.collection.name')}>
          <Input
            autoFocus
            {...register('title', {
              required: i18next.t('validation.required'),
              setValueAs: (value: string) => value.trim(),
            })}
          />
          <ErrorMessage
            name="title"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup
          className="overflow-hidden"
          tooltip={i18next.t('sku.sku-desc')}
          label={i18next.t('pages.edit.slug')}
          caption={<PagesSlugCaption base={website?.url} slug={slug} />}
        >
          <Controller
            control={control}
            name="slug"
            rules={{
              required: i18next.t('validation.required'),
              maxLength: {
                message: i18next.t('validation.maxLength', {
                  field: i18next.t('pages.edit.slug'),
                  value: MAX_SLUG_LENGTH,
                }),
                value: MAX_SLUG_LENGTH,
              },
            }}
            render={({ field }) => (
              <Input
                color={errors.slug && 'error'}
                value={field.value}
                onChange={e => {
                  let str = slugify(e.target.value)
                  field.onChange(str)
                }}
              />
            )}
          />
          <ErrorMessage
            name="slug"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        {helperText && <FieldValidationMessage>{helperText}</FieldValidationMessage>}

        <div className="mt-10 flex justify-end gap-4">
          <Button color="secondary" variant="outline" type="reset" onClick={props.onClose}>
            {i18next.t('Cancel')}
          </Button>
          <Button color="primary" type="submit">
            {i18next.t(props.item.id ? 'Save2' : 'pages.collections-add.submit')}
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default EditCollectionDialog
