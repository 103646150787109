import { useTranslation } from 'react-i18next'
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import i18next from 'i18next'
import dayjs from 'dayjs'
import { Button, FormErrorMessage, Input, Select, Textarea, formatHookFormErrors } from '@dashboard/ui'
import { PageLayoutType, PageRead } from '@/api/dashboard'
import { ButtonIcon, ConfirmDialog, DrawerContent, DrawerTopbar, FieldGroup, ImageUploader } from '@/ui'
import StyledDatePicker from '../../../components/StyledDatePicker'
import { PagesSlugCaption } from './PagesSlugCaption'
import { ChangeEvent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { XClose } from '@/icons'
import { S3Bucket } from '@/types'
import { usePageUpdateMutate } from '../api/usePageUpdateMutate'
import { usePageCreateMutate } from '../api/usePageCreateMutate'
import slugify from '../../../libs/slugify'
import { LexicalController } from '@/components/lexical'

interface PageFormProps {
  websiteUrl?: string
  item?: PageRead
  parents: PageRead[]
  onClose: () => void
  createType?: PageLayoutType
}

const MAX_SLUG_LENGTH = 64

export const PagesForm = ({ websiteUrl, item, onClose, createType, parents }: PageFormProps) => {
  const { companyId, gameId, parent } = useParams() as { companyId: string; gameId: string; parent?: string }
  const { t } = useTranslation()

  const { mutateAsync: updateMutateAsync } = usePageUpdateMutate(companyId, gameId)
  const { mutateAsync: createMutateAsync } = usePageCreateMutate(companyId, gameId)

  const [confirmPublish, setConfirmPublish] = useState<boolean | null>(null)

  const parentID =
    item?.parent_id || (parent && createType !== PageLayoutType.Collection)
      ? parents.find(p => p.slug === parent)?.id
      : undefined
  const {
    setValue,
    formState: { errors, dirtyFields, isSubmitting },
    setError,
    control,
    register,
    watch,
    handleSubmit,
    reset,
  } = useForm<PageRead>({
    defaultValues: {
      title: '',
      date: dayjs().format('YYYY-MM-DD'),
      type: createType || PageLayoutType.Article,
      slug: '',
      html: '',
      description: '',
      image_url: '',
      text: '',
      parent_id: parentID,
      ...item,
    },
  })

  const [id, title, slug, parent_id, published_at] = watch(['id', 'title', 'slug', 'parent_id', 'published_at'])

  const publishClick = async () => {
    let p = published_at ? undefined : new Date().getTime() / 1000
    setValue('published_at', p, { shouldDirty: false })
    setConfirmPublish(null)

    await updateMutateAsync({
      pageId: item!.id,
      update: {
        published_at: p ? p : (null as unknown as number),
      },
    })
  }

  const onChangeTitle = (e: ChangeEvent<HTMLInputElement>, field: ControllerRenderProps<PageRead, 'title'>) => {
    if (!item?.id) {
      setValue('slug', slugify(e.target.value))
    }
    field.onChange(e.target.value)
  }

  const onFormSubmit = async (data: PageRead) => {
    try {
      if (item?.id) {
        await updateMutateAsync({
          pageId: data.id,
          update: data,
        })
      } else {
        await createMutateAsync({
          create: data,
        })
      }

      reset(data)
      onClose()
    } catch (responseError) {
      // @ts-ignore
      if (responseError.error.detail === `This 'Page' resource already exists`) {
        setError('slug', { message: t('validation.page-slug-already-exists') })
      }
    }
  }

  return (
    <form className="flex h-full flex-col overflow-y-auto" onSubmit={handleSubmit(onFormSubmit)}>
      {confirmPublish !== null && (
        <ConfirmDialog
          color={published_at ? 'error' : 'primary'}
          message={i18next.t(published_at ? 'pages.confirm.unpublish' : 'pages.confirm.publish')}
          confirmButtonText={i18next.t(published_at ? 'edit-news-dialog.unpublish' : 'edit-news-dialog.publish')}
          subMessage={i18next.t(published_at ? 'pages.confirm.unpublish.message' : 'pages.confirm.publish.message', {
            name: title,
          })}
          onCancel={() => setConfirmPublish(null)}
          onConfirm={publishClick}
        />
      )}

      <DrawerTopbar>
        <ButtonIcon variant="secondary-gray" size="sm" type="button" onClick={onClose}>
          <XClose />
        </ButtonIcon>
        <h2 className="mr-auto text-title-t5">
          {i18next.t(item?.id ? 'pages.edit.header' : 'pages.edit.header.create')}
        </h2>
        <div className="flex gap-2">
          {item?.id && (
            <Button
              variant="outline"
              type="button"
              onClick={() => (published_at ? setConfirmPublish(false) : publishClick())}
              disabled={Object.keys(dirtyFields).length > 0}
            >
              {i18next.t(published_at ? 'edit-news-dialog.unpublish' : 'edit-news-dialog.publish')}
            </Button>
          )}
          <Button type="submit" loading={isSubmitting} disabled={Object.keys(dirtyFields).length == 0}>
            {i18next.t(item?.id ? 'Save2' : 'Create')}
          </Button>
        </div>
      </DrawerTopbar>

      <DrawerContent className="flex h-full flex-col overflow-y-auto">
        <FieldGroup label={i18next.t('pages.edit.title')}>
          <Controller
            control={control}
            name="title"
            rules={{ required: i18next.t('validation.required') }}
            render={({ field }) => (
              <Input
                {...field}
                {...formatHookFormErrors(errors, 'title')}
                autoFocus={true}
                onChange={e => onChangeTitle(e, field)}
              />
            )}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('pages.edit.description')}>
          <Textarea {...register('description')} {...formatHookFormErrors(errors, 'description')} rows={6} />
        </FieldGroup>

        <FieldGroup
          tooltip={i18next.t('sku.sku-desc')}
          label={i18next.t('pages.edit.slug')}
          caption={
            <PagesSlugCaption base={websiteUrl} slug={slug} parentSlug={parents.find(p => p.id === parent_id)?.slug} />
          }
        >
          <Controller
            control={control}
            name="slug"
            rules={{
              required: t('validation.required'),
              maxLength: {
                message: t('loyalty.summary.name.validation', { max: MAX_SLUG_LENGTH }),
                value: MAX_SLUG_LENGTH,
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                {...formatHookFormErrors(errors, 'slug')}
                onChange={e => {
                  field.onChange(slugify(e.target.value))
                }}
              />
            )}
          />
        </FieldGroup>

        <FieldGroup>
          <Controller
            control={control}
            name="image_url"
            render={({ field }) => (
              <ImageUploader
                accept={{
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'image/png': [],
                  'image/webp': [],
                }}
                bucket={S3Bucket.pages}
                {...field}
              />
            )}
          />
          <ErrorMessage
            name="image_url"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>

        <div className="grid grid-cols-3 gap-5">
          <FieldGroup label={i18next.t('pages.edit.type')}>
            <Controller
              name="type"
              control={control}
              rules={{ required: t('validation.required') }}
              disabled={!!id}
              render={({ field }) => (
                <Select
                  {...field}
                  {...formatHookFormErrors(errors, 'type')}
                  items={[
                    {
                      children: i18next.t(`pages.type.${PageLayoutType.Article}`),
                      caption: i18next.t(`pages.type.${PageLayoutType.Article}.desc`),
                      value: PageLayoutType.Article,
                    },
                    {
                      children: i18next.t(`pages.type.${PageLayoutType.Content}`),
                      caption: i18next.t(`pages.type.${PageLayoutType.Content}.desc`),
                      value: PageLayoutType.Content,
                    },
                  ]}
                />
              )}
            />
          </FieldGroup>
          <FieldGroup label={i18next.t('pages.edit.parent')}>
            <Controller
              control={control}
              name="parent_id"
              render={({ field }) => (
                <Select
                  {...field}
                  {...formatHookFormErrors(errors, 'parent_id')}
                  items={[
                    { value: null, children: i18next.t('pages.edit.uncategorized') },
                    ...parents.map(p => ({ value: p.id, children: p.title })),
                  ]}
                />
              )}
            />
          </FieldGroup>
          <FieldGroup label={i18next.t('pages.edit.date')}>
            <Controller
              control={control}
              name="date"
              rules={{ required: t('validation.required') }}
              render={({ field }) => <StyledDatePicker fullWidth={true} {...field} value={field.value || null} />}
            />
            <ErrorMessage
              name="date"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </div>

        <FieldGroup label={i18next.t('pages.edit.content')}>
          <ErrorMessage
            name="html"
            errors={errors}
            render={({ message }) => (
              <div className="mb-1">
                <FormErrorMessage>{message}</FormErrorMessage>
              </div>
            )}
          />
          <LexicalController control={control} name="html" rules={{ required: t('validation.required') }} />
        </FieldGroup>
      </DrawerContent>
    </form>
  )
}
