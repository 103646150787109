import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const Clock: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3ZM1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9ZM9 4.2C9.41421 4.2 9.75 4.53579 9.75 4.95V8.53647L12.0354 9.67918C12.4059 9.86442 12.5561 10.3149 12.3708 10.6854C12.1856 11.0559 11.7351 11.2061 11.3646 11.0208L8.66459 9.67082C8.4105 9.54378 8.25 9.28408 8.25 9V4.95C8.25 4.53579 8.58579 4.2 9 4.2Z"
        fill="#475569"
      />
    </IconBase>
  );
});
