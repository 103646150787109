import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/components/icons'

export const InsertColumnLeftOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 20C7.89543 20 7 19.1046 7 18C7 17.4477 6.55228 17 6 17C5.44772 17 5 17.4477 5 18C5 20.2091 6.79086 22 9 22L18 22C20.2091 22 22 20.2091 22 18L22 6C22 3.79086 20.2091 2 18 2L9 2C6.79086 2 5 3.79086 5 6C5 6.55229 5.44771 7 6 7C6.55228 7 7 6.55229 7 6C7 4.89543 7.89543 4 9 4L12.2 4C12.3657 4 12.5 4.13431 12.5 4.3L12.5 19.7C12.5 19.8657 12.3657 20 12.2 20L9 20ZM14.5 19.7C14.5 19.8657 14.6343 20 14.8 20L18 20C19.1046 20 20 19.1046 20 18L20 6C20 4.89543 19.1046 4 18 4L14.8 4C14.6343 4 14.5 4.13431 14.5 4.3L14.5 19.7Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 8C6.55228 8 7 8.44772 7 9V10.7C7 10.8657 7.13431 11 7.3 11H9C9.55228 11 10 11.4477 10 12C10 12.5523 9.55228 13 9 13H7.3C7.13431 13 7 13.1343 7 13.3V15C7 15.5523 6.55228 16 6 16C5.44772 16 5 15.5523 5 15V13.3C5 13.1343 4.86569 13 4.7 13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H4.7C4.86569 11 5 10.8657 5 10.7L5 9C5 8.44772 5.44772 8 6 8Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
