import i18next from 'i18next'
import { useParams } from 'react-router-dom'
import { Block, NewsSortField, SortOrder } from '../../api/dashboard'
import { useNewsQuery } from '../../layouts/news/api/useNewsQuery'
import { Controller, useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { FieldGroup, FieldValidationMessage, Select } from '@/ui'

const SelectNewsPanel = () => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  const { data: items = [] } = useNewsQuery(companyId, gameId, {
    sort_order: SortOrder.Desc,
    sort_field: NewsSortField.Date,
  })

  const {
    setValue,
    formState: { errors },
    control,
  } = useFormContext<Block>()

  return (
    <FieldGroup label={i18next.t('hub-editor.block-news')}>
      <Controller
        name="news_id"
        control={control}
        rules={{ required: i18next.t('validation.required') }}
        render={({ field }) => (
          <>
            <Select
              value={field.value}
              options={items
                .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
                .map(it => ({
                  value: it.id,
                  children: it.title,
                }))}
              onChange={v => setValue('news_id', v as string)}
            />
            <ErrorMessage
              name="item_id"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </>
        )}
      />
    </FieldGroup>
  )
}

export default SelectNewsPanel
