import { useContext, useEffect, useState } from 'react'
import ProfileButton from './ProfileButton'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { canManageUsers } from '../../security'
import { CompanyGame, InviteRead, Role } from '../../api/dashboard'
import { useAuth0 } from '@auth0/auth0-react'
import { AuthContext, IAuthContext } from '../../Context'
import { ToastSeverity, Toggle, Tooltip, useModal, useToast } from '@/ui'
import EditInviteDialog from '../users/EditInviteDialog'
import { initFeedbackIntegration } from '@/libs/sentry'
import { addCompanyToCache, useCurrentGame } from '@/api/useCompaniesQuery'
import { dashboardClient, getErrorText } from '@/api'
import { useQueryClient } from '@tanstack/react-query'
import { HOME_PATH } from '@/libs'
import { setLastGameId } from '@/Settings'
import { useHubGeneratingStatus } from '@/layouts/get-started/hooks'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@/libs/hooks/useFeatureFlags'

const AppHeader = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()
  const context = useContext(AuthContext) as IAuthContext
  const { user } = useAuth0()
  const { gameId, companyId } = useParams() as { companyId: string; gameId: string }
  const { company, game, getSandboxGame, setSandboxGameState } = useCurrentGame()
  const queryClient = useQueryClient()
  const showToast = useToast()
  const location = useLocation()
  const [loadingSandbox, setLoadingSandbox] = useState(false)
  const openInviteModal = useModal<{ item: InviteRead }>(props => <EditInviteDialog {...props} />)
  const [sandBoxMode, setSandboxMode] = useState(game?.sandbox)
  const { isHubGenerated } = useHubGeneratingStatus()

  const isGetStartedPage = location.pathname.includes('get-started')

  useEffect(() => {
    initFeedbackIntegration(user?.email, context.customer?.locale)
  }, [])

  useEffect(() => {
    setSandboxMode(!!game?.sandbox)
  }, [game])

  const onSandboxChanged = async (v: boolean) => {
    setSandboxMode(v)

    if (!v) {
      if (game?.live_game_id) {
        navigate(generatePath(HOME_PATH, { companyId: companyId, gameId: game.live_game_id }))
        setSandboxGameState(game.live_game_id, false)
        setLastGameId(game.live_game_id)
      }
      return
    }

    let sandgame = getSandboxGame()
    if (sandgame) {
      setSandboxGameState(gameId, true)
      setLastGameId(sandgame.id)
      navigate(generatePath(HOME_PATH, { companyId: companyId, gameId: sandgame.id }))
      return
    }

    try {
      setLoadingSandbox(true)
      const { data: newGame } = await dashboardClient.v1.createSandbox(companyId, gameId)
      if (game) {
        game.sandbox_game_id = newGame.id
      }
      addCompanyToCache(queryClient, {
        ...company,
        games: [newGame],
      } as CompanyGame)
      setSandboxGameState(gameId, true)
      setLastGameId(newGame.id)
      navigate(generatePath(HOME_PATH, { companyId: companyId, gameId: newGame.id }))
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
      setSandboxMode(false)
    } finally {
      setLoadingSandbox(false)
    }
  }

  const renderToggleSandbox = () => {
    return (
      <div className="flex items-center gap-2 px-4">
        {t('header.sandbox')}
        <Toggle
          date-testid="header/toggle-sanbox"
          date-testvalue={sandBoxMode}
          disabled={!isHubGenerated}
          loading={loadingSandbox}
          sizev="md"
          variant={isGetStartedPage ? 'dark-secondary' : 'secondary'}
          checked={sandBoxMode}
          onChange={e => onSandboxChanged(e.target.checked)}
        />
      </div>
    )
  }

  return (
    <div className="relative z-10 flex h-[56px] w-full shrink-0 items-center justify-center">
      <div className={'w-full'}>
        <div className="grow" />
        <div className="flex items-center justify-end gap-2 text-caption-md font-medium text-text-primary">
          {gameId && canManageUsers(context.customer) && (
            <div
              className="cursor-pointer px-4"
              date-testid="header/invite-to-team"
              onClick={() => openInviteModal({ item: { role: Role.User } as InviteRead })}
            >
              {t('header.invite-friend')}
            </div>
          )}

          <div id="bug-report" date-testid="header/bug-report" className="cursor-pointer px-4">
            {t('header.bug-report')}
          </div>

          <div
            className="cursor-pointer px-4"
            date-testid="header/documentation"
            onClick={() => window.open('https://docs.aghanim.com/')}
          >
            {t('header.documentation')}
          </div>

          {game &&
            featureFlags.sandbox_enabled &&
            !company?.is_demo &&
            (isHubGenerated ? (
              renderToggleSandbox()
            ) : (
              <Tooltip message={isHubGenerated ? '' : t('header.sandbox.toggle')}>{renderToggleSandbox()}</Tooltip>
            ))}
          <ProfileButton />
        </div>
      </div>
    </div>
  )
}

export default AppHeader
