import { ReactNode } from 'react';
import { focusGroupKeyUX, hotkeyKeyUX, jumpKeyUX, pressKeyUX, startKeyUX } from 'keyux';

interface UIProviderProps {
  children: ReactNode;
}

startKeyUX(window, [hotkeyKeyUX([]), focusGroupKeyUX(), pressKeyUX('is-pressed'), jumpKeyUX()]);

export const UIProvider = ({ children }: UIProviderProps) => {
  return <div>{children}</div>;
};
