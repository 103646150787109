import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { PAGE_SIZE } from '../../../Settings'
import { GAME_WEBHOOKS_DETAIL_PATH } from '../../../libs/routerPaths'
import { Button, Page, PageHeader, Pagination, Table, TableCell, TableRow, usePagination } from '@/ui'
import { webhooksQuery } from '../api'
import { WebhookDiscoverCallout, WebhookTableZeroState } from './index'
import { WebhookRow, WebhookRowSkeleton } from '../components'
import { useContext } from 'react'
import { AuthContext, IAuthContext } from '@/Context'
import { isAdmin } from '@/security'

export const WebhookTable = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { page, query, onPageChange, needShowPagination } = usePagination(PAGE_SIZE)
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const context = useContext(AuthContext) as IAuthContext

  const addClick = () => {
    navigate(
      generatePath(GAME_WEBHOOKS_DETAIL_PATH, {
        companyId: companyId as string,
        gameId: gameId as string,
        webhookId: 'new',
      }),
    )
  }

  const { data: webhooks = [], isLoading } = useQuery(webhooksQuery(companyId, gameId, query))

  return (
    <Page>
      <PageHeader
        extra={
          isAdmin(context.customer) &&
          !!webhooks.length && (
            <Button variant="primary" onClick={addClick}>
              {t('webhook.add-webhook')}
            </Button>
          )
        }
      >
        {t('sidebar.webhooks')}
      </PageHeader>

      <WebhookDiscoverCallout />

      {!isLoading && page === 1 && !webhooks.length ? (
        <WebhookTableZeroState />
      ) : (
        <Table>
          <TableRow variant="header">
            <TableCell>{t('webhook.url')}</TableCell>
            <TableCell className="max-w-36">{t('webhook.event-count')}</TableCell>
            <TableCell className="max-w-36">{t('webhook.error-rate')}</TableCell>
            <TableCell className="max-w-36">{t('webhook.status')}</TableCell>
          </TableRow>

          {!isLoading &&
            webhooks.map(webhook => (
              <WebhookRow canEdit={isAdmin(context.customer)} key={`webhook-row-${webhook.id}`} webhook={webhook} />
            ))}
        </Table>
      )}

      {isLoading && (
        <Table>
          {new Array(PAGE_SIZE).fill('').map((_, index) => (
            <WebhookRowSkeleton key={`row-skeleton-${index}`} />
          ))}
        </Table>
      )}

      {needShowPagination(isLoading, webhooks) && (
        <Pagination hasNext={!isLoading && webhooks.length === PAGE_SIZE} page={page} onPageChange={onPageChange} />
      )}
    </Page>
  )
}
