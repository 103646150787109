import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ItemOrderType, ItemRead, ItemType, ResourceState } from '@/api/dashboard'
import {
  Badge,
  Button,
  ButtonIcon,
  Modal,
  ModalProps,
  Pagination,
  Table,
  TableCell,
  TableCellBulkCheckbox,
  TableRow,
  TableZeroState,
  usePaginationMemory,
} from '@/ui'
import { CloseOutline, XClose } from '@/icons'
import { Query, useGameItemsQuery } from '@/api/useGameItems'
import { useParams } from 'react-router-dom'
import { InputSearch, Skeleton } from '@dashboard/ui'
import { useDebounce } from '@/hooks'
import { SkuImageName } from '@/layouts/game-items/components/SkuImageName'

interface ItemsPickerProps extends ModalProps {
  title: string
  limit?: number
  picked?: ItemRead[]
  onClose?: () => void
  onSubmit?: (picked: ItemRead[]) => void
  types?: ItemType[]
}

export const SelectSkuModal = ({
  title,
  limit,
  types,
  picked: existed = [],
  onClose = () => {},
  onSubmit,
}: ItemsPickerProps) => {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { t } = useTranslation()
  const [picked, setPicked] = useState<ItemRead[]>(existed || [])
  const { page, query, onPageChange, needShowPagination } = usePaginationMemory(100)
  const { data: items = [], isLoading } = useGameItemsQuery(companyId, gameId, {
    ...query,
    search_string: debouncedSearch,
    state: ResourceState.Active,
    types: types,
    order_by: ItemOrderType.CreatedAt,
  } as Query)

  return (
    <Modal className="flex h-2/3 flex-col gap-3 p-0 pb-2 pt-6" onClose={onClose}>
      <div className="flex size-full flex-col overflow-y-auto px-6">
        <div className="sticky top-0 z-10 inline-flex items-center justify-between bg-fg-primary">
          <div className="text-title-t4 text-text-secondary">{title}</div>
          <div className="inline-flex gap-3">
            {onSubmit && (
              <Button variant="primary" onClick={() => onSubmit(picked)}>
                {t('Save')}
              </Button>
            )}
            <ButtonIcon size="sm" variant="secondary-gray" onClick={onClose}>
              <XClose />
            </ButtonIcon>
          </div>
        </div>

        <div className="sticky top-[32px] z-10 h-fit w-full bg-fg-primary py-3">
          <InputSearch
            value={search}
            onChange={e => {
              setSearch(e.target.value)
              onPageChange(1)
            }}
            placeholder={t('search')}
          />
        </div>

        {items.length === 0 && !isLoading && search && (
          <TableZeroState
            title={t('sku.search.zero.title')}
            message={t('sku.search.zero.text')}
            buttons={
              <Button
                variant="outline"
                onClick={() => {
                  setSearch('')
                }}
              >
                <CloseOutline size={14} />
                <span>{t('sku.search.clear-filters')}</span>
              </Button>
            }
          />
        )}

        {isLoading ? (
          new Array(10).fill('').map((_, index) => <Skeleton className="mb-2 h-[32px]" key={index} />)
        ) : (
          <Table>
            {items.map(it => {
              const checked = !!picked.find(pickedItem => pickedItem.id === it.id)
              const disabled = !checked && !!(limit && picked.length >= limit)

              return (
                <TableRow key={it.id}>
                  <TableCellBulkCheckbox
                    selected={checked}
                    disabled={disabled}
                    onChange={({ target: { checked } }) => {
                      if (checked) {
                        const found: ItemRead | undefined = items.find(item => item.id === it.id)
                        found && setPicked([...picked, found])
                      } else {
                        const index = picked.findIndex(pickedItem => pickedItem.id === it.id)
                        setPicked([...picked.slice(0, index), ...picked.slice(index + 1)])
                      }
                    }}
                  />
                  <TableCell className="flex items-center gap-3" width="100%">
                    <SkuImageName item={it} />
                  </TableCell>
                  <TableCell width={80}>
                    <Badge variant={it.archived_at ? 'gray-primary' : 'lime-secondary'}>
                      {it.archived_at
                        ? t('daily-rewards.reward.item.state.archived')
                        : t('daily-rewards.reward.item.state.active')}
                    </Badge>
                  </TableCell>
                </TableRow>
              )
            })}
            {needShowPagination(isLoading, items) && (
              <Pagination
                className="pb-3"
                hasNext={items.length >= query.limit}
                page={page}
                onPageChange={onPageChange}
                pageItems={items.length}
              />
            )}
          </Table>
        )}
      </div>
    </Modal>
  )
}
