import { cva } from 'class-variance-authority'
import { cn } from '../../../libs/cn'
import { SendStatus, WebhookLogRead } from '../../../api/dashboard'
import { TableCell, Tooltip } from '@/ui'
import { Check, InfoCircle, XCircleFill } from '@/icons'
import { getDateTimeFormatParams } from '@/libs/dates'
import { useTranslation } from 'react-i18next'

interface WebhookLogRowProps {
  wl: WebhookLogRead
  id?: string
  className?: string
  onClick?: () => void
  selected?: boolean
}

const variants = cva(
  'mb-0.5 flex h-[44px] cursor-pointer items-center rounded-md text-left font-medium text-text-secondary transition-colors last:border-none',
  {
    variants: {
      variant: {
        default:
          'from-transparent to-bg-primary to-30% hover:bg-fg-secondary [&[datatype="selected"]]:bg-gradient-to-r',
        warning:
          'from-transparent to-fg-warning-tertiary to-30% text-text-warning-solid hover:bg-fg-warning-tertiary [&[datatype="selected"]]:bg-gradient-to-r',
        error:
          'from-transparent to-fg-error-tertiary to-30% text-text-error-solid hover:bg-fg-error-tertiary [&[datatype="selected"]]:bg-gradient-to-r',
      },
      defaultVariants: {
        variant: 'default',
      },
    },
  },
)

const getIcon = (wl: WebhookLogRead, variant: string) => {
  switch (variant) {
    case 'error':
      return (
        <Tooltip message={`status ${wl.status_code}`}>
          <XCircleFill className="text-text-error-primary" size={18} />
        </Tooltip>
      )
    case 'warning':
      return (
        <Tooltip message={`duration ${wl.duration} ms`}>
          <InfoCircle className="text-text-warning-primary" size={18} />
        </Tooltip>
      )
    default:
      return (
        <Tooltip message={`duration ${wl.duration} ms`}>
          <Check className="text-text-success-primary" size={18} strokeWidth={2} />
        </Tooltip>
      )
  }
}

const getVariant = (item: WebhookLogRead) => {
  if (item.status == SendStatus.Error || !item.status_code || item.status_code >= 400) {
    return 'error'
  }

  if (item.duration > 2000) {
    return 'warning'
  }

  return 'default'
}

export const WebhookLogRow = ({ wl, className, id, onClick, selected }: WebhookLogRowProps) => {
  const variant = getVariant(wl)
  const { t } = useTranslation()
  return (
    <div
      className={cn(variants({ variant }), className)}
      tabIndex={-1}
      id={id}
      datatype={selected ? 'selected' : ''}
      onClick={onClick}
    >
      <TableCell>
        <div className="flex items-center gap-2">
          {getIcon(wl, variant)}
          {wl.event_type}
        </div>
      </TableCell>
      <TableCell className="text-right text-text-secondary">
        {t('intl.DateTime', getDateTimeFormatParams(wl.created_at, 'short'))}
      </TableCell>
    </div>
  )
}
