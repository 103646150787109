import { ReactElement } from 'react'
import { SidebarLinkIconProps, SidebarLinkIconVariant } from '@/components/ui/AppSidebar/types'

const variantMap = new Map<SidebarLinkIconVariant, ReactElement>([
  [
    'default',
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.28368 2.4944L9.77247 6.66364C10.441 7.80897 9.57329 9.16666 8.30683 9.16666H3.35983C2.09337 9.16666 1.22567 7.80899 1.89423 6.66366L1.89706 6.6588L4.36508 2.49568C5.01281 1.39074 6.6352 1.39032 7.28368 2.4944ZM3.48413 7.24779C3.41771 7.35888 3.49629 7.49999 3.62572 7.49999C4.5494 7.49997 7.17472 7.49982 8.0601 7.49873C8.18307 7.49858 8.26045 7.37186 8.20199 7.26367C7.70503 6.34399 6.42217 4.26736 5.96612 3.55137C5.90067 3.44861 5.75319 3.45301 5.69067 3.55757L3.48413 7.24779ZM13.3333 3.33332C12.8731 3.33332 12.5 3.70642 12.5 4.16666V6.66666C12.5 7.12689 12.8731 7.49999 13.3333 7.49999H15.8333C16.2936 7.49999 16.6667 7.12689 16.6667 6.66666V4.16666C16.6667 3.70642 16.2936 3.33332 15.8333 3.33332H13.3333ZM10.8333 4.16666C10.8333 2.78594 11.9526 1.66666 13.3333 1.66666H15.8333C17.214 1.66666 18.3333 2.78594 18.3333 4.16666V6.66666C18.3333 8.04737 17.214 9.16666 15.8333 9.16666H13.3333C11.9526 9.16666 10.8333 8.04737 10.8333 6.66666V4.16666ZM4.16666 12.5C3.70643 12.5 3.33333 12.8731 3.33333 13.3333V15.8333C3.33333 16.2936 3.70643 16.6667 4.16666 16.6667H6.66666C7.1269 16.6667 7.5 16.2936 7.5 15.8333V13.3333C7.5 12.8731 7.1269 12.5 6.66666 12.5H4.16666ZM1.66666 13.3333C1.66666 11.9526 2.78595 10.8333 4.16666 10.8333H6.66666C8.04737 10.8333 9.16666 11.9526 9.16666 13.3333V15.8333C9.16666 17.214 8.04737 18.3333 6.66666 18.3333H4.16666C2.78595 18.3333 1.66666 17.214 1.66666 15.8333V13.3333ZM14.5833 12.5C13.4327 12.5 12.5 13.4327 12.5 14.5833C12.5 15.7339 13.4327 16.6667 14.5833 16.6667C15.7339 16.6667 16.6667 15.7339 16.6667 14.5833C16.6667 13.4327 15.7339 12.5 14.5833 12.5ZM10.8333 14.5833C10.8333 12.5123 12.5123 10.8333 14.5833 10.8333C16.6544 10.8333 18.3333 12.5123 18.3333 14.5833C18.3333 16.6544 16.6544 18.3333 14.5833 18.3333C12.5123 18.3333 10.8333 16.6544 10.8333 14.5833Z"
      fill="currentColor"
    />,
  ],
  [
    'fill',
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.28368 2.4944L9.77247 6.66364C10.441 7.80897 9.57329 9.16666 8.30683 9.16666H3.35983C2.09337 9.16666 1.22567 7.80899 1.89423 6.66366L1.89706 6.6588L4.36508 2.49568C5.01281 1.39074 6.6352 1.39032 7.28368 2.4944ZM3.48413 7.24779C3.41771 7.35888 3.49629 7.49999 3.62572 7.49999C4.5494 7.49997 7.17472 7.49982 8.0601 7.49873C8.18307 7.49858 8.26045 7.37186 8.20199 7.26367C7.70503 6.34399 6.42217 4.26736 5.96612 3.55137C5.90067 3.44861 5.75319 3.45301 5.69067 3.55757L3.48413 7.24779ZM13.3333 3.33332C12.8731 3.33332 12.5 3.70642 12.5 4.16666V6.66666C12.5 7.12689 12.8731 7.49999 13.3333 7.49999H15.8333C16.2936 7.49999 16.6667 7.12689 16.6667 6.66666V4.16666C16.6667 3.70642 16.2936 3.33332 15.8333 3.33332H13.3333ZM10.8333 4.16666C10.8333 2.78594 11.9526 1.66666 13.3333 1.66666H15.8333C17.214 1.66666 18.3333 2.78594 18.3333 4.16666V6.66666C18.3333 8.04737 17.214 9.16666 15.8333 9.16666H13.3333C11.9526 9.16666 10.8333 8.04737 10.8333 6.66666V4.16666ZM4.16666 12.5C3.70643 12.5 3.33333 12.8731 3.33333 13.3333V15.8333C3.33333 16.2936 3.70643 16.6667 4.16666 16.6667H6.66666C7.1269 16.6667 7.5 16.2936 7.5 15.8333V13.3333C7.5 12.8731 7.1269 12.5 6.66666 12.5H4.16666ZM1.66666 13.3333C1.66666 11.9526 2.78595 10.8333 4.16666 10.8333H6.66666C8.04737 10.8333 9.16666 11.9526 9.16666 13.3333V15.8333C9.16666 17.214 8.04737 18.3333 6.66666 18.3333H4.16666C2.78595 18.3333 1.66666 17.214 1.66666 15.8333V13.3333ZM14.5833 12.5C13.4327 12.5 12.5 13.4327 12.5 14.5833C12.5 15.7339 13.4327 16.6667 14.5833 16.6667C15.7339 16.6667 16.6667 15.7339 16.6667 14.5833C16.6667 13.4327 15.7339 12.5 14.5833 12.5ZM10.8333 14.5833C10.8333 12.5123 12.5123 10.8333 14.5833 10.8333C16.6544 10.8333 18.3333 12.5123 18.3333 14.5833C18.3333 16.6544 16.6544 18.3333 14.5833 18.3333C12.5123 18.3333 10.8333 16.6544 10.8333 14.5833Z"
      fill="currentColor"
    />,
  ],
  [
    'duotone',
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.28368 2.4944L9.77247 6.66364C10.441 7.80897 9.57329 9.16666 8.30683 9.16666H3.35983C2.09337 9.16666 1.22567 7.80899 1.89423 6.66366L1.89706 6.6588L4.36508 2.49568C5.01281 1.39074 6.6352 1.39032 7.28368 2.4944ZM10.8333 4.16666C10.8333 2.78594 11.9526 1.66666 13.3333 1.66666H15.8333C17.214 1.66666 18.3333 2.78594 18.3333 4.16666V6.66666C18.3333 8.04737 17.214 9.16666 15.8333 9.16666H13.3333C11.9526 9.16666 10.8333 8.04737 10.8333 6.66666V4.16666ZM1.66666 13.3333C1.66666 11.9526 2.78595 10.8333 4.16666 10.8333H6.66666C8.04737 10.8333 9.16666 11.9526 9.16666 13.3333V15.8333C9.16666 17.214 8.04737 18.3333 6.66666 18.3333H4.16666C2.78595 18.3333 1.66666 17.214 1.66666 15.8333V13.3333ZM10.8333 14.5833C10.8333 12.5123 12.5123 10.8333 14.5833 10.8333C16.6544 10.8333 18.3333 12.5123 18.3333 14.5833C18.3333 16.6544 16.6544 18.3333 14.5833 18.3333C12.5123 18.3333 10.8333 16.6544 10.8333 14.5833Z"
      fill="#334155"
    />,
  ],
])

export const ItemCategory = ({ variant = 'default', ...rest }: SidebarLinkIconProps) => {
  return (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {variantMap.get(variant)}
    </svg>
  )
}
