import { Typography } from '@mui/material'
import i18next from 'i18next'
import { useTheme } from '@mui/material/styles'
import { MAIN_MARGIN } from '../Settings'

import { Button } from '@/ui'
import { CSSProperties } from 'react'
import { cn } from '@/libs'
import { useBanner } from '@/libs/hooks/useBanner'
import { ChevronLeft } from '@/icons'

/**
 * @deprecated please use PageHeader instead.
 * @param props
 * @constructor
 */
const TableBar = (props: {
  onBtnClick?: () => void
  sticky?: boolean
  title: string
  btnText?: string
  sx?: CSSProperties
  backButtonText?: string
  onBackClick?: () => void
  btnDisabled?: boolean
  secondaryAction?: React.ReactNode
  customAction?: React.ReactNode
  className?: string
  showBottomBorder?: boolean
}) => {
  const theme = useTheme()
  const { getStickyTop } = useBanner()

  return (
    <>
      <div
        className={cn(
          'flex h-[56px] min-h-[56px] items-center justify-start bg-fg-primary',
          props.className,
          props.sticky ? 'sticky z-[2]' : '',
          props.sticky && getStickyTop(),
        )}
        style={props.sx}
      >
        {props.onBackClick && (
          <Button size="md" style={{ marginRight: MAIN_MARGIN }} onClick={props.onBackClick}>
            <ChevronLeft />
          </Button>
        )}

        <div className="flex flex-col justify-between">
          {props.backButtonText && (
            <Typography variant="body2" color={theme.palette.text.tertiary}>
              {props.backButtonText}
            </Typography>
          )}
          <span
            style={{
              textTransform: 'uppercase',
              fontSize: '20px',
              fontFamily: 'Nohemi',
              color: theme.palette.text.focus,
            }}
          >
            {props.title}
          </span>
        </div>

        <div className="ml-auto">
          {props.secondaryAction}
          {props.onBtnClick && (
            <Button
              variant={'primary'}
              size={'md'}
              disabled={props.btnDisabled === true}
              onClick={() => props.onBtnClick!()}
            >
              {props.btnText || i18next.t('AddItem')}
            </Button>
          )}
          {props.customAction}
        </div>
      </div>
      {props.showBottomBorder && <div className="w-full border-t border-border-secondary bg-fg-primary"> </div>}
    </>
  )
}

export default TableBar
