import { ConditionNode, Token, TokenType } from '../../../../api/dashboard'
import i18next from 'i18next'
import { Attribute, CAMPAIGN_ATTRIBUTES, OperatorGeneralType, OperatorType, SelectorValueType } from '../../expr/types'
import { isMoneyAttribute } from '../common'
import { formatMoney } from '../../../../util'
import { DescContext } from './types'
import { findAttr } from '@/layouts/campaigns/expr/findAttr'

export function getDescConditionNode(
  conditionNode: ConditionNode,
  context: DescContext,
): [string, React.ReactNode | string] {
  if (!conditionNode.expr?.tokens?.length) {
    return [i18next.t('campaign.block.ConditionNode'), '']
  }

  let tokensBy3items: Token[][] = []

  for (let i = 0; i < conditionNode.expr.tokens.length; i += 4) {
    tokensBy3items.push([
      conditionNode.expr.tokens[i - 1],
      conditionNode.expr.tokens[i],
      conditionNode.expr.tokens[i + 1],
      conditionNode.expr.tokens[i + 2],
    ])
  }

  const node = tokensBy3items.map((it, idx) => {
    let leftOperand = it[1]
    let leftPart = leftOperand.value
    let leftAttrId = ''
    switch (leftOperand.type) {
      case TokenType.CustomAttribute:
      case TokenType.Attribute:
        {
          let leftAttr = findAttr(leftOperand.value)

          let name = leftAttr?.name || leftOperand.value
          leftPart = (
            <div className="inline-flex items-center">
              <span className="text-[14px] text-fg-brand-primary">{i18next.t('campaign.player')}</span>
              <span className="text-[14px] text-text-secondary">({name})</span>
            </div>
          )
          leftAttrId = leftOperand.value as string
        }
        break
      case TokenType.EventAttribute:
        {
          let leftAttr = Object.values(CAMPAIGN_ATTRIBUTES)
            .flat()
            .find(it => it.id == leftPart) as Attribute
          if (leftAttr?.id) {
            leftAttrId = leftAttr.id
          }
          leftPart = (
            <div className="inline-flex items-center">
              <span className="text-[14px] text-fg-brand-primary">{i18next.t('campaign.event')}</span>
              <span className="text-[14px] text-text-secondary">({leftAttr?.name})</span>
            </div>
          )
        }
        break
    }

    let operatorType = it[2].value
    let operator = i18next.t('campaign.operator.' + operatorType)
    let leftAttr = findAttr(leftOperand.value)
    let value = it[3].value

    if (it[3].type == TokenType.Boolean) {
      value = value ? 'True' : 'False'
    }

    if (leftAttr?.generalType == OperatorGeneralType.contains_or_not) {
      switch (operatorType) {
        case OperatorType['==']:
          operator = i18next.t('campaign.operator.contains')
          break
        case OperatorType['!=']:
          operator = i18next.t('campaign.operator.not_contains')
          break
        case OperatorType.not_in_range:
        case OperatorType.in_range:
          break
      }

      switch (leftAttr.selectorValueType) {
        case SelectorValueType.sku_no_price:
        case SelectorValueType.sku:
          value = context.items.find(it => it.id == value)?.name || value
          break
        case SelectorValueType.segment:
          value = context.segments.find(it => it.id == value)?.name || value
          break
      }
    }

    switch (operatorType) {
      case OperatorType.in_list:
      case OperatorType.not_in_list:
        value = '(' + value.join(', ') + ')'
        break
      case OperatorType.is_none:
      case OperatorType.is_not_none:
        value = ''
        break
      case OperatorType.not_in_range:
      case OperatorType.in_range:
        let arr = value as number[]
        if (arr) {
          if (isMoneyAttribute(leftAttrId)) {
            value = formatMoney(arr[0]) + ' - ' + formatMoney(arr[1])
          } else {
            value = arr[0] + ' - ' + arr[1]
          }
        }
        break
      default:
        if (isMoneyAttribute(leftAttrId)) {
          value = formatMoney(value)
        }
    }

    let ifOrAnd = idx == 0 ? 'if' : it[0].value

    return (
      <div key={idx}>
        <span className="mr-1 text-[14px] text-text-secondary">{ifOrAnd}</span>
        {leftPart}
        <span className="ml-1 text-[14px] text-text-secondary">{operator}</span>
        {value != null && value != undefined && (
          <span className="ml-1 text-[14px]" style={{ color: '#AF6705' }}>
            {value}
          </span>
        )}
      </div>
    )
  })

  return [i18next.t('campaign.block.ConditionNode'), node]
}
