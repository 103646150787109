import { ChangeEvent, MouseEvent, ReactNode, useRef } from 'react'
import { Checkbox } from '../Checkbox'
import { TableCell } from './TableCell'

interface TableCellBulkCheckboxProps {
  selected: boolean
  disabled?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  children?: ReactNode
}

export const TableCellBulkCheckbox = ({ selected, disabled, onChange, children }: TableCellBulkCheckboxProps) => {
  const checkboxWrapperRef = useRef<HTMLInputElement>(null)

  const onClickCell = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (!checkboxWrapperRef.current?.contains(e.target as HTMLElement)) {
      e.preventDefault()
    }
  }

  return (
    <TableCell className="overflow-visible" width="auto" onClick={onClickCell}>
      <Checkbox
        wrapperRef={checkboxWrapperRef}
        checked={!disabled ? selected : false}
        disabled={disabled}
        onChange={onChange}
      >
        {children}
      </Checkbox>
    </TableCell>
  )
}
