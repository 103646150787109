import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Locale } from '@/api/dashboard'
import { useCompanyEventsSocket, useGenerateCompanyPath } from '@/libs'
import { WSEventType } from '@/types'
import { localeTranslateAllQueryOptions } from '../api'

export interface LocaleTranslateMessageProgress {
  type: WSEventType.translation_progress
  payload: {
    locale_id: string
    total: number
    done: number
  }
}

export interface LocaleTranslateMessageComplete {
  type: WSEventType.translation_complete
  payload: void
}

export interface LocaleTranslateMessageSyncComplete {
  type: WSEventType.translation_sync_complete
  payload: {
    locale: Locale
  }
}

export interface LocaleTranslateMessageStatusChange {
  type: WSEventType.locale_status_change
  payload: {
    locale: Locale
  }
}

export type LocaleTranslateAllMessage =
  | LocaleTranslateMessageProgress
  | LocaleTranslateMessageComplete
  | LocaleTranslateMessageSyncComplete
  | LocaleTranslateMessageStatusChange

export const useLocaleTranslateAllListener = () => {
  const ql = useQueryClient()
  const { companyId, gameId } = useGenerateCompanyPath()

  const handleTranslationProgress = useCallback(
    (message: LocaleTranslateMessageProgress) => {
      ql.setQueryData(localeTranslateAllQueryOptions(gameId, message.payload.locale_id).queryKey, {
        total: message.payload.total,
        done: message.payload.done,
      })
    },
    [ql, companyId, gameId],
  )

  const onMessage = useCallback(
    (message: LocaleTranslateAllMessage) => {
      switch (message.type) {
        case WSEventType.translation_progress:
          handleTranslationProgress(message)
          break
        case WSEventType.translation_complete:
          ql.refetchQueries({ queryKey: [gameId, 'locales'] })
          ql.refetchQueries({ queryKey: [gameId, 'translations'] })
          break
        case WSEventType.translation_sync_complete:
          ql.refetchQueries({ queryKey: [gameId, 'locales'] })
          ql.refetchQueries({ queryKey: [gameId, 'translations'] })
          break
        case WSEventType.locale_status_change:
          break
      }
    },
    [companyId, gameId, handleTranslationProgress],
  )

  useCompanyEventsSocket<LocaleTranslateAllMessage>({
    onMessage,
  })
}
