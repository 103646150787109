import { AghanimIcon, IconBase } from '../../../components/icons/IconBase'
import { forwardRef } from 'react'

export const ArrowsUpIcon: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 9 9" ref={ref}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.50651 0.492842C8.36944 0.355766 8.18217 0.28086 7.98838 0.285587L2.85449 0.410804C2.4634 0.420343 2.1541 0.745113 2.16363 1.1362C2.17317 1.52728 2.49794 1.83659 2.88903 1.82705L6.25261 1.74501L0.492638 7.50498C0.216016 7.78161 0.216016 8.2301 0.492638 8.50672C0.769259 8.78334 1.21775 8.78334 1.49437 8.50672L7.25435 2.74675L7.17231 6.11033C7.16277 6.50141 7.47207 6.82618 7.86316 6.83572C8.25424 6.84526 8.57901 6.53596 8.58855 6.14487L8.71377 1.01098C8.7185 0.817184 8.64359 0.629918 8.50651 0.492842Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
