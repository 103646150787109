import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LocaleRead, LocaleStatus } from '@/api/dashboard'
import { ButtonIcon, Floating, SidebarLink, Spinner } from '@/ui'
import { DotsHorizontal } from '@/icons'
import { LOCALIZATION_LOCALE_PATH, getPercentFrom, useGenerateCompanyPath } from '@/libs'
import { LOCALE_FLAGS } from '@/util'
import { LocaleMenu } from './LocaleMenu'

interface LocaleSidebarLinkProps {
  locale: LocaleRead
}

interface LocaleFlagProps {
  locale: LocaleRead
}

const LocaleFlag = ({ locale }: LocaleFlagProps) => {
  return (
    <div className="relative">
      {locale.published_at && (
        <>
          {locale.status === LocaleStatus.Clean && (
            <div className="absolute -right-1.5 -top-1.5 size-3 rounded-full border-2 border-fg-primary bg-fg-lime-primary shadow-xs" />
          )}
          {locale.status === LocaleStatus.Synchronization && (
            <div className="absolute -right-1.5 -top-1.5 size-3 animate-pulse rounded-full border-2 border-fg-primary bg-fg-lime-primary shadow-xs" />
          )}
          {locale.status === LocaleStatus.Fuzzy && (
            <div className="absolute -right-1.5 -top-1.5 size-3 rounded-full border-2 border-fg-primary bg-fg-warning-primary-error shadow-xs" />
          )}
        </>
      )}
      <img className="w-5 rounded-sm" src={`${LOCALE_FLAGS}/${locale.locale.toUpperCase()}.svg`} alt={locale.locale} />
    </div>
  )
}

export const LocaleSidebarLink = ({ locale }: LocaleSidebarLinkProps) => {
  const { t } = useTranslation()
  const { generatePath } = useGenerateCompanyPath()

  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const renderExtraMenu = () => {
    return (
      <Floating
        menu={<LocaleMenu locale={locale} />}
        placement="bottom-end"
        stopPropagation={true}
        onOpenCallback={setIsOpenMenu}
      >
        <ButtonIcon variant="tertiary-gray" size="xs" onClick={e => e.stopPropagation()}>
          <DotsHorizontal />
        </ButtonIcon>
      </Floating>
    )
  }

  return (
    <SidebarLink
      data-testid={`l10n/sidebar/link-${locale.locale}`}
      to={generatePath(LOCALIZATION_LOCALE_PATH, { localeId: locale.id }, true)}
      icon={<LocaleFlag locale={locale} />}
      extra={locale.status !== LocaleStatus.Translating && renderExtraMenu()}
      extraAlways={isOpenMenu}
    >
      <div className="flex w-full items-center gap-3">
        <div className="flex w-full items-center gap-1 truncate">
          <span className="truncate">{t(`locales.${locale.locale}`)}</span>
          <span className="text-text-quarterary-hover">·</span>
          <span className="tabular-nums text-text-quarterary-hover">
            {getPercentFrom(locale.stats.done || 0, locale.stats.total || 0)}%
          </span>
        </div>
        {locale.status === LocaleStatus.Translating && (
          <div className="ml-auto">
            <Spinner className="text-border-brand" size={22} />
          </div>
        )}
      </div>
    </SidebarLink>
  )
}
