import i18next from 'i18next'
import PageBlock from '../../game-items/components/PageBlock'
import { useParams } from 'react-router-dom'
import { useWebhookLogOrderQuery } from '../api/useWebhookLogOrderQuery'
import { WebhookLogsInspector } from '../../webhooks'
import { TableRowSkeleton, TableZeroState } from '@/ui'
import { PaymentStatus } from '../../../pay-types'
import { useRef } from 'react'
import { useInView } from 'framer-motion'

export const WebhookLogTimeline = (props: { orderId: string; paymentStatus: PaymentStatus }) => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  const ref = useRef(null)
  const isInView = useInView(ref)
  const { data = [], isLoading } = useWebhookLogOrderQuery(companyId, gameId, props.orderId, isInView)
  let isZeroState = false

  switch (props.paymentStatus) {
    case PaymentStatus.done:
    case PaymentStatus.refunded:
      if (data.length == 0 && !isLoading) {
        isZeroState = true
      }
      break
    default:
      // for other statuses webhooks log for transaction is not available
      if (!isLoading) {
        isZeroState = true
      }
      break
  }

  return (
    <PageBlock title={i18next.t('transaction-details.webhook-log')} ref={ref}>
      {isLoading && <TableRowSkeleton rowCount={5} columnCount={3} />}
      {isZeroState ? (
        <TableZeroState
          className="mt-4 h-[300px]"
          title={i18next.t('transaction-details.webhook-log.no-logs')}
          message={i18next.t('transaction-details.webhook-log.no-logs.desc')}
        />
      ) : (
        <WebhookLogsInspector
          items={data}
          className="max-h-[630px] overflow-hidden rounded-xl border border-border-secondary px-2"
        />
      )}
    </PageBlock>
  )
}
