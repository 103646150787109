import { useAuth0 } from '@auth0/auth0-react'
import { HUB_API } from '../HubAPI'
import { useEffect, useRef, useState } from 'react'

export const useAuthToken = () => {
  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect, user, handleRedirectCallback } = useAuth0()
  const [authDone, setAuthDone] = useState<boolean>(false)
  const [targetUrl, setTargetUrl] = useState<string | null>(null)
  const shouldRedirect = useRef(true)

  const loadToken = async () => {
    if (!shouldRedirect.current) {
      return
    }

    shouldRedirect.current = false

    let appState = {
      targetUrl: '/',
    }

    try {
      const result = await handleRedirectCallback()
      appState = result.appState
    } catch (e) {
      console.log(e)
    }

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: import.meta.env.VITE_AUTH0_API_AUDIENCE,
          scope: 'read:current_user',
        },
      })

      const target = appState.targetUrl || '/'
      setTargetUrl(target)

      HUB_API.setToken(accessToken)
      setAuthDone(true)
    } catch (e) {
      loginWithRedirect({ appState: { targetUrl: window.location.pathname } })
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      loadToken()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (!HUB_API.getToken()) {
      loadToken()
    } else {
      setAuthDone(true)
    }
  }, [])

  if (authDone) {
    return { isLoading: false, loginWithRedirect, authDone: true, targetUrl, user }
  }

  return { isLoading: true, loginWithRedirect, authDone, user }
}
