import { ItemRead } from '@/api/dashboard'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '@/Settings'
import { Tooltip } from '@/ui'

export const BundleNestedItems = (props: { item: ItemRead }) => {
  if (!props.item.nested_items_read) {
    return null
  }

  const renderItem = (it: ItemRead, idx: number) => {
    return (
      <Tooltip message={it.name} options={{ arrow: false, placement: 'top' }}>
        <div
          key={it.id}
          className="relative inline-block size-[38px] rounded-md border border-border-primary bg-fg-tertiary bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${it.image_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM})`,
            left: `-${idx * 12}px`,
            zIndex: props.item.nested_items_read!.length - idx,
          }}
        />
      </Tooltip>
    )
  }

  let viewList = props.item.nested_items_read.slice(0, 4)
  let moreCount = props.item.nested_items_read.length - viewList.length

  return (
    <div>
      {viewList.map((it, idx) => renderItem(it, idx))}
      {moreCount > 0 && (
        <div
          style={{
            left: `-${(viewList.length - 2) * 12}px`,
          }}
          className="relative inline-flex size-[38px] items-center justify-center rounded-md border border-border-primary bg-fg-tertiary bg-cover bg-center bg-no-repeat align-top text-paragraph-sm text-text-tertiary"
        >
          +{moreCount}
        </div>
      )}
    </div>
  )
}
