import { ObjectType, TranslationRead } from '@/api/dashboard'

export const isHtmlTranslation = (translation: TranslationRead) => {
  if (translation.object_property === 'html') {
    return true
  }

  if (translation.object_type == ObjectType.DailyReward && translation.object_property === 'description') {
    return true
  }

  return false
}
