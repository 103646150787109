import { AghanimIcon, IconBase } from '../../../components/icons/IconBase'
import { forwardRef } from 'react'

export const ArrowsDownIcon: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 9 9" ref={ref}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.50716 8.50651C8.64423 8.36944 8.71914 8.18217 8.71441 7.98838L8.5892 2.85449C8.57966 2.4634 8.25489 2.1541 7.8638 2.16363C7.47272 2.17317 7.16341 2.49794 7.17295 2.88903L7.25499 6.25261L1.49502 0.492637C1.21839 0.216016 0.769903 0.216016 0.493282 0.492637C0.21666 0.769259 0.21666 1.21775 0.493281 1.49437L6.25326 7.25435L2.88967 7.17231C2.49859 7.16277 2.17382 7.47207 2.16428 7.86316C2.15474 8.25424 2.46404 8.57901 2.85513 8.58855L7.98902 8.71377C8.18282 8.7185 8.37008 8.64359 8.50716 8.50651Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
