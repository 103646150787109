import { InputProps } from '../../components';

/**
 * Format errors from hook form to be used in (input, select) components.
 * @param errors
 * @param field
 */
export const formatHookFormErrors = (
  errors: Record<string, { message?: string }>,
  field: string,
  // TODO: @ds.pankov create type for base controls props, instead of using InputProps.
): Partial<Pick<InputProps, 'color' | 'error'>> => {
  const error = errors[field]?.message;

  if (error) {
    return {
      color: 'danger',
      error,
    };
  }

  return {};
};
