import i18next from 'i18next'
import { Button, ConfirmDialog, Table, TableCell, TableRow, TableRowEditButton, Tooltip, useModal } from '@/ui'
import { Header } from '../../components/ui/Header'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { SegmentGroupRead, SegmentGroupReadStat, SegmentGroupType, SegmentationProgress } from '../../api/dashboard'
import EditSegmentGroupDialog from './widgets/EditSegmentGroupDialog'
import { useSegmentGroupUpdateMutate, useSegmentGroupsQuery } from './api/useSegmentGroupsQuery'
import { dashboardClient } from '../../api'
import { GAME_SEGMENT_EDIT_GROUP_GRAPH_PATH } from '../../libs/routerPaths'
import { AuthContext, IAuthContext } from '../../Context'
import { canEdit } from '../../security'
import { WSEventType, WSMessage } from '../../types'
import { connectToWS } from '../../WSListener'
import { EditIcon } from '../../icons/Icons'
import { InfoCircle, RefreshIcon, Trash } from '@/icons'
import { isSuperAdmin } from '../../util'
import AnimatedDots from '../../components/animated-dots/AnimatedDots'

interface Props {
  onClose: () => void
}

export default function SegmentGroupTable(props: Props) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const context = useContext(AuthContext) as IAuthContext
  const navigate = useNavigate()
  const { data: items = [], isLoading, refetch } = useSegmentGroupsQuery(companyId, gameId)
  const { updateCacheFieldById } = useSegmentGroupUpdateMutate(companyId, gameId)

  const [deleteConfirmItem, setDeleteConfirmItem] = useState<SegmentGroupRead | undefined>(undefined)
  const [calcConfirmItem, setCalcConfirmItem] = useState<SegmentGroupRead | undefined>(undefined)

  const authContext = useContext(AuthContext) as IAuthContext
  const isReadOnly = !canEdit(authContext.customer)

  const onDeleteItemConfirm = async () => {
    if (deleteConfirmItem) {
      await dashboardClient.v1.deleteSegmentGroup(companyId, gameId, deleteConfirmItem.id)
      refetch()
    }
    setDeleteConfirmItem(undefined)
  }

  const onCalcConfirm = async () => {
    if (!calcConfirmItem) {
      return
    }
    setCalcConfirmItem(undefined)
    connectToWS(authContext)
    await dashboardClient.v1.calculateStaticSegmentGroup(companyId, gameId, calcConfirmItem.id)
    updateCacheFieldById(calcConfirmItem.id, {
      progress: {
        total: 1,
        completed: 0,
        segment_group_id: calcConfirmItem.id,
      },
    })
  }

  useEffect(() => {
    let msg = authContext?.state.wsMessage as WSMessage<SegmentationProgress>
    if (!msg) {
      connectToWS(authContext)
      return
    }

    switch (msg.type) {
      case WSEventType.segmentation_progress:
        updateCacheFieldById(msg.payload.segment_group_id, {
          progress: msg.payload.total == msg.payload.completed ? undefined : msg.payload,
        })
        break
    }
  }, [authContext?.state.wsMessage])

  const getProgressString = (progress: SegmentationProgress | undefined) => {
    if (!progress) {
      return ''
    }
    return (
      <>
        {Math.round((100 * progress.completed!) / progress.total) + '%'}
        <AnimatedDots />
      </>
    )
  }

  const onEditRulesClick = (it: SegmentGroupRead) => {
    navigate(generatePath(GAME_SEGMENT_EDIT_GROUP_GRAPH_PATH, { companyId, gameId, groupId: it.id }))
  }

  const openEditModal = useModal<{ item: SegmentGroupRead }>(props => <EditSegmentGroupDialog {...props} />)

  return (
    <div className="flex flex-col">
      <Header title={i18next.t('segments.edit-groups')}>
        <Button onClick={props.onClose}>{i18next.t('close')}</Button>
      </Header>
      <div className="flex items-center justify-end px-5 py-4">
        <Button
          onClick={() =>
            openEditModal({
              item: {
                name: 'New Group',
                type: SegmentGroupType.Static,
                enabled: true,
                is_template: false,
              } as SegmentGroupRead,
            })
          }
          variant="primary"
        >
          {i18next.t('segments.add-group')}
        </Button>
      </div>

      {deleteConfirmItem && (
        <ConfirmDialog
          color={'error'}
          confirmButtonText={i18next.t('remove')}
          subMessage={i18next.t('confirm.text', { name: deleteConfirmItem.name })}
          onCancel={() => setDeleteConfirmItem(undefined)}
          onConfirm={onDeleteItemConfirm}
        />
      )}

      {calcConfirmItem && (
        <ConfirmDialog
          color={'error'}
          cancelButtonText={i18next.t('confirm.no')}
          confirmButtonText={i18next.t('segment.group.calc.confirm.yes')}
          message={i18next.t('confirm.title')}
          subMessage={i18next.t('segment.group.calc.confirm', { group_name: calcConfirmItem.name })}
          onCancel={() => setCalcConfirmItem(undefined)}
          onConfirm={onCalcConfirm}
        />
      )}

      <div className="px-5">
        <div className="border-t border-border-primary">
          <Table>
            <TableRow variant="header">
              <TableCell width="200%">{i18next.t('segments.group.name')}</TableCell>
              <TableCell width="80%">{i18next.t('segments.group.type')}</TableCell>
              <TableCell width="60%">{i18next.t('segments.group.count')}</TableCell>
              <TableCell width="40%">{i18next.t('segments.group.progress')}</TableCell>
              <TableCell width="20%" />
            </TableRow>

            {!isLoading &&
              (items as SegmentGroupReadStat[]).map(it => (
                <TableRow key={it.id}>
                  <TableCell width="200%">
                    <div className="flex items-center gap-1">
                      {it.name}
                      {isSuperAdmin(context.customer) && it.is_template && (
                        <Tooltip message={i18next.t('segments.template')}>
                          <InfoCircle />
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                  <TableCell width="80%">
                    <div className="flex items-center gap-1">
                      {i18next.t(`segments.group.type.${it.type}`)}
                      {it.type == SegmentGroupType.Dynamic && (
                        <Tooltip message={i18next.t(it.enabled ? 'segments.published' : 'segments.not-published')}>
                          <InfoCircle
                            className={it.enabled ? 'text-text-success-primary' : 'text-text-warning-solid'}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                  <TableCell width="60%"> {it.segment_count}</TableCell>
                  <TableCell width="40%">{getProgressString(it.progress)}</TableCell>
                  <TableCell width="20%">
                    {!isReadOnly && (
                      <TableRowEditButton
                        onChange={v => {
                          switch (v) {
                            case 'edit':
                              openEditModal({ item: it })
                              break
                            case 'remove':
                              setDeleteConfirmItem(it)
                              break
                            case 'calc':
                              setCalcConfirmItem(it)
                              break
                            case 'edit-rules':
                              onEditRulesClick(it)
                              break
                          }
                        }}
                        options={[
                          !isReadOnly && {
                            icon: <EditIcon />,
                            children: i18next.t('segments.group.edit'),
                            value: 'edit',
                          },
                          !isReadOnly && {
                            icon: <EditIcon />,
                            children: i18next.t('segment.group.edit-rules'),
                            value: 'edit-rules',
                          },
                          it.type == SegmentGroupType.Static &&
                            !isReadOnly &&
                            !it.progress &&
                            it.segment_count > 0 && {
                              icon: <RefreshIcon />,
                              children: i18next.t('segment.group.calc'),
                              value: 'calc',
                            },
                          !isReadOnly && {
                            icon: (
                              <span className="text-text-error-primary">
                                <Trash />
                              </span>
                            ),
                            children: <span className="text-text-error-primary"> {i18next.t('grid.remove')} </span>,
                            value: 'remove',
                          },
                        ]}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </Table>
        </div>
      </div>
    </div>
  )
}
