import FullScreenActionBackground from '../layouts/components/FullScreenActionBackground/FullScreenActionBackground'
import { useState } from 'react'
import { ThemeContext, lightTheme } from '../App'
import { ThemeProvider } from '@mui/material'
import Header from '../layouts/errors/Header'

export default function ThemeLangOutlet(props: { children: React.ReactNode }) {
  const [theme] = useState(lightTheme)

  return (
    <ThemeContext.Provider value={{ theme: theme }}>
      <ThemeProvider theme={theme}>
        <FullScreenActionBackground header={<Header />}>{props.children}</FullScreenActionBackground>
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}
