import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const Range: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.71967 3.21967C2.01256 2.92678 2.48744 2.92678 2.78033 3.21967L6 6.43934L9.21967 3.21967C9.51256 2.92678 9.98744 2.92678 10.2803 3.21967C10.5732 3.51256 10.5732 3.98744 10.2803 4.28033L7.06066 7.5L10.2803 10.7197C10.5732 11.0126 10.5732 11.4874 10.2803 11.7803C9.98744 12.0732 9.51256 12.0732 9.21967 11.7803L6 8.56066L2.78033 11.7803C2.48744 12.0732 2.01256 12.0732 1.71967 11.7803C1.42678 11.4874 1.42678 11.0126 1.71967 10.7197L4.93934 7.5L1.71967 4.28033C1.42678 3.98744 1.42678 3.51256 1.71967 3.21967ZM13.0559 8.54969C13.5213 8.28656 14.0629 8.19129 14.5902 8.27984C15.1174 8.36838 15.5982 8.63536 15.9522 9.03609L15.9584 9.04313L15.9583 9.04317C16.3079 9.44913 16.5 9.96775 16.5 10.5001C16.5 11.3118 16.0472 11.8801 15.5808 12.2553C15.1455 12.6055 14.6144 12.8558 14.2371 13.0335C14.2228 13.0403 14.2088 13.0469 14.195 13.0534C13.8354 13.223 13.7103 13.3363 13.6442 13.4386C13.6329 13.456 13.6214 13.4762 13.61 13.5001H15.75C16.1642 13.5001 16.5 13.8358 16.5 14.2501C16.5 14.6643 16.1642 15.0001 15.75 15.0001H12.75C12.3358 15.0001 12 14.6643 12 14.2501C12 13.6281 12.0882 13.0824 12.3846 12.624C12.6822 12.1638 13.1196 11.9021 13.555 11.6967C13.9709 11.5006 14.3534 11.3176 14.6405 11.0867C14.9128 10.8675 15 10.6859 15 10.5001C15 10.3289 14.9382 10.159 14.8247 10.0255C14.6987 9.88442 14.5283 9.79046 14.3417 9.75912C14.1541 9.72761 13.9614 9.76132 13.7956 9.85461C13.6345 9.94714 13.5154 10.0912 13.4567 10.2563C13.3179 10.6466 12.889 10.8505 12.4987 10.7117C12.1085 10.573 11.9046 10.1441 12.0433 9.7538C12.2244 9.24444 12.5848 8.81888 13.0529 8.55138L13.0559 8.54968L13.0559 8.54969Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
