import { HTMLAttributes, ReactNode, forwardRef } from 'react';
import { HTMLMotionProps, motion } from 'framer-motion';
import { cn } from '../../libs';

interface MenuProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Items to be displayed in the menu.
   */
  children: ReactNode;
  /**
   * Props for the motion ul element. Use this to animate the menu.
   */
  motionProps?: HTMLMotionProps<'ul'>;
}

/**
 * Component for displaying a dropdown menu.
 */
export const Menu = forwardRef<HTMLDivElement, MenuProps>(({ children, motionProps, ...rest }, ref) => (
  <div {...rest} ref={ref} className={cn('w-full', rest.className)}>
    <motion.ul
      {...motionProps}
      className={cn(
        'group/menu',
        'box-border max-h-[45vh] w-full min-w-44 max-w-96 rounded-xl border border-border-primary bg-fg-primary p-3 shadow-lg outline-none',
        'overflow-x-hidden',
        'overflow-y-auto',
        motionProps?.className,
      )}
      role="listbox"
    >
      {children}
    </motion.ul>
  </div>
));
