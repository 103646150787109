import { ItemRead } from '../../../api/dashboard'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '../../../Settings'
import { getItemName } from '../../../api/getItemName'

export const SkuImageName = ({ item }: { item: ItemRead }) => {
  return (
    <>
      <div
        className="size-[38px] shrink-0 rounded-md bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${item.image_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM})`,
        }}
      />
      <div className="mr-auto overflow-hidden">
        <div className="mb-0.5 truncate leading-5">{getItemName(item)}</div>
        <div className="truncate text-xs font-medium	leading-none text-text-quarterary-hover">{item.sku}</div>
      </div>
    </>
  )
}
