import i18next from 'i18next'
import { SegmentGroupRead, SegmentGroupType } from '../../../api/dashboard'
import { Button, Checkbox, FieldGroup, Input, Modal, ModalFooter, ModalProps, ModalTitle, Select } from '@/ui'
import { Controller, useForm } from 'react-hook-form'
import { dashboardClient } from '../../../api'
import { useParams } from 'react-router-dom'
import { isSuperAdmin } from '../../../util'
import { useContext } from 'react'
import { AuthContext, IAuthContext } from '../../../Context'
import { useQueryClient } from '@tanstack/react-query'

export interface EditSegmentGroupDialogProps extends ModalProps {
  item: SegmentGroupRead
  onAdded?: (item: SegmentGroupRead) => void
}

export default function EditSegmentGroupDialog(props: EditSegmentGroupDialogProps) {
  const { ...rest } = props
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const context = useContext(AuthContext) as IAuthContext
  const queryClient = useQueryClient()

  const { register, control, watch, handleSubmit } = useForm<SegmentGroupRead>({
    mode: 'onChange',
    values: { ...props.item },
  })

  const onSaveClick = async (data: SegmentGroupRead) => {
    let addedItem: SegmentGroupRead | null = null
    if (data.id) {
      await dashboardClient.v1.updateSegmentGroup(companyId, gameId, data.id, data)
    } else {
      let { data: created } = await dashboardClient.v1.createSegmentGroup(companyId, gameId, data)
      addedItem = created
    }
    queryClient.invalidateQueries({ queryKey: ['segment_groups', gameId] })
    if (addedItem) {
      props.onAdded?.(addedItem)
    }
    props.onClose?.()
  }

  const type = watch('type')

  return (
    <Modal {...rest}>
      <ModalTitle>{i18next.t(props.item.id ? 'segments.group.edit' : 'segments.group.add')}</ModalTitle>

      <div className="mt-10">
        <FieldGroup label={i18next.t('segment.name')}>
          <Input {...register('name', { required: true })} autoFocus />
        </FieldGroup>

        <FieldGroup caption={i18next.t(`segments.group.type.${type}.desc`)} label={i18next.t('segments.group.type')}>
          <Controller
            control={control}
            name="type"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={[
                  { value: SegmentGroupType.Static, children: i18next.t('segments.group.type.static') },
                  { value: SegmentGroupType.Dynamic, children: i18next.t('segments.group.type.dynamic') },
                ]}
              />
            )}
          />
        </FieldGroup>

        {type == SegmentGroupType.Dynamic && (
          <div className="flex flex-col gap-4">
            <Checkbox {...register('enabled')}>{i18next.t('campaign.editor.campaign-published')}</Checkbox>

            {isSuperAdmin(context.customer) && (
              <Checkbox {...register('is_template')}>{i18next.t('segments.editor.is_template')}</Checkbox>
            )}
          </div>
        )}
      </div>

      <ModalFooter>
        <Button className="w-full" size="lg" onClick={props.onClose}>
          {i18next.t('Cancel')}
        </Button>
        <Button className="w-full" size="lg" variant="primary" onClick={handleSubmit(onSaveClick)}>
          {i18next.t(props.item.id ? 'Save2' : 'Add')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
