import { useContext } from 'react'
import { AuthContext, IAuthContext } from '@/Context'
import { isSuperAdmin } from '@/util'

export const useCurrentUser = () => {
  const context = useContext(AuthContext) as IAuthContext

  const isSuperAdm = isSuperAdmin(context.customer)

  return {
    customer: context.customer,
    isSuperAdmin: isSuperAdm,
    isStaff: isSuperAdm || context.customer.email.endsWith('@aghanim.com'),
  }
}
