import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import {
  Button,
  ModalConfirm,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableRowEditButton,
  TableRowSkeleton,
  TableZeroState,
  useModal,
  usePagination,
} from '@/ui'
import { AuthContext, IAuthContext } from '@/Context'
import { itemPropertiesQuery } from '@/layouts/sku-settings/api/itemPropertiesQuery'
import { useItemPropertyDeleteMutate } from '@/layouts/sku-settings/api/useItemPropertyDeleteMutate'
import { ItemPropertyRead } from '@/api/dashboard'
import { useQuery } from '@tanstack/react-query'
import EditItemPropertyModal from '@/layouts/sku-settings/widgets/EditItemPropertyModal'
import { canEdit } from '@/security'
import { useTranslation } from 'react-i18next'
import { Edit03, Plus, Trash } from '@/icons'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '@/Settings'
import { ItemPropertyBadge } from '@/layouts/sku-settings/components/ItemPropertyBadge'

const ItemPropertiesCell = (props: { item: ItemPropertyRead }) => {
  const item = props.item

  return (
    <div className="flex gap-3">
      <div
        className="size-[38px] shrink-0 rounded-md bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${item.icon_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM})`,
        }}
      />
      <div className="mr-auto overflow-hidden">
        <div className="mb-0.5 truncate leading-5">{item.name}</div>
        <div className="text-xs font-medium	leading-none text-text-quarterary-hover">{item.description}</div>
      </div>
    </div>
  )
}

export default function ItemPropertiesList() {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = canEdit(context.customer)
  const { page, query, onPageChange, needShowPagination } = usePagination(10)
  const { data: items = [], isLoading } = useQuery({
    ...itemPropertiesQuery(companyId, gameId, query),
  })
  const { mutateAsync: deleteMutateAsync } = useItemPropertyDeleteMutate(companyId, gameId)
  const { t } = useTranslation()

  const openDeleteModal = useModal<{ item: ItemPropertyRead }>(props => (
    <ModalConfirm
      subMessage={t('confirm.text', { name: props.item.name })}
      confirmButtonText={t('remove')}
      color="danger"
      onConfirm={() => onDeleteGameItemClick(props.item)}
      {...props}
    />
  ))

  const openEditModal = useModal<{ item: ItemPropertyRead }>(props => <EditItemPropertyModal {...props} />)

  const onDeleteGameItemClick = async (item: ItemPropertyRead) => {
    await deleteMutateAsync({ id: item.id })
  }

  const renderDefaultHeader = () => {
    return (
      <TableRow variant="header">
        <TableCell width="200%">{t('item-props.name')}</TableCell>
        <TableCell width={100}>{t('item-props.type')}</TableCell>
        <TableCell width="20%" />
      </TableRow>
    )
  }

  const onCreateClick = () => {
    openEditModal({
      item: {
        id: '',
        name: '',
        description: '',
        image_url: '',
        config: {
          property_type: 'string',
        },
      } as ItemPropertyRead,
    })
  }

  return (
    <div className="flex h-full flex-col">
      <PageHeader
        extra={
          canUserEdit &&
          !!items.length && (
            <>
              <Button variant="primary" type="button" onClick={onCreateClick}>
                {t('item-props.create')}
              </Button>
            </>
          )
        }
      >
        {t('sidebar.item-properties')}
      </PageHeader>

      {isLoading ? (
        <TableRowSkeleton rowCount={query.limit} columnCount={3} />
      ) : items.length === 0 ? (
        <TableZeroState
          title={t(canUserEdit ? 'item-props.empty-table.title' : 'item-props.empty-table.title.read-only')}
          message={t(canUserEdit ? 'item-props.empty-table' : 'item-props.empty-table.read-only')}
          buttons={
            canUserEdit && (
              <Button variant="primary" onClick={onCreateClick}>
                <Plus size={14} />
                <span>{t('item-props.create')}</span>
              </Button>
            )
          }
        />
      ) : (
        <>
          <Table>
            {renderDefaultHeader()}
            {items.map(it => (
              <TableRow key={it.id}>
                <TableCell width="200%">
                  <ItemPropertiesCell item={it} />
                </TableCell>
                <TableCell width={100}>
                  <ItemPropertyBadge itemProperty={it} />
                </TableCell>

                <TableCell width="20%">
                  <div className="text-right">
                    {canUserEdit && (
                      <TableRowEditButton
                        onChange={v => {
                          switch (v) {
                            case 'edit':
                              openEditModal({ item: it })
                              break
                            case 'remove':
                              openDeleteModal({ item: it })
                              break
                          }
                        }}
                        options={[
                          {
                            icon: <Edit03 />,
                            children: t('grid.edit'),
                            value: 'edit',
                          },
                          {
                            icon: <Trash className="text-text-error-primary" />,
                            children: <span className="text-text-error-primary"> {t('grid.remove')} </span>,
                            value: 'remove',
                          },
                        ]}
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </Table>
          {needShowPagination(isLoading, items) && (
            <Pagination
              hasNext={items.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={items.length}
            />
          )}
        </>
      )}
    </div>
  )
}
