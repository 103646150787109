import { AghanimIcon, IconBase } from '@/icons'
import { forwardRef } from 'react'

export const AddIcon: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 11 11" ref={ref}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.8125 0.784343C5.23793 0.538723 5.76208 0.538723 6.1875 0.784343L9.24012 2.54677C9.66555 2.79239 9.92762 3.24632 9.92762 3.73756V7.26241C9.92762 7.75365 9.66555 8.20758 9.24012 8.4532L6.1875 10.2156C5.76208 10.4612 5.23793 10.4612 4.8125 10.2156L1.75989 8.4532C1.33446 8.20758 1.07239 7.75365 1.07239 7.26241V3.73756C1.07239 3.24632 1.33446 2.79239 1.75989 2.54677L4.8125 0.784343ZM5.72917 1.5782C5.58736 1.49633 5.41265 1.49633 5.27084 1.5782L2.21822 3.34063C2.07641 3.4225 1.98905 3.57381 1.98905 3.73756V7.26241C1.98905 7.42616 2.07641 7.57747 2.21822 7.65934L5.27084 9.42177C5.41265 9.50364 5.58736 9.50364 5.72917 9.42177L8.78179 7.65934C8.9236 7.57747 9.01096 7.42616 9.01096 7.26241V3.73756C9.01096 3.57381 8.9236 3.4225 8.78179 3.34063L5.72917 1.5782ZM5.5 3.66665C5.75314 3.66665 5.95834 3.87186 5.95834 4.12499V5.04165H6.875C7.12814 5.04165 7.33334 5.24686 7.33334 5.49999C7.33334 5.75312 7.12814 5.95832 6.875 5.95832H5.95834V6.87499C5.95834 7.12812 5.75314 7.33332 5.5 7.33332C5.24687 7.33332 5.04167 7.12812 5.04167 6.87499V5.95832H4.125C3.87187 5.95832 3.66667 5.75312 3.66667 5.49999C3.66667 5.24686 3.87187 5.04165 4.125 5.04165H5.04167V4.12499C5.04167 3.87186 5.24687 3.66665 5.5 3.66665Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
