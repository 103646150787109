import { Config } from 'tailwindcss';
import typography from '@tailwindcss/typography';
import { PluginAPI } from 'tailwindcss/types/config';

export default {
  mode: 'jit',
  theme: {
    extend: {
      container: {
        center: true,
        padding: '2rem',
        screens: {
          sm: '600px',
          md: '728px',
          lg: '984px',
          xl: '1240px',
          '2xl': '1400px',
        },
      },
      backgroundImage: {
        'linear-bg-gradient': 'linear-gradient(180deg, #F1F5F9 0%, #FFF 100%)',
        'linear-bg-topbar-gradient': 'linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.75) 100%)',
        'linear-company-gradient': 'linear-gradient(45deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.05) 100%)',
      },
      colors: {
        'bg-primary': '#F1F5F9',
        'bg-secondary': '#FFFFFF',
        'fg-primary': '#FFFFFF',
        'fg-primary-hover': '#F8FAFC',
        'fg-primary-alt': '#F1F5F9',
        'fg-secondary': '#F8FAFC',
        'fg-secondary-hover': '#F1F5F9',
        'fg-secondary-alt': '#E2E8F0',
        'fg-tertiary': '#E2E8F0',
        'fg-tertiary-hover': '#CBD5E1',
        'fg-tertiary-alt': '#CBD5E1',
        'fg-brand-primary': '#0EA5E9',
        'fg-brand-primary-hover': '#38BDF8',
        'fg-brand-primary-pressed': '#7DD3FC',
        'fg-brand-secondary': '#BAE6FD',
        'fg-brand-tertiary': '#F0F9FF',
        'fg-brand-tertiary-hover': '#E0F2FE',
        'fg-brand-tertiary-pressed': '#BAE6FD',
        'fg-gray-primary': '#E2E8F0',
        'fg-gray-secondary': '#F1F5F9',
        'fg-gray-tertiary': '#F8FAFC',
        'fg-pink-primary': '#F9A8D4',
        'fg-pink-secondary': '#FBCFE8',
        'fg-pink-tertiary': '#FDF2F8',
        'fg-lime-primary': '#BEF264',
        'fg-lime-secondary': '#D9F99D',
        'fg-lime-tertiary': '#F7FEE7',
        'fg-success-primary': '#86EFAC',
        'fg-success-secondary': '#BBF7D0',
        'fg-success-solid': '#22C55E',
        'fg-success-tertiary': '#F0FDF4',
        'fg-warning-primary': '#FDBA74',
        'fg-warning-primary-error': '#FB923C',
        'fg-warning-secondary': '#FED7AA',
        'fg-warning-tertiary': '#FFF7ED',
        'fg-warning-solid': '#F97316',
        'fg-error-primary': '#EF4444',
        'fg-error-primary-hover': '#F87171',
        'fg-error-secondary': '#FECACA',
        'fg-error-tertiary': '#FEF2F2',
        'fg-error-tertiary-alt': '#FEF2F2',
        'fg-error-tertiary-hover': '#FEE2E2',
        'fg-error-solid': '#EF4444',
        'fg-disabled': '#F1F5F9',
        'fg-violet-secondary': '#DDD6FE',
        'fg-overlay': 'rgba(100, 116, 139, 0.5)',
        'fg-black-10': 'rgba(0, 0, 0, 0.10)',
        'text-primary': '#000000',
        'text-secondary': '#475569',
        'text-secondary-hover': '#334155',
        'text-tertiary': '#64748B',
        'text-gray-primary': '#475569',
        'text-brand-primary': '#0EA5E9',
        'text-brand-secondary': '#0284C7',
        'text-brand-solid': '#0C4A6E',
        'text-quarterary': '#CBD5E1',
        'text-quarterary-hover': '#94A3B8',
        'text-tertiary-hover': '#64748B',
        'text-pink-solid': '#831843',
        'text-pink-tertiary': '#F472B6',
        'text-lime-solid': '#365314',
        'text-disabled': '#94A3B8',
        'text-success-primary': '#16A34A',
        'text-success-secondary': '#22C55E',
        'text-success-solid': '#14532D',
        'text-warning-primary': '#EA580C',
        'text-warning-solid': '#7C2D12',
        'text-error-primary': '#DC2626',
        'text-error-secondary': '#EF4444',
        'text-error-hover': '#B91C1C',
        'text-error-solid': '#7F1D1D',
        'text-inverse': '#fff',
        'text-violet-solid': '#4C1D95',
        'border-primary': '#E2E8F0',
        'border-primary-hover': '#CBD5E1',
        'border-secondary': '#F1F5F9',
        'border-tertiary': '#64748B',
        'border-brand': '#0EA5E9',
        'border-brand-filled': '#0EA5E9',
        'border-disabled': '#E2E8F0',
        'border-success': '#4ADE80',
        'border-success-ring': '#22C55E1A',
        'border-warning': '#FB923C',
        'border-warning-ring': '#F973161A',
        'border-error': '#F87171',
        'border-error-solid': '#EF4444',
        'border-img': 'rgba(0, 0, 0, 0.10)',
        /**
         * Bad colors token
         */
        'brand-300': '#7DD3FC',
      },
      ringColor: {
        brand: 'rgba(0, 151, 228, 0.1)',
        error: 'rgba(239, 68, 68, 0.1)',
      },
      height: {
        banner: '28px',
      },
      borderRadius: {
        '2xl1': '18px',
      },
      boxShadow: {
        radio: '0 0 0 2px rgba(0, 151, 228, 0.14)',
        'ring-gray-xs': '0px 0px 0px 4px rgba(152, 162, 179, 0.14), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        'sku-card-preview':
          '0px 39.271px 33.422px 0px rgba(0, 0, 0, 0.10), 0px 20.996px 17.869px 0px rgba(0, 0, 0, 0.08), 0px 11.77px 10.017px 0px rgba(0, 0, 0, 0.07), 0px 6.251px 5.32px 0px rgba(0, 0, 0, 0.06), 0px 2.601px 2.214px 0px rgba(0, 0, 0, 0.04)',
        'inner-img': 'inset 0 0 0 1px rgba(0, 0, 0, 0.10)',
        'inner-img-white': 'inset 0 0 0 1px rgba(255, 255, 255, 0.10)',
        xs: '0 1px 2px 0 rgba(16, 24, 40, 0.05)',
        md: '0 2px 4px -2px rgba(16, 24, 40, 0.06), 0 4px 8px -2px rgba(16, 24, 40, 0.10)',
        lg: '0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08)',
        xl: '0 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
        '2xl': '0 24px 48px -12px rgba(16, 24, 40, 0.18)',
      },
      fontSize: {
        'title-t1': [
          '2.25rem',
          {
            lineHeight: '2.75rem',
            letterSpacing: '0.18px',
            fontWeight: '600',
          },
        ],
        'title-t3': [
          '1.5rem',
          {
            lineHeight: '1.875rem',
            letterSpacing: '0.12px',
            fontWeight: '600',
          },
        ],
        'title-t4': [
          '1.375rem',
          {
            lineHeight: '1.625rem',
            letterSpacing: '0.10px',
            fontWeight: '600',
          },
        ],
        'title-t5': [
          '1.125rem',
          {
            lineHeight: '1.375rem',
            letterSpacing: '0.09px',
            fontWeight: '600',
          },
        ],
        'title-t6': [
          '1rem',
          {
            lineHeight: '1.25rem',
            letterSpacing: '0.08px',
            fontWeight: '600',
          },
        ],
        'hero-h1': [
          '2.25rem',
          {
            lineHeight: '2.25rem',
            letterSpacing: '1px',
            fontWeight: '500',
          },
        ],
        'hero-h2': [
          '1.75rem',
          {
            lineHeight: '1.75rem',
            letterSpacing: '1px',
            fontWeight: '500',
          },
        ],
        'hero-h3': [
          '1.5rem',
          {
            lineHeight: '1.5rem',
            letterSpacing: '1px',
            fontWeight: '500',
          },
        ],
        // todo: remove h* & t*
        h2: [
          '1.375rem',
          {
            lineHeight: '1',
            letterSpacing: '-0.5px',
            fontWeight: '500',
          },
        ],
        t4: [
          '1.375rem',
          {
            lineHeight: '1.625rem',
            letterSpacing: '0.10px',
            fontWeight: '600',
          },
        ],
        t5: [
          '1.125rem',
          {
            lineHeight: '1.375rem',
            letterSpacing: '0.10px',
            fontWeight: '600',
          },
        ],
        t6: [
          '1rem',
          {
            lineHeight: '1.25rem',
            letterSpacing: '0.08px',
            fontWeight: '600',
          },
        ],
        'caption-lg': [
          '1rem',
          {
            lineHeight: '1.25rem',
            fontWeight: '500',
          },
        ],
        'caption-md': [
          '0.875rem',
          {
            lineHeight: '1.125rem',
            fontWeight: '500',
          },
        ],
        'caption-sm': [
          '0.75rem',
          {
            lineHeight: '1rem',
            fontWeight: '500',
          },
        ],
        'caption-xs': [
          '0.6255rem',
          {
            lineHeight: '1rem',
            fontWeight: '500',
          },
        ],
        'paragraph-lg': [
          '1.125rem',
          {
            lineHeight: '1.625rem',
          },
        ],
        'paragraph-md': [
          '1rem',
          {
            lineHeight: '1.375rem',
          },
        ],
        'paragraph-sm': [
          '0.875rem',
          {
            lineHeight: '1.25rem',
          },
        ],
        'paragraph-xs': [
          '0.75rem',
          {
            lineHeight: '1rem',
          },
        ],
        xs: ['0.75rem', {}],
        sm: ['0.875rem', {}],
      },
      fontFamily: {
        nohemi: ['"Nohemi", system-ui, sans-serif'],
      },
    },
  },
  plugins: [
    typography,
    (plugins: PluginAPI) => {
      plugins.addUtilities({
        '.termly-display-preferences': {},
        '.divider': {
          height: '1px',
          // TODO: use color token @dspankov
          backgroundColor: '#F1F5F9',
        },
      });
    },
  ],
} as Partial<Config>;
