import { ReactNode, useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { ModalProps, o1MotionProps, o2MotionProps, rushMotionProps } from '@/ui'
import { DEFAULT_MOTION_DURATION, UseNavigateBackValue, cn } from '@/libs'

interface ModalRushChildrenProps extends ModalProps {
  onClose: () => void
}

interface ModalRushPageProps {
  children: ({ onClose }: ModalRushChildrenProps) => ReactNode
  back: UseNavigateBackValue
}

export const ModalRushPage = ({ children, back }: ModalRushPageProps) => {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const outlet = document.querySelector('[data-id="main-outlet-root"]')
    outlet?.setAttribute('data-modal-rush', 'true')
    return () => {
      const outlet = document.querySelector('[data-id="main-outlet-root"]')
      outlet?.setAttribute('data-modal-rush', 'false')
    }
  }, [])

  const onClose = useCallback(() => {
    const outlet = document.querySelector('[data-id="main-outlet-root"]')
    outlet?.setAttribute('data-modal-rush', 'false')
    setVisible(false)
    setTimeout(() => {
      back.onClick()
    }, DEFAULT_MOTION_DURATION * 1.05)
  }, [setVisible])

  return createPortal(
    <div className="fixed left-0 top-0 z-[200] flex size-full items-center justify-center">
      <AnimatePresence mode="wait">
        {visible && (
          <>
            <motion.div {...o1MotionProps} className="absolute left-0 top-0 z-[-2] size-full bg-[#c1c8d1]" />
            <motion.div {...o2MotionProps} className="absolute z-[-2] h-full bg-[#dfe3e7]" />
          </>
        )}
        {visible && (
          <motion.div
            {...rushMotionProps}
            className={cn('flex size-full items-center justify-center overflow-y-auto', 'pt-6')}
          >
            {children({ onClose })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>,
    document.body,
  )
}
