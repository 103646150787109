import { Badge } from '@/ui'
import { Check } from '@/icons'
import { useTranslation } from 'react-i18next'
import { SlashCircle02 } from '@dashboard/ui'

interface PlayerStatusProps {
  isBanned: boolean
}

export const PlayerStatus = ({ isBanned }: PlayerStatusProps) => {
  const { t } = useTranslation()
  return (
    <Badge variant={isBanned ? 'red-secondary' : 'gray-secondary'} className="whitespace-nowrap">
      <span>{isBanned ? t('players.banned') : t('no')}</span>
      {isBanned ? <SlashCircle02 size={16} /> : <Check size={16} />}
    </Badge>
  )
}
