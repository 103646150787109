import { useTranslation } from 'react-i18next'
import { FormSection } from '../../components'
import { ItemRead } from '@/api/dashboard'
import { SkuImageName } from '@/layouts/game-items/components/SkuImageName'
import { Button, ButtonIcon, FieldValidationMessage, ModalProps, TableZeroState, useModal } from '@/ui'
import { SelectSkuModal } from '@/layouts/components/SelectSkuModal/SelectSkuModal'
import { DotsGridSix, Plus, Trash } from '@/icons'
import i18next from 'i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { SkuFormData } from '@/layouts/game-items/widgets/SkuForm/SkuForm'
import { ErrorMessage } from '@hookform/error-message'
import { ReactSortable } from 'react-sortablejs'

export const SkuFormBundleItems = (props: {
  children?: React.ReactNode
  onChange: (value: ItemRead[]) => void
  value: ItemRead[]
}) => {
  const { t } = useTranslation()
  const { value, onChange } = props

  const openPicker = useModal<ModalProps>(({ ...rest }) => (
    <SelectSkuModal
      {...rest}
      title={t('game-item-edit-dialog.bundle.add-items')}
      picked={value}
      onSubmit={items => {
        onChange(items)
        rest?.onClose?.()
      }}
    />
  ))

  const renderItem = (item: ItemRead) => {
    return (
      <div key={item.id} className="flex h-[62px] w-full items-center gap-3 border-b border-border-secondary">
        <div className="min-w-[32px]">
          <DotsGridSix className="cursor-move" />
        </div>
        <div className="flex w-full items-center gap-3 truncate">
          <SkuImageName item={item} />
        </div>
        <ButtonIcon
          variant="tertiary-destructive"
          onClick={() => onChange(value.filter(it => it.id !== item.id))}
          className="ml-auto mr-3"
        >
          <Trash />
        </ButtonIcon>
      </div>
    )
  }

  return (
    <FormSection
      label={t('sku.item.contain-items')}
      action={
        value.length > 0 && (
          <Button
            variant="text-brand"
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              openPicker()
            }}
          >
            {t('game-item-edit-dialog.bundle.add-items')}
          </Button>
        )
      }
    >
      {value.length == 0 ? (
        <TableZeroState
          className="h-[195px]"
          title={t('game-item-edit-dialog.bundle.no-items')}
          buttons={
            <Button
              variant="primary"
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                openPicker()
              }}
            >
              <Plus size={14} />
              <span>{i18next.t('game-item-edit-dialog.bundle.add-items')}</span>
            </Button>
          }
        />
      ) : (
        <ReactSortable
          dragClass="cursor-move"
          filter=".non-drag"
          animation={200}
          delay={2}
          preventOnFilter={false}
          list={value}
          setList={v => {
            let changed = false
            for (let i = 0; i < v.length; i++) {
              if (v[i].id != value[i].id) {
                changed = true
                break
              }
            }
            if (changed) {
              onChange(v)
            }
          }}
        >
          {value.map(it => renderItem(it))}
        </ReactSortable>
      )}
      {props.children}
    </FormSection>
  )
}

export const SkuFormBundleForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<SkuFormData>()
  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      name="nested_items_read"
      rules={{
        required: t('validation.required'),
      }}
      render={({ field }) => (
        <SkuFormBundleItems value={field.value || []} onChange={items => field.onChange(items.length ? items : null)}>
          <ErrorMessage
            name="nested_items_read"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </SkuFormBundleItems>
      )}
    />
  )
}
