import { ButtonIcon } from '@/components/ui'
import { XClose } from '@/icons'

interface ModalCloseProps {
  onClick: () => void
}

export const ModalClose = ({ onClick }: ModalCloseProps) => (
  <div className="absolute right-[18px] top-[18px]">
    <ButtonIcon variant="secondary-gray" onClick={onClick}>
      <XClose size={18} />
    </ButtonIcon>
  </div>
)
