import { useParams } from 'react-router-dom'
import { useGameSettingsCreateMutate, useGameSettingsQuery } from '../../campaigns/api'
import { useGameSettingsFeaturesUpdateMutate } from '../../../api/useGameSettingsFeaturesUpdateMutate'
import { dashboardClient } from '../../../api'
import { useForm } from 'react-hook-form'
import { FeatureFlags } from '@/api/dashboard'
import { Button, Checkbox, Page, PageHeader, ToastSeverity, useToast } from '@/ui'
import i18next from 'i18next'
import DescBlock from '@/layouts/game-settings/components/DescBlock'
import Block from '@/layouts/game-settings/components/Block'
import { KeyValue } from '@/types'

export default function FeatureSettings() {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const showToast = useToast()
  const { data: settings } = useGameSettingsQuery(companyId, gameId)

  const { mutateAsync: createMutateAsync } = useGameSettingsCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useGameSettingsFeaturesUpdateMutate(companyId, gameId)

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<FeatureFlags>({
    values: {
      ...settings?.feature_flags,
    },
  })

  const onSaveClick = async (data: FeatureFlags) => {
    let id = settings?.id

    if (!id) {
      await createMutateAsync({
        data: {},
      })
      let { data: createdSettings } = await dashboardClient.v1.getGameSettings(companyId, gameId)
      id = createdSettings.id
    }

    for (let key in data) {
      let map = data as KeyValue
      if (map[key] === false) {
        map[key] = null
      }
    }

    await updateMutateAsync({
      settingsId: id,
      data: {
        feature_flags: data,
      },
    })

    showToast({ message: i18next.t('saved'), severity: ToastSeverity.success })
  }

  const onDiscardClick = () => {
    reset()
  }

  return (
    <Page>
      <PageHeader
        variant="bordered"
        sticky={true}
        extra={
          <>
            {isDirty && (
              <Button type="button" onClick={onDiscardClick}>
                {i18next.t('Discard')}
              </Button>
            )}
            <Button variant="primary" type="button" disabled={!isDirty} onClick={handleSubmit(onSaveClick)}>
              {i18next.t('Save2')}
            </Button>
          </>
        }
      >
        Admin: Features
      </PageHeader>

      <br />

      <DescBlock title="Features flags" description="Set Features flags for current game">
        <Block className="flex flex-col gap-3">
          <Checkbox {...register('account_settings_enabled')}>Account Settings</Checkbox>

          <Checkbox {...register('daily_rewards_enabled')}>Daily Rewards</Checkbox>

          <Checkbox {...register('loyalty_program_enabled')}>Loyalty Program</Checkbox>

          <Checkbox {...register('sandbox_enabled')}>Sandbox</Checkbox>

          <Checkbox {...register('bundle_enabled')}>Bundles</Checkbox>
        </Block>
      </DescBlock>
    </Page>
  )
}
