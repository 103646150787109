import { Clock, Select, SelectProps } from '@dashboard/ui'

export const SelectTime = (props: Omit<SelectProps, 'items'>) => {
  const hours = Array.from({ length: 24 }, (_, i) => i)

  return (
    <Select
      {...props}
      renderValueFn={({ item }) => {
        return (
          <>
            <Clock />
            {item.children}
          </>
        )
      }}
      items={hours.map(item => {
        let date = new Date(1970, 0, 1, item, 0)

        let timeString = date.toLocaleTimeString(navigator.language, {
          hour: '2-digit',
          minute: '2-digit',
        })

        return {
          value: item,
          children: timeString,
        }
      })}
    />
  )
}
