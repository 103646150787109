import i18next from 'i18next'
import { PeriodType, RotationType, StoreRead, StoreType } from '../../api/dashboard'
import {
  Button,
  FieldGroup,
  FieldValidationMessage,
  Input,
  Modal,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Select,
} from '@/ui'
import { useStoreCreateMutate, useStoreUpdateMutate } from './api'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { useState } from 'react'
import { ResponseError, getErrorText } from '../../api'
import { STORE_ITEMS_PATH } from '@/libs'

interface EditStoreDialogProps extends ModalProps {
  item: StoreRead
}

export default function EditStoreDialog(props: EditStoreDialogProps) {
  const { ...rest } = props
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { mutateAsync: createMutateAsync } = useStoreCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useStoreUpdateMutate(companyId, gameId)

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<StoreRead>({
    values: { ...props.item },
  })

  const saveClick = async (data: StoreRead) => {
    if (data.type == StoreType.Rotation) {
      if (!data.rotation_settings) {
        data.rotation_settings = {
          count: 3,
          type: RotationType.Random,
          period_type: PeriodType.Day,
          period_value: 1,
        }
      }
    } else {
      data.rotation_settings = null as unknown as undefined
    }

    try {
      if (props.item.id) {
        await updateMutateAsync({
          id: props.item.id,
          update: data,
        })
      } else {
        let created = await createMutateAsync({
          create: data,
        })

        if (data.type == StoreType.Rotation) {
          navigate(generatePath(STORE_ITEMS_PATH, { storeId: created.data.id, companyId, gameId }), {
            state: { openSettings: true },
          })
        }
      }
    } catch (e) {
      setErrorMessage(getErrorText(e as ResponseError))
      return
    }

    props.onClose?.()
  }

  return (
    <Modal {...rest}>
      <ModalTitle>{i18next.t(props.item.id ? 'store.edit-store' : 'store.create-store')}</ModalTitle>

      <div className="mt-10">
        <FieldGroup label={i18next.t('store.name')}>
          <Input autoFocus {...register('name', { required: i18next.t('validation.required') })} />
          <ErrorMessage
            name="name"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('store.description')}>
          <Input {...register('description')} />
          <ErrorMessage
            name="description"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('store.type')}>
          <Controller
            control={control}
            name="type"
            rules={{ required: i18next.t('validation.required') }}
            render={({ field }) => (
              <Select
                {...field}
                options={Object.values(StoreType).map(it => ({
                  value: it,
                  children: i18next.t(`store.type.${it}`),
                  desc: i18next.t(`store.type.${it}.desc`),
                }))}
              />
            )}
          />
          <ErrorMessage
            name="type"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <div className="mb-2 inline-block">
          {errorMessage && <FieldValidationMessage> {errorMessage} </FieldValidationMessage>}
        </div>
      </div>

      <ModalFooter>
        <Button size="lg" onClick={props.onClose}>
          {i18next.t('Cancel')}
        </Button>
        <Button size="lg" variant="primary" onClick={handleSubmit(saveClick)}>
          {i18next.t(props.item.id ? 'Save2' : 'Add')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
