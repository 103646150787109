import { useState } from 'react'
import i18next from 'i18next'
import { useParams } from 'react-router-dom'
import { isEmail } from '../../../util'
import { PersistentPlayerAttributes, User } from '../../../api/dashboard'
import { ResponseError, getErrorText } from '../../../api'
import { S3Bucket } from '../../../types'
import {
  Button,
  FieldGroup,
  FieldValidationMessage,
  ImageUploader,
  Input,
  Modal,
  ModalProps,
  SelectCountry,
} from '@/ui'
import { Controller, useForm } from 'react-hook-form'
import { SelectSegment } from '../../components/SelectSegment/SelectSegment'
import { usePlayerSegmentQuery } from '../api/usePlayerSegmentQuery'
import { usePlayerSegmentUpdateMutate } from '../api/usePlayerSegmentUpdateMutate'
import { usePlayerDetailQuery, usePlayerUpdateMutate } from '../api'
import { ErrorMessage } from '@hookform/error-message'

interface EditPlayerModalProps extends ModalProps {
  userId: string
}

export default function EditPlayerModal(props: EditPlayerModalProps) {
  const { ...rest } = props
  const [helperText, setHelperText] = useState('')
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
    userId: string
  }
  const { data: user } = usePlayerDetailQuery(companyId, gameId, props.userId)
  const [imageLoading, setImageLoading] = useState(false)
  const { mutateAsync } = usePlayerUpdateMutate(companyId, gameId)
  const { mutateAsync: mutateSegmentsAsync } = usePlayerSegmentUpdateMutate(companyId, gameId)
  const { data = [] } = usePlayerSegmentQuery(companyId, gameId, props.userId)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<User>({
    mode: 'onChange',
    values: { ...(user as User) },
  })

  const [userSegments, setUserSegments] = useState<string[]>(data.map(it => it.segment_id) as string[])

  const onSaveClick = async (user: User) => {
    setHelperText('')

    try {
      let update = {
        email: user.email,
        name: user.name,
        avatar_url: user.avatar_url,
        attributes: undefined as PersistentPlayerAttributes | undefined,
        country: user.country,
      }

      if (user.attributes) {
        update.attributes = {
          ...user.attributes,
          level: !user.attributes.level && user.attributes.level !== 0 ? 0 : user.attributes.level,
        }
      }

      await mutateAsync({
        userId: user.id,
        update: update,
      })

      await mutateSegmentsAsync({
        userId: user.id,
        update: {
          ids: userSegments,
        },
      })
      props.onClose?.()
    } catch (e) {
      setHelperText(getErrorText(e as ResponseError))
    }
  }

  return (
    <Modal {...rest}>
      <div className="text-[24px] font-semibold text-text-primary">{i18next.t('users.user.edit')}</div>

      <form className="mt-10" onSubmit={handleSubmit(onSaveClick)}>
        <FieldGroup label={i18next.t('users.avatar_url')}>
          <Controller
            control={control}
            name="avatar_url"
            render={({ field }) => (
              <ImageUploader
                onLoadingChanged={v => setImageLoading(v)}
                bucket={S3Bucket.hub}
                style={{ width: '100%', maxWidth: '100%' }}
                accept={{
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'image/png': [],
                  'image/webp': [],
                }}
                {...field}
              />
            )}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('users.email')}>
          <Input
            maxLength={254}
            {...register('email', {
              validate: v => {
                if (!v) {
                  return true
                }
                if (!isEmail(v)) {
                  return i18next.t('validation.invalid_email')
                }
                return true
              },
              required: false,
            })}
          />
          <ErrorMessage
            name="email"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <SelectSegment
          label={i18next.t('users.segment')}
          multiselect
          onChange={v => setUserSegments(v as unknown as string[])}
          value={userSegments}
        />

        <FieldGroup label={i18next.t('players.country')}>
          <Controller control={control} name="country" render={({ field }) => <SelectCountry {...field} />} />
        </FieldGroup>

        <FieldGroup label={i18next.t('users.level')}>
          <Input {...register('attributes.level')} type="number" />
        </FieldGroup>

        {helperText && <FieldValidationMessage>{helperText}</FieldValidationMessage>}

        <div className="mt-10 flex gap-4">
          <Button className="w-full" size="lg" onClick={props.onClose} type="reset">
            {i18next.t('Cancel')}
          </Button>
          <Button
            className="w-full"
            size="lg"
            variant="primary"
            type="submit"
            isLoading={isSubmitting}
            disabled={imageLoading}
          >
            {i18next.t('Save2')}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
