import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useWebsitesQuery } from '../../api/useWebsitesQuery'
import { PageLayoutType, PageRead } from '../../api/dashboard'
import { Drawer } from '@/ui'
import { PagesForm } from './components'

interface EditNewsDialogProps {
  item?: PageRead
  parents?: PageRead[]
  onClose: (saved: boolean) => void
}

const EditPageDialog = ({ item, parents = [], onClose }: EditNewsDialogProps) => {
  const { companyId, gameId, pageId } = useParams() as { companyId: string; gameId: string; pageId: string }
  const [open, setOpen] = useState(false)

  const { data: websites = [] } = useWebsitesQuery(companyId, gameId)

  useEffect(() => {
    setOpen(true)
  }, [setOpen])

  const onClickClose = () => {
    setOpen(false)
    setTimeout(() => {
      onClose(false)
    }, 150)
  }
  const createType = pageId === 'collection' ? PageLayoutType.Collection : PageLayoutType.Article

  return (
    <Drawer open={open}>
      <PagesForm
        websiteUrl={websites[0] ? websites[0].url : ''}
        item={item}
        parents={parents}
        onClose={onClickClose}
        createType={createType}
      />
    </Drawer>
  )
}

export default EditPageDialog
