import i18next from 'i18next'
import { Header } from '../../components/ui/Header'
import {
  Badge,
  Button,
  ButtonIcon,
  Drawer,
  Floating,
  MenuButton,
  ModalConfirm,
  SpinnerAghanim,
  Table,
  TableCell,
  TableCellBulkCheckbox,
  TableCellBulkToolbar,
  TableRow,
  TableZeroState,
  Tooltip,
  TooltipAlertCircle,
  useModal,
} from '@/ui'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useStoreQuery } from './api/useStoreQuery'
import { STORE_PATH } from '../../libs/routerPaths'
import { useStoreItemsQuery } from './api/useStoreItemsQuery'
import { useContext, useEffect, useState } from 'react'
import {
  ConditionNode,
  Currency,
  ResourceState,
  RotationType,
  StoreCardType,
  StoreItemRead,
  StoreRead,
  StoreType,
} from '../../api/dashboard'
import StoreItemSettings from './components/StoreItemSettings'
import { dashboardClient, getErrorText } from '@/api'
import { ReactSortable } from 'react-sortablejs'
import { useForm } from 'react-hook-form'
import { useStoreUpdateMutate } from './api'
import { itemCategoriesQuery } from '../item-category/api'
import { DEFAULT_LOAD_LIMIT, useGameItemsQuery } from '../../api/useGameItems'
import { DotsGridSix, DotsHorizontal, Filter, FlipBackward, Plus, SwitchHorizontal } from '@/icons'
import { canEdit } from '../../security'
import { ToastSeverity } from '../../components/ui/Toast/types'
import { useToast } from '../../components/ui/Toast/useToast'
import { useBulkSelectGeneric } from '../../libs/hooks/useBulkSelectGeneric'
import { getDescConditionNode } from '../campaigns/blocks/descriptions/getDescConditionNode'
import { SkuImageName } from '../game-items/components/SkuImageName'
import { DescContext } from '../campaigns/blocks/descriptions/types'
import { useSegmentsQuery } from '../segment/api/useSegmentsQuery'
import { cn } from '../../libs/cn'
import { PriceView } from '../game-items/components/PriceView'
import { AuthContext, IAuthContext } from '@/Context'
import { useBanner } from '@/libs/hooks/useBanner'
import { GeneralStoreSettings } from '@/layouts/store/GeneralStoreSettings'
import { BulkEditModal, BulkPropertyName } from '@/layouts/store/widgets/BulkEditModal'
import { BulkEditMenu } from '@/layouts/store/widgets/BulkEditMenu'
import useKeyPress from 'react-use/lib/useKeyPress'
import { useQuery } from '@tanstack/react-query'

export default function StoreEditor() {
  const navigate = useNavigate()
  const { companyId, gameId, storeId } = useParams() as {
    companyId: string
    gameId: string
    storeId: string
  }

  const location = useLocation()
  const { state } = location
  const showToast = useToast()
  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = canEdit(context.customer)
  const { getStickyTop, getStickyHeight } = useBanner()
  const [isStoreOpening, setIsStoreOpening] = useState(true)
  const { data: segments = [] } = useSegmentsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
  })
  const { data: store = null, isLoading } = useStoreQuery(companyId, gameId, storeId)
  const {
    data: items = [],
    isLoading: isLoadingStore,
    isRefetching: isStoreRefetching,
  } = useStoreItemsQuery(companyId, gameId, storeId)
  const { data: allItems = [] } = useGameItemsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    state: ResourceState.Active,
  })

  const [isDirtyOrder, setIsDirtyOrder] = useState(false)
  const [isDirtySomeItem, setIsDirtySomeItem] = useState(false)
  const [visibleStoreSettings, setVisibleStoreSettings] = useState(false)

  const [editStoreItem, setEditStoreItem] = useState<StoreItemRead | null>(null)
  const [newStoreItem, setNewStoreItem] = useState<StoreItemRead | null>(null)

  const { data: categories = [] } = useQuery({
    ...itemCategoriesQuery(companyId, gameId, { limit: 100 }),
    refetchOnMount: false,
  })
  const { mutateAsync: updateMutateAsync } = useStoreUpdateMutate(companyId, gameId)

  const [deletedIds, setDeletedIds] = useState<string[]>([])

  const { selected, onSelect, onReset, onSelectItems } = useBulkSelectGeneric<StoreItemRead>()

  const [isShiftPress] = useKeyPress('Shift')

  const {
    watch,
    reset,
    getValues,
    setValue,
    formState: { isDirty: isDirtyForm, isSubmitting },
    handleSubmit,
  } = useForm<StoreRead>({})

  const [name, rotation_settings] = watch(['name', 'rotation_settings'])

  const [storeItems, setStoreItems] = useState<StoreItemRead[]>([])

  useEffect(() => {
    if (!isLoadingStore && !isStoreRefetching && isStoreOpening) {
      setStoreItems([...items])
      setIsStoreOpening(false)
    }
  }, [isLoadingStore, isStoreRefetching])

  useEffect(() => {
    if (!isLoading && store) {
      reset(store)

      if (state?.openSettings) {
        state.openSettings = null
        setVisibleStoreSettings(true)
      }
    }
  }, [isLoading])

  const openConfirmExit = useModal(props => (
    <ModalConfirm
      subMessage={i18next.t('campaign.confirm.lost-changes')}
      confirmButtonText={i18next.t('campaign.confirm.yes')}
      cancelButtonText={i18next.t('campaign.confirm.no-stay')}
      color="danger"
      onConfirm={() => navigate(generatePath(STORE_PATH, { companyId, gameId }))}
      {...props}
    />
  ))

  const showSaveEmptyStoreConfirm = useModal<{ data: StoreRead }>(props => (
    <ModalConfirm
      subMessage={i18next.t('store.save-empty-store.confirm')}
      confirmButtonText={i18next.t('campaign.confirm.yes')}
      cancelButtonText={i18next.t('campaign.confirm.no')}
      color="danger"
      onConfirm={() => confirmSaveClick(props.data)}
      {...props}
    />
  ))

  const openBulkEditModal = useModal<{ propertyName: BulkPropertyName; items: StoreItemRead[] }>(props => (
    <BulkEditModal
      {...props}
      onEdit={editedItems => {
        setStoreItems([...storeItems.map(it => editedItems.find(e => e.item_id == it.item_id) || it)])
        onReset(editedItems)
        setIsDirtySomeItem(true)
      }}
    />
  ))

  const onMultiSelect = (item: StoreItemRead) => {
    if (selected.length == 0) {
      onSelect(item)
      return
    }

    const isSelected = selected.includes(item)
    const lastSelected = selected[selected.length - 1]
    const indexOfLast = storeItems.indexOf(lastSelected)
    const indexOfCurrent = storeItems.indexOf(item)

    const itemsForSelect = storeItems.slice(
      Math.min(indexOfLast, indexOfCurrent),
      Math.max(indexOfLast, indexOfCurrent) + 1,
    )

    onSelectItems(itemsForSelect, !isSelected)
  }

  const closeClick = () => {
    if (isDirtyOrder || isDirtyForm || isDirtySomeItem) {
      openConfirmExit()
    } else {
      navigate(generatePath(STORE_PATH, { companyId, gameId }))
    }
  }

  const saveClick = async (data: StoreRead) => {
    if (!storeItems.length && store?.type == StoreType.Default) {
      showSaveEmptyStoreConfirm({ data })
      return
    }

    await confirmSaveClick(data)
  }

  const getBasePropsForUpdate = (it: StoreItemRead) => {
    return {
      rotation_weight: it.rotation_weight,
      is_free_item: it.is_free_item,
      start_at: it.start_at,
      end_at: it.end_at,
      reward_points_percent: it.reward_points_percent,
      custom_badge: it.custom_badge ? it.custom_badge : (null as unknown as string),
      requirements_expression: it.requirements_expression,
      id: it.id,
      item_id: it.item_id,
      discount_percent: it.discount_percent ? it.discount_percent : (null as unknown as number),
      bonus_percent: it.bonus_percent ? it.bonus_percent : (null as unknown as number),
      name: '',
      description: '',
      max_purchases: it.max_purchases || (null as unknown as number),
      max_purchases_period_type: it.max_purchases_period_type,
      max_purchases_period_value: it.max_purchases_period_value,
      card_type: it.card_type,
      show_first_on_category_list: it.show_first_on_category_list,
      show_disabled_by_max_purchases: it.show_disabled_by_max_purchases,
    }
  }

  const confirmSaveClick = async (data: StoreRead) => {
    try {
      await updateMutateAsync({
        id: data.id,
        update: data,
      })
      reset(data)
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
      return
    }

    if (deletedIds.length) {
      await dashboardClient.v1.bulkDeleteStoreItems(
        companyId,
        gameId,
        storeId,
        deletedIds.map(it => ({
          id: it,
        })),
      )

      setDeletedIds([])
    }

    let update = storeItems
      .filter(it => it.id)
      .map(it => ({
        ...getBasePropsForUpdate(it),
        position: isDirtyOrder ? storeItems.indexOf(it) : undefined,
      }))

    if (update.length) {
      await dashboardClient.v1.bulkUpdateStoreItems(companyId, gameId, storeId, update)
    }

    const promises = storeItems
      .filter(it => !it.id)
      .map(it => {
        return dashboardClient.v1.createStoreItem(companyId, gameId, storeId, {
          ...getBasePropsForUpdate(it),
          position: storeItems.indexOf(it),
        })
      })

    if (promises.length) {
      const responses = await Promise.all(promises)
      const createdItems = responses.map(res => res.data)
      setStoreItems([...storeItems.map(it => (it.id ? it : createdItems.find(c => c.item_id == it.item_id)!))])
    }

    onReset([])

    setIsDirtyOrder(false)
    setIsDirtySomeItem(false)
    showToast({ message: i18next.t('saved'), severity: ToastSeverity.success })
  }

  const sortByPrice = (sortType: string) => {
    let freeItems = storeItems.filter(it => it.is_free_item).sort((a, b) => a.item.name.localeCompare(b.item.name))

    let rpItems = storeItems
      .filter(it => it.item.currency == Currency.RP)
      .sort((a, b) => {
        let diff = (a.item.reward_points_price || 0) - (b.item.reward_points_price || 0)
        return sortType == 'desc' ? -diff : diff
      })

    let otherItems = storeItems
      .filter(it => !it.is_free_item && it.item.currency != Currency.RP)
      .sort((a, b) => {
        let diff = a.item.price - b.item.price

        return sortType == 'desc' ? -diff : diff
      })

    setStoreItems(
      sortType == 'asc' ? [...freeItems, ...rpItems, ...otherItems] : [...otherItems, ...rpItems, ...freeItems],
    )
    setIsDirtyOrder(true)
  }

  const sortByName = (value: string) => {
    if (value == 'asc') {
      setStoreItems([...storeItems].sort((a, b) => a.item.name.localeCompare(b.item.name)))
    } else {
      setStoreItems([...storeItems].sort((a, b) => b.item.name.localeCompare(a.item.name)))
    }
    setIsDirtyOrder(true)
  }

  const sortByCategory = (value: string) => {
    if (value == 'asc') {
      setStoreItems([...storeItems].sort((a, b) => getCategoryName(a).localeCompare(getCategoryName(b))))
    } else {
      setStoreItems([...storeItems].sort((a, b) => getCategoryName(b).localeCompare(getCategoryName(a))))
    }
    setIsDirtyOrder(true)
  }

  const addStoreItemClick = () => {
    setNewStoreItem({} as StoreItemRead)
  }

  const getHeaderButtons = () => {
    return (
      <>
        <Button
          data-testid="sku/store/add-all"
          onClick={() => {
            let idx = storeItems.length
            let newItems = allItems
              .filter(it => !storeItems.find(s => s.item_id == it.id))
              .map(it => ({
                id: '',
                name: '',
                description: '',
                position: idx++,
                item_id: it.id,
                item: it,
                store_id: storeId,
                created_at: 0,
              }))

            setStoreItems([...storeItems, ...newItems])
            setEditStoreItem(null)
            setIsDirtyOrder(true)
          }}
        >
          {i18next.t('store.add-all')}
        </Button>

        <Button
          data-testid="sku/store/clear-all"
          onClick={() => {
            setDeletedIds(storeItems.filter(it => it.id).map(it => it.id))
            setStoreItems([])
            setIsDirtyOrder(true)
            setEditStoreItem(null)
          }}
        >
          {i18next.t('store.clear')}
        </Button>

        <Button onClick={() => setVisibleStoreSettings(true)}>{i18next.t('store.settings')}</Button>

        <Button
          data-testid="save-store"
          variant="primary"
          isLoading={isSubmitting}
          onClick={handleSubmit(saveClick)}
          disabled={!isDirtyOrder && !isDirtyForm && !isDirtySomeItem}
        >
          {i18next.t('Save2')}
        </Button>
      </>
    )
  }

  const renderCommandButtons = () => {
    return (
      <div
        className={cn(
          'z-10 ml-auto flex items-center justify-end gap-3 bg-bg-primary py-1 pr-0.5 pt-5',
          'sticky top-[56px]',
        )}
      >
        {allItems.length > storeItems.length && canUserEdit && (
          <Button onClick={addStoreItemClick} className="mr-auto">
            {i18next.t('store.add-item')}
          </Button>
        )}

        <MenuButton
          variant="tertiary-gray-dashed"
          onChange={v => sortByName(v as string)}
          options={[
            { value: 'asc', children: 'A->Z' },
            { value: 'desc', children: 'Z->A' },
          ]}
        >
          <Filter />
          {i18next.t('store.sort-by-name')}
        </MenuButton>

        {categories.length > 0 && (
          <MenuButton
            variant="tertiary-gray-dashed"
            onChange={v => sortByCategory(v as string)}
            options={[
              { value: 'asc', children: 'A->Z' },
              { value: 'desc', children: 'Z->A' },
            ]}
          >
            <Filter />
            {i18next.t('store.sort-by-category')}
          </MenuButton>
        )}
        <MenuButton
          variant="tertiary-gray-dashed"
          onChange={v => sortByPrice(v as string)}
          options={[
            { value: 'asc', children: i18next.t('store.sort.asc') },
            { value: 'desc', children: i18next.t('store.sort.desc') },
          ]}
        >
          <Filter />
          {i18next.t('store.sort-by-price')}
        </MenuButton>
      </div>
    )
  }

  const noCat = i18next.t('store.no-category')

  const getCategoryName = (s: StoreItemRead) => {
    let c = s.item?.categories?.length ? categories.find(c => s.item.categories?.includes(c.id)) : null
    return c?.name || noCat
  }

  const renderBulkSelectionHeader = () => (
    <div className={cn(' flex items-center ', 'sticky top-[110px] z-10 h-[45px] bg-fg-primary')}>
      <div className="w-[44px]" />
      <TableCellBulkCheckbox
        selected={selected.length > 0 && selected.length == storeItems.length}
        disabled={storeItems.length === 0}
        onChange={() => onReset(selected.length == storeItems.length ? [] : storeItems)}
      >
        {selected.length == 0 && i18next.t('store.item.select_all')}
      </TableCellBulkCheckbox>

      {selected.length > 0 && (
        <div className="flex w-full">
          <TableCellBulkToolbar
            selected={selected}
            onReset={() => onReset([])}
            extra={
              <Tooltip message={i18next.t('store.item.invert_selection')}>
                <ButtonIcon variant="secondary-gray" size="xs" onClick={invertSelection}>
                  <SwitchHorizontal />
                </ButtonIcon>
              </Tooltip>
            }
          >
            {' '}
          </TableCellBulkToolbar>

          <div className="ml-auto mr-3">
            <Floating
              menu={
                <BulkEditMenu
                  selected={selected}
                  onDelete={onDeleteClick}
                  isWeightRotation={isWeightRotation()}
                  onSelect={onBulkEditClick}
                />
              }
              placement="bottom-start"
            >
              <ButtonIcon variant="secondary-gray" size="sm">
                <DotsHorizontal />
              </ButtonIcon>
            </Floating>
          </div>
        </div>
      )}
    </div>
  )

  const invertSelection = () => {
    let newSelected = storeItems.filter(it => !selected.includes(it))
    onReset(newSelected)
  }

  const onBulkEditClick = (propertyName: BulkPropertyName) => {
    openBulkEditModal({
      propertyName: propertyName,
      items: Array.from(selected),
    })
  }

  const onEditConfirm = (data: StoreItemRead) => {
    setStoreItems([...storeItems.map(it => (it.item_id == data.item_id ? data : it))])

    setEditStoreItem(null)

    onReset([])
    setIsDirtySomeItem(true)
  }

  const onDeleteClick = () => {
    for (let selectedStoreItem of selected) {
      if (selectedStoreItem.id) {
        setDeletedIds(prev => [...prev, selectedStoreItem.id])
        setStoreItems(prev => prev.filter(it => it.id != selectedStoreItem.id))
      } else {
        setStoreItems(prev => prev.filter(it => it.item_id != selectedStoreItem.item_id))
      }
    }

    onReset([])
    setIsDirtySomeItem(true)
  }

  const renderDateTimeValue = (value: number) => {
    return (
      <div className="flex gap-1.5">
        <div className="text-text-secondary">
          {i18next.t('intl.DateTime', {
            val: new Date(value * 1000),
            formatParams: { val: { dateStyle: 'short' } },
          })}
        </div>
        <div className="text-text-tertiary">
          {i18next.t('intl.DateTime', {
            val: new Date(value * 1000),
            formatParams: { val: { timeStyle: 'short' } },
          })}
        </div>
      </div>
    )
  }

  const renderTimeLimit = (storeItem: StoreItemRead) => {
    if (storeItem.start_at && storeItem.end_at) {
      return (
        <div className="flex flex-col">
          {renderDateTimeValue(storeItem.start_at)}
          {renderDateTimeValue(storeItem.end_at)}
        </div>
      )
    }
    return ''
  }

  const isWeightRotation = () => {
    return rotation_settings?.type == RotationType.RandomByWeight
  }

  const renderCondition = (storeItem: StoreItemRead) => {
    if (!storeItem.requirements_expression) {
      return ''
    }

    let [, subTitle] = getDescConditionNode(
      {
        expr: storeItem.requirements_expression,
      } as ConditionNode,
      {
        items: allItems,
        segments: segments,
      } as unknown as DescContext,
    )
    return subTitle
  }

  const renderStoreTableView = () => {
    const hasSegmentationRules = storeItems.some(it => it.requirements_expression?.tokens?.length)

    return (
      <div className="flex w-full flex-1 items-start justify-center px-5">
        <div className="w-[1440px]">
          {canUserEdit && renderCommandButtons()}
          <div className="mt-5">
            {selected.length > 0 && renderBulkSelectionHeader()}

            <Table className="overflow-hidden rounded-md bg-fg-primary">
              {selected.length == 0 && (
                <TableRow variant="header">
                  <TableCell width={42} className="flex justify-end" />
                  {canUserEdit && (
                    <TableCellBulkCheckbox
                      selected={selected.length > 0 && selected.length == storeItems.length}
                      disabled={storeItems.length === 0}
                      onChange={() => onReset(selected.length == storeItems.length ? [] : storeItems)}
                    />
                  )}
                  <TableCell width="50%">{i18next.t('sku.item.product-name')}</TableCell>
                  <TableCell width="15%">{i18next.t('sku.item.price')}</TableCell>
                  <TableCell width="20%">{i18next.t('sku.item.category')}</TableCell>
                  <TableCell width="20%">{i18next.t('store.item.benefits')}</TableCell>
                  <TableCell width="30%">{i18next.t('campaign.settings.limit-limitations')}</TableCell>
                  {hasSegmentationRules && (
                    <TableCell width="50%">{i18next.t('store.item.requirements_expression')}</TableCell>
                  )}
                  {isWeightRotation() && (
                    <TableCell width="12%" className="flex items-center gap-1">
                      {i18next.t('store.item.weight')}
                      <TooltipAlertCircle message={i18next.t('store.item.weight.tooltip')} />
                    </TableCell>
                  )}
                </TableRow>
              )}

              <ReactSortable
                animation={200}
                delay={2}
                disabled={!canUserEdit}
                filter=".non-drag"
                list={storeItems || []}
                setList={v => {
                  let changed = false
                  for (let i = 0; i < v.length; i++) {
                    if (v[i].id != storeItems[i].id) {
                      changed = true
                      break
                    }
                  }
                  setStoreItems(v)
                  if (changed) {
                    setIsDirtyOrder(true)
                  }
                }}
              >
                {storeItems.map(it => (
                  <TableRow
                    key={it.item_id}
                    variant="clickable"
                    onClick={canUserEdit ? () => setEditStoreItem(it) : undefined}
                  >
                    <TableCell width={42} className="flex justify-end">
                      {canUserEdit && <DotsGridSix className="cursor-move" />}
                    </TableCell>
                    {canUserEdit && (
                      <TableCellBulkCheckbox
                        selected={selected.includes(it)}
                        onChange={_ => {
                          if (isShiftPress) {
                            onMultiSelect(it)
                          } else {
                            onSelect(it)
                          }
                        }}
                      />
                    )}
                    <TableCell width="50%" className="flex items-center gap-3">
                      <SkuImageName item={it.item} />
                      {it.card_type == StoreCardType.Featured && (
                        <Badge variant="gray-secondary">{i18next.t('store.item.featured_type.featured')}</Badge>
                      )}
                    </TableCell>
                    <TableCell width="15%" className="truncate">
                      {it.is_free_item ? i18next.t('store.item.free_item') : <PriceView item={it.item} />}
                    </TableCell>
                    <TableCell width="20%">{getCategoryName(it)}</TableCell>
                    <TableCell width="20%">
                      {it.discount_percent ? (
                        <div>{i18next.t('store.item.discount1') + ' ' + it.discount_percent + '%'}</div>
                      ) : (
                        ''
                      )}
                      {it.bonus_percent ? (
                        <div>{i18next.t('store.item.bonus1') + ' ' + it.bonus_percent + '%'}</div>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell width="30%">{renderTimeLimit(it)}</TableCell>
                    {hasSegmentationRules && <TableCell width="50%">{renderCondition(it)}</TableCell>}
                    {isWeightRotation() && <TableCell width="12%">{it.rotation_weight}</TableCell>}
                  </TableRow>
                ))}
              </ReactSortable>
            </Table>
          </div>
        </div>
      </div>
    )
  }

  const renderStoreItemSettings = () => {
    return (
      <Drawer open={!!editStoreItem || !!newStoreItem}>
        {newStoreItem && (
          <StoreItemSettings
            store={getValues()}
            hideIds={storeItems.map(it => it.item_id)}
            onClose={(item: StoreItemRead | null) => {
              setNewStoreItem(null)
              if (item) {
                setStoreItems([...storeItems, item])
                setIsDirtySomeItem(true)
              }
            }}
            item={newStoreItem}
          />
        )}

        {editStoreItem && (
          <StoreItemSettings
            store={getValues()}
            onClose={(item: StoreItemRead | null) => {
              if (item) {
                onEditConfirm(item)
              } else {
                setEditStoreItem(null)
              }
            }}
            item={editStoreItem}
          />
        )}
      </Drawer>
    )
  }

  const renderContent = () => {
    if (isStoreOpening) {
      return (
        <div className="flex w-full grow items-center justify-center">
          <SpinnerAghanim />
        </div>
      )
    }

    if (storeItems.length === 0) {
      return (
        <TableZeroState
          className="w-full grow"
          title={i18next.t('store.store')}
          message={i18next.t('store.empty-message')}
          buttons={
            canUserEdit && (
              <Button variant="primary" onClick={addStoreItemClick}>
                <Plus size={14} />
                <span>{i18next.t('store.add-item')}</span>
              </Button>
            )
          }
        />
      )
    }

    return renderStoreTableView()
  }

  return (
    <div
      className={cn(
        'absolute left-0 z-50 flex size-full flex-col overflow-auto bg-bg-primary',
        getStickyHeight(),
        getStickyTop(),
      )}
    >
      {renderStoreItemSettings()}
      {visibleStoreSettings && (
        <GeneralStoreSettings
          onApplied={data => {
            let name = data.name
            data.name = ''
            reset(data)
            setValue('name', name, { shouldDirty: true })
            setVisibleStoreSettings(false)
          }}
          store={getValues()}
          onClose={() => setVisibleStoreSettings(false)}
        />
      )}
      <Header
        className={cn('sticky top-0 z-10')}
        title={<div className="max-w-[300px] truncate">{name}</div>}
        titleAction={
          <ButtonIcon
            onClick={closeClick}
            type="button"
            data-testid="back-store-button"
            className="mr-5"
            size="sm"
            variant="secondary-gray"
          >
            <FlipBackward />
          </ButtonIcon>
        }
      >
        <div className="flex items-center gap-2">{canUserEdit ? getHeaderButtons() : null}</div>
      </Header>

      {renderContent()}
    </div>
  )
}
