import { Check, Copy03 } from '@/icons'
import { HTMLAttributes, useEffect, useState } from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '@/libs'
import { Tooltip } from '@/components/ui'
import { useTranslation } from 'react-i18next'

const variants = cva('flex items-center ', {
  variants: {
    variant: {
      default: 'h-[33px] w-[443px]  rounded-md bg-fg-secondary-hover px-3 text-text-secondary',
      secondary: 'w-full truncate text-caption-sm font-semibold text-text-secondary',
    },
    icon: {
      default: '',
      secondary: 'text-text-quarterary',
    },
  },
})

export interface LabelCopyButtonProps extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof variants> {
  value: string
  tooltip?: string
}

export const LabelCopyButton = ({ value, tooltip, variant = 'default', ...rest }: LabelCopyButtonProps) => {
  const [success, setSuccess] = useState(false)
  const { t } = useTranslation()

  const onCopyClick = () => {
    setSuccess(true)
    navigator.clipboard.writeText(value)
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false)
      }, 2500)
    }
  }, [success, setSuccess])

  return (
    <div {...rest} className={cn(variants({ variant }), rest.className)}>
      {tooltip ? (
        <Tooltip message={tooltip}>
          <div className="grow truncate text-caption-sm font-semibold text-text-secondary">{value}</div>
        </Tooltip>
      ) : (
        value
      )}
      {success ? (
        <Tooltip message={t('copied')}>
          <Check className="ml-auto text-text-brand-primary" size={16} strokeWidth={3} />
        </Tooltip>
      ) : (
        <Copy03 size={16} onClick={onCopyClick} className={cn('ml-auto cursor-pointer', variants({ icon: variant }))} />
      )}
    </div>
  )
}
