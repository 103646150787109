import { ReactNode, forwardRef } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { InfoCircleIcon } from '../../../icons/Icons'
import { TooltipAlertCircle } from '../index'

interface FieldGroupProps {
  children: ReactNode
  label?: ReactNode
  caption?: ReactNode
  size?: 'sm' | 'md'
  required?: boolean
  error?: string
  tooltip?: string
  className?: string
}

const StyledFieldGroup = styled.div<{
  $size: FieldGroupProps['size']
}>`
  ${p =>
    p.$size === 'sm' &&
    css`
      margin-bottom: 12px;
    `}
  ${p =>
    p.$size === 'md' &&
    css`
      margin-bottom: 20px;
    `}
    &:last-child {
    margin-bottom: 0;
  }
`

export const Label = styled('div')<{
  $size: FieldGroupProps['size']
}>`
  display: flex;
  align-items: center;
  line-height: normal;
  gap: 6px;

  ${p =>
    p.$size === 'sm' &&
    css`
      color: #000000;
      margin-bottom: 6px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.07px;
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      color: #64748b;
      margin-bottom: 6px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      letter-spacing: 0.06px;
    `}
`

const Caption = styled.div`
  color: #475569;
  font-size: 14px;
  font-weight: 400;
  margin-top: 6px;

  &:empty {
    display: none;
  }
`

const Error = styled.div`
  color: ${p => p.theme.palette.error.textPrimary};
  background-color: ${p => p.theme.palette.background.fgErrorTertiary};
  margin-top: 3px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
`

export const FieldGroup = forwardRef<HTMLDivElement, FieldGroupProps>(function FieldGroup(
  { children, size = 'md', label, caption, required, error, tooltip, ...rest },
  ref,
) {
  return (
    <StyledFieldGroup ref={ref} $size={size} {...rest}>
      {label && (
        <Label $size={size}>
          {label}
          {required && <span className="-ml-1 text-text-pink-tertiary">*</span>}{' '}
          {tooltip && <TooltipAlertCircle message={tooltip} placement="top" />}
        </Label>
      )}
      {children}
      {caption && <Caption>{caption}</Caption>}
      {error && (
        <Error>
          <InfoCircleIcon />
          {error}
        </Error>
      )}
    </StyledFieldGroup>
  )
})
