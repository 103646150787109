import { InputHTMLAttributes, createElement, forwardRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import { cva } from 'class-variance-authority';
import { AghanimIconOrCustom } from '../../icons';
import { cn } from '../../libs';
import { ControlHookFormError } from '../../types';
import { FormErrorMessage } from '../FormErrorMessage';

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>, ControlHookFormError {
  /**
   * Placeholder of the input.
   */
  placeholder?: string;
  /**
   * Color of the input.
   */
  color?: 'primary' | 'danger';
  /**
   * Size of the input.
   */
  size?: 'md';
  /**
   * Extra element to be placed on the left side of the input.
   */
  extraLeft?: AghanimIconOrCustom;
  /**
   * Extra element to be placed on the right side of the input.
   */
  extraRight?: AghanimIconOrCustom;
}

const variants = cva(
  [
    'group/input',
    'flex h-9 w-full items-center rounded-md border shadow-xs transition-colors',
    'has-[:focus]:border-border-brand-filled',
    'has-[:focus]:ring-2',
    'has-[:focus]:ring-brand',
    'has-[:disabled]:bg-fg-disabled',
    'has-[:disabled]:border-border-disabled',
    'has-[:disabled]:ring-0',
    'has-[:disabled]:pointer-events-none',
    'has-[:disabled]:shadow-none',
  ],
  {
    variants: {
      color: {
        primary: ['bg-fg-primary', 'border-border-primary', 'hover:border-border-primary-hover'],
        danger: ['bg-fg-primary', 'border-border-error', 'ring-2 ring-error', 'hover:border-border-error-solid'],
      },
      size: {
        md: 'gap-2 px-3',
      },
      defaultVariants: {
        color: 'primary',
        size: 'md',
      },
    },
  },
);

const extraClasses = cn(
  'text-text-quarterary transition-colors pointer-events-none flex-shrink-0',
  'group-hover/input:text-text-quarterary-hover',
  'peer-[:not(:placeholder-shown)]/input-input:text-text-secondary',
  'peer-focus/input-input:text-text-secondary',
  'peer-disabled/input-input:text-text-disabled',
);

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ color = 'primary', size = 'md', extraLeft, extraRight, error, ...rest }, ref) => (
    <>
      <label className={cn(variants({ color, size }))}>
        <input
          {...rest}
          ref={ref}
          className={cn(
            'peer/input-input',
            'size-full bg-transparent m-0 p-0 border-none transition-colors placeholder:transition-colors',
            'text-paragraph-sm',
            'leading-4',
            'text-text-secondary',
            'group-hover/input:placeholder:text-text-quarterary-hover',
            'focus:placeholder:text-text-quarterary-hover',
            'focus:text-text-primary',
            'placeholder:text-text-quarterary',
            'disabled:text-text-disabled',
            'disabled:placeholder:text-text-disabled',
            rest.className,
          )}
        />
        {extraLeft && (
          <div className={cn('-order-1 -ml-1', extraClasses)}>{createElement(extraLeft, { size: 18 })}</div>
        )}
        {extraRight && <div className={cn('-mr-1', extraClasses)}>{createElement(extraRight, { size: 18 })}</div>}
      </label>
      <AnimatePresence>{error && <FormErrorMessage>{error}</FormErrorMessage>}</AnimatePresence>
    </>
  ),
);
