import { UserRead } from '@/api/dashboard'
import { TableCell, TableRow } from '@/ui'
import { PlayerAvatar } from '../PlayerAvatar'
import { PlayerStatus } from '../PlayerStatus'
import i18next from 'i18next'
import { DateTimeValue } from '@/components/ui/DateTimeValue'
import UserCountry from '@/layouts/player/components/UserCountry'
import { formatMoney } from '@/util'

interface PlayerTableRowProps {
  user: UserRead
  to: string
}

export const PlayerTableRow = ({ user, to }: PlayerTableRowProps) => {
  return (
    <TableRow to={to}>
      <TableCell width="200%">
        <div className="flex items-center gap-3">
          <PlayerAvatar src={user.avatar_url} />
          <div className="mr-auto overflow-hidden">
            <div className="mb-0.5 truncate leading-5">{user.email || user.name || i18next.t('players.incognito')}</div>
            <div className="text-xs font-medium	leading-none text-text-quarterary-hover">{user.player_id}</div>
          </div>
        </div>
      </TableCell>
      <TableCell width="70%">
        <UserCountry country={user.country} />
      </TableCell>
      <TableCell width="40%">{formatMoney(user.ltv || 0)}</TableCell>
      <TableCell width="50%">
        <DateTimeValue value={user.created_at} />
      </TableCell>
      <TableCell width="50%">
        <DateTimeValue value={user.last_active_at || user.last_verified_at || user.last_login_at || user.created_at} />
      </TableCell>
      <TableCell width="30%">
        <PlayerStatus isBanned={user.banned || false} />
      </TableCell>
    </TableRow>
  )
}
