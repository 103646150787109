import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import dayjs from 'dayjs'

import { ResponseError, getErrorText } from '@/api'
import { LeaderboardRead, LeaderboardVisibility } from '@/api/dashboard'
import { Button, FieldGroup, Input, Select, Textarea } from '@/ui'
import DialogHeader from '@/components/ui/Dialog/DialogHeader'
import Modal from '@/components/ui/Dialog/Modal'
import { useLeaderboardUpdateMutate } from './api/useLeaderboardUpdateMutate'
import { useLeaderboardCreateMutate } from './api/useLeaderboardCreateMutate'
import { InputCopyButton } from '@/components/ui/InputCopyButton'
import StyledDatePicker from '@/components/StyledDatePicker'
import { FormErrorMessage } from '@dashboard/ui'

const LeaderBoardDialog = (props: { onClose: () => void; item: LeaderboardRead }) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const [helperText, setHelperText] = useState('')
  const { mutateAsync: updateMutateAsync } = useLeaderboardUpdateMutate(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useLeaderboardCreateMutate(companyId, gameId)

  const {
    watch,
    control,
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<LeaderboardRead>({
    defaultValues: {
      ...props.item,
      visibility: props.item.visibility || LeaderboardVisibility.General,
    },
  })
  const [starts, ends] = watch(['start_at', 'end_at'])

  const validate = (data: LeaderboardRead) => {
    if (data.start_at && data.end_at && data.start_at >= data.end_at) {
      return t('leaderboard.dialog.validation.starts_at_less_than_ends_at')
    }
    return null
  }

  const onSubmit = handleSubmit(async (data: LeaderboardRead) => {
    setHelperText('')

    const error = validate(data)
    if (error) {
      setHelperText(error)
      return
    }

    try {
      if (props.item?.id) {
        await updateMutateAsync({
          id: data.id,
          update: data,
        })
        props.onClose()
      } else {
        await createMutateAsync({
          create: data,
        })
      }
      props.onClose()
    } catch (e) {
      setHelperText(getErrorText(e as ResponseError))
    }
  })

  return (
    <Modal onClose={props.onClose}>
      <DialogHeader title={t(props.item.id ? 'edit-leaderboard-dialog.title.edit' : 'leaderboard.add-button')} />

      <div className="mt-10">
        {props.item.id && (
          <FieldGroup label={t('leaderboard.id')}>
            <InputCopyButton
              value={props.item.id}
              onChange={e => {
                e.target.value = props.item.id
              }}
            />
          </FieldGroup>
        )}

        <FieldGroup label={t('leaderboard.name')}>
          <Input autoFocus {...register('name', { required: t('validation.required') })} />
          <ErrorMessage
            name="name"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>

        <FieldGroup label={t('leaderboard.description')}>
          <Textarea {...register('description', { required: t('validation.required') })} />
          <ErrorMessage
            name="description"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>

        <div className="flex gap-1">
          <FieldGroup label={t('leaderboard.dialog.starts_at')}>
            <Controller
              control={control}
              name="start_at"
              render={({ field }) => (
                <StyledDatePicker
                  {...field}
                  fullWidth
                  clearable
                  maxDate={ends ? dayjs.unix(ends) : undefined}
                  value={field.value ? dayjs.unix(field.value).format('YYYY-MM-DD') : null}
                  onChange={date => field.onChange(date ? dayjs(date).unix() : null)}
                />
              )}
            />
            <ErrorMessage
              name="start_at"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup label={t('leaderboard.dialog.ends_at')}>
            <Controller
              control={control}
              name="end_at"
              render={({ field }) => (
                <StyledDatePicker
                  {...field}
                  fullWidth
                  clearable
                  minDate={starts ? dayjs.unix(starts).add(1, 'day') : undefined}
                  value={field.value ? dayjs.unix(field.value).format('YYYY-MM-DD') : null}
                  onChange={date => field.onChange(date ? dayjs(date).unix() : null)}
                />
              )}
            />
            <ErrorMessage
              name="end_at"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </div>

        <FieldGroup
          label={t('leaderboard.dialog.visibility.title')}
          tooltip={t('leaderboard.dialog.visibility.title.desc')}
        >
          <Controller
            control={control}
            name="visibility"
            render={({ field }) => (
              <Select
                {...field}
                disabled={isSubmitting}
                options={[LeaderboardVisibility.General, LeaderboardVisibility.Personal].map(v => ({
                  value: v,
                  children: t(`leaderboard.dialog.visibility.${v}.title`),
                  desc: t(`leaderboard.dialog.visibility.${v}.desc`),
                }))}
              />
            )}
          />
        </FieldGroup>

        {helperText && <FormErrorMessage>{helperText}</FormErrorMessage>}
      </div>

      <div className="mt-10 flex justify-end gap-4">
        <Button size="lg" onClick={() => props.onClose()}>
          {t('Cancel')}
        </Button>
        <Button size="lg" variant="primary" onClick={onSubmit}>
          {t(props.item.id ? 'Save2' : 'Add')}
        </Button>
      </div>
    </Modal>
  )
}

export default LeaderBoardDialog
