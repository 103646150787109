import { ReactElement, cloneElement, forwardRef, useMemo } from 'react';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { AnimatePresence } from 'framer-motion';
import { UseFloatingMenuParams, useFloatingMenu } from '../../hooks';
import { getFloatingMotionProps } from '../../libs';
import { PopperMenuProps } from './types';

interface PopperProps {
  children: ReactElement;
  /**
   * Popper component, should be a function that returns a ReactElement.
   * The element must be wrapped in a forwardRef.
   * @param props
   */
  popper: (props: PopperMenuProps) => ReactElement;
  params?: UseFloatingMenuParams;
  focusManager?: {
    initialFocus?: number;
  };
}

export const Popper = forwardRef<HTMLElement, PopperProps>(({ children, popper, params, focusManager }, ref) => {
  const {
    state: { isOpen, setIsOpen },
    floating: { context, placement },
    getReferenceProps,
    getFloatingProps,
  } = useFloatingMenu(ref, params);

  const popperInner = useMemo(
    () =>
      popper({
        onClose: () => {
          setIsOpen(false);
        },
      }),
    [popper, setIsOpen],
  );

  return (
    <>
      {cloneElement(children, getReferenceProps())}
      <AnimatePresence>
        {isOpen && (
          <FloatingFocusManager {...focusManager} context={context}>
            <FloatingPortal>
              {cloneElement(popperInner, {
                ...getFloatingProps(),
                className: 'absolute z-[3000] w-max',
                motionProps: {
                  ...getFloatingMotionProps(placement),
                  className: 'max-w-none',
                },
              })}
            </FloatingPortal>
          </FloatingFocusManager>
        )}
      </AnimatePresence>
    </>
  );
});
