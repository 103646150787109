import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const Event: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 16 16" ref={ref}>
      <g clip-path="url(#clip0_3489_646)">
        <path
          d="M0 5.71077C0 3.71181 0 2.71233 0.389023 1.94883C0.731217 1.27724 1.27724 0.731217 1.94883 0.389023C2.71233 0 3.71181 0 5.71077 0H10.2892C12.2882 0 13.2877 0 14.0512 0.389023C14.7228 0.731217 15.2688 1.27724 15.611 1.94883C16 2.71233 16 3.71181 16 5.71077V10.2892C16 12.2882 16 13.2877 15.611 14.0512C15.2688 14.7228 14.7228 15.2688 14.0512 15.611C13.2877 16 12.2882 16 10.2892 16H5.71077C3.71181 16 2.71233 16 1.94883 15.611C1.27724 15.2688 0.731217 14.7228 0.389023 14.0512C0 13.2877 0 12.2882 0 10.2892V5.71077Z"
          fill="url(#paint0_linear_3489_646)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.62995 1.66675C5.95723 1.66675 6.22255 1.93538 6.22255 2.26675V2.86675H9.7781V2.26675C9.7781 1.93538 10.0434 1.66675 10.3707 1.66675C10.698 1.66675 10.9633 1.93538 10.9633 2.26675V2.86675C12.1721 2.86675 13.1695 3.78285 13.3153 4.96675H13.3337V11.2667C13.3337 12.5922 12.2724 13.6667 10.9633 13.6667H5.03736C3.72824 13.6667 2.66699 12.5922 2.66699 11.2667V4.96675H2.68533C2.83114 3.78285 3.82859 2.86675 5.03736 2.86675V2.26675C5.03736 1.93538 5.30268 1.66675 5.62995 1.66675ZM5.03736 4.06675C4.3828 4.06675 3.85218 4.60401 3.85218 5.26675V5.86675C3.85218 6.19812 4.11749 6.46675 4.44477 6.46675H11.5559C11.8832 6.46675 12.1485 6.19812 12.1485 5.86675V5.26675C12.1485 4.60401 11.6178 4.06675 10.9633 4.06675V4.66675C10.9633 4.99812 10.698 5.26675 10.3707 5.26675C10.0434 5.26675 9.7781 4.99812 9.7781 4.66675V4.06675H6.22255V4.66675C6.22255 4.99812 5.95723 5.26675 5.62995 5.26675C5.30268 5.26675 5.03736 4.99812 5.03736 4.66675V4.06675ZM8.32767 7.54903C8.19628 7.26154 7.80437 7.26154 7.67298 7.54903L7.14494 8.70448C7.09206 8.82018 6.98672 8.90009 6.865 8.91685L5.64938 9.08416C5.34692 9.12579 5.22581 9.51498 5.44707 9.73429L6.33634 10.6157C6.42538 10.704 6.46562 10.8333 6.44327 10.9593L6.22002 12.2182C6.16447 12.5314 6.48154 12.7719 6.74967 12.62L7.82731 12.0093C7.93522 11.9481 8.06543 11.9481 8.17334 12.0093L9.25098 12.62C9.51911 12.7719 9.83618 12.5314 9.78063 12.2182L9.55739 10.9593C9.53503 10.8333 9.57527 10.704 9.66431 10.6157L10.5536 9.73429C10.7748 9.51498 10.6537 9.12579 10.3513 9.08416L9.13565 8.91685C9.01393 8.90009 8.90859 8.82018 8.85571 8.70448L8.32767 7.54903Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_3489_646" x1="8" y1="0" x2="8" y2="16" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A78BFA" />
          <stop offset="1" stop-color="#8B5CF6" />
        </linearGradient>
        <clipPath id="clip0_3489_646">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </IconBase>
  )
})
