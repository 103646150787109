import { useContext } from 'react'
import { useTheme } from '@mui/material/styles'
import i18next from 'i18next'
import { SURFACE_SURFACE_BG_STROKE, getHubEditorHeaderHeight } from '../../Settings'
import { BackIcon, TrashIcon } from '../../icons/Icons'
import getCampaignName from './pages/CampaignTable/getCampaignName'
import {
  Badge,
  Button,
  ButtonIcon,
  ModalConfirm,
  ToastSeverity,
  Tooltip,
  TooltipAlertCircle,
  useModal,
  useToast,
} from '@/ui'
import { CampaignContext, ICampaignContext } from './Context'
import { BarChart, Code02, Settings } from '@/icons'
import { isSuperAdmin } from '@/util'
import { AuthContext, IAuthContext } from '@/Context'
import { dashboardClient, getErrorText } from '@/api'
import { CampaignEventType } from '@/api/dashboard'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { campaignTemplatesQuery } from '@/layouts/campaigns/api/campaignTemplatesQuery'
import { DataFlow } from '@/components/icons/DataFlow'

const CampaignEditorHeader = (props: {
  disabledSave: boolean
  saveClick: () => void
  onOpenSettings: () => void
  onOpenHistory: () => void
  onOpenAnalytics: () => void
  onDeleteClick: () => void | null
  onBackClick: () => void
  isLive: boolean
  publishChangeClick: () => void
}) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const theme = useTheme()
  const context = useContext(CampaignContext) as ICampaignContext
  const authContext = useContext(AuthContext) as IAuthContext
  const showToast = useToast()
  const { data: templates = [], refetch } = useQuery(campaignTemplatesQuery(companyId, gameId))

  const openConfirmSaveAsTemplateModal = useModal(props => (
    <ModalConfirm
      color="primary"
      subMessage={i18next.t('campaign.confirm.save-as-template')}
      confirmButtonText={i18next.t('yes')}
      cancelButtonText={i18next.t('no')}
      onConfirm={saveAsTemplate}
      showClose={true}
      {...props}
    />
  ))

  const openConfirmPublish = useModal(p => (
    <ModalConfirm
      color="primary"
      message={i18next.t(props.isLive ? 'campaign.confirm.unpublish' : 'campaign.confirm.publish')}
      subMessage={i18next.t(props.isLive ? 'campaign.confirm.unpublish.message' : 'campaign.confirm.publish.message')}
      confirmButtonText={i18next.t(props.isLive ? 'unpublish' : 'publish')}
      onConfirm={props.publishChangeClick}
      showClose={true}
      {...p}
    />
  ))

  const saveAsTemplate = async () => {
    const { campaign, graph, viewPort } = context
    graph.view_port = viewPort
    try {
      await dashboardClient.v1.createOrUpdateCampaignTemplate(companyId, gameId, {
        graph: graph,
        type: campaign?.type as CampaignEventType,
        source_campaign_id: campaign.id,
      })
      showToast({ message: i18next.t('saved'), severity: ToastSeverity.success })
      refetch()
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
    }
  }

  return (
    <div
      className="flex w-full items-center justify-between border border-b-border-primary"
      style={{
        minWidth: '800px',
        minHeight: getHubEditorHeaderHeight(theme),
      }}
    >
      <div className="flex items-center gap-4 p-4">
        <Button onClick={props.onBackClick} color="secondary" style={{ gap: '8px' }}>
          <BackIcon />
          {i18next.t('Back')}
        </Button>

        <div
          style={{
            backgroundColor: SURFACE_SURFACE_BG_STROKE,
            height: '16px',
            width: '1px',
          }}
        />

        {context.campaign && (
          <span className="text-sm font-medium text-text-primary">{getCampaignName(context.campaign.type)}</span>
        )}

        {isSuperAdmin(authContext.customer) && templates.find(it => it.source_campaign_id == context.campaign?.id) && (
          <Badge variant="gray-primary">
            Template <TooltipAlertCircle message={i18next.t('campaign.template-source')} />
          </Badge>
        )}

        {props.isLive && <Badge variant="green-primary">Live</Badge>}
      </div>

      <div className="flex h-full items-center justify-end gap-2 pr-3">
        {isSuperAdmin(authContext.customer) && context.campaign?.id && (
          <Tooltip message={i18next.t('campaign.header.save-template')}>
            <ButtonIcon onClick={() => openConfirmSaveAsTemplateModal()} variant="secondary-gray">
              <DataFlow size={18} />
            </ButtonIcon>
          </Tooltip>
        )}
        {!context.readOnly && (
          <>
            {props.onDeleteClick && (
              <Tooltip message={i18next.t('campaign.header.delete-edge')}>
                <ButtonIcon onClick={props.onDeleteClick} variant="secondary-gray">
                  <TrashIcon />
                </ButtonIcon>
              </Tooltip>
            )}

            <Tooltip message={i18next.t('campaign.header.analytics')}>
              <ButtonIcon onClick={props.onOpenAnalytics} variant="secondary-gray">
                <BarChart />
              </ButtonIcon>
            </Tooltip>

            <Tooltip message={i18next.t('campaign.header.logs')}>
              <ButtonIcon onClick={props.onOpenHistory} variant="secondary-gray">
                <Code02 />
              </ButtonIcon>
            </Tooltip>
          </>
        )}

        <Tooltip message={i18next.t('campaign.header.settings')}>
          <ButtonIcon onClick={props.onOpenSettings} variant="secondary-gray">
            <Settings />
          </ButtonIcon>
        </Tooltip>
        {!context.readOnly && (
          <>
            <Button variant={'primary'} onClick={() => openConfirmPublish()}>
              {i18next.t(props.isLive ? 'unpublish' : 'publish')}
            </Button>
            <Button variant={'primary'} onClick={props.saveClick} disabled={props.disabledSave}>
              {i18next.t('Save2')}
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default CampaignEditorHeader
