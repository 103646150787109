import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Currency } from '@/api/dashboard'
import { FieldGroup, Input, InputExtraSection, Tab, Tabs, useModal } from '@/ui'
import { InfoCircle, RewardPoint } from '@/icons'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { FormSection, PriceEditFormData, PriceEditModal, PriceEditModalData, PriceInput } from '../../components'
import { SkuFormData } from './SkuForm'
import { PRICE_MAX } from '../../data'

export const SkuFormPriceSection = () => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const {
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<SkuFormData>()

  const item = watch()
  const { data: settings } = useGameSettingsQuery(companyId, gameId)
  const openPriceEditModal = useModal<PriceEditModalData>(props => <PriceEditModal {...props} />)

  const onChangePrice = (data: PriceEditFormData) => {
    setValue('price', data.price, { shouldDirty: true })
    setValue('price_point', data.price_point, { shouldDirty: true })
  }

  const onClickEditPrice = () => {
    openPriceEditModal({ item, onChange: onChangePrice })
  }

  const [currency, reward_points_price] = watch(['currency', 'reward_points_price'])
  const isFiat = currency != Currency.RP

  const renderRewardPointEditor = () => {
    return (
      <div className="flex items-center gap-3">
        <div className="w-[460px]">
          <Input
            type="number"
            min={0}
            {...register('reward_points_price', {
              required: t('validation.required'),
              min: { value: 0, message: t('game-item-page.price-point-min', { min: 0 }) },
              max: { value: 1_000_000, message: t('validation.max', { field: 'Quantity', value: 1_000_000 }) },
              setValueAs: value => {
                if (value == null) {
                  return null
                }
                return parseFloat(value)
              },
            })}
            placeholder={t('game-item-page.price-title.points.place-holder')}
            errors={errors}
            extraLeft={
              <InputExtraSection side="left">
                <div className="flex gap-1.5">
                  <RewardPoint /> {t('game-item-page.price-title.points')}
                </div>
              </InputExtraSection>
            }
          />
        </div>
        {reward_points_price != null && reward_points_price > 0 && (
          <div className="flex items-center gap-2 text-text-tertiary">
            <InfoCircle />
            <span className="text-sm leading-none">
              {t('game-item-page.price-title.points.desc', { value: reward_points_price / 100 })}
            </span>
          </div>
        )}
      </div>
    )
  }

  return (
    <FormSection label={t('game-item-page.price-title')}>
      <div className="-mt-3 mb-6 text-sm text-text-secondary">{t('game-item-page.price-description')}</div>

      {settings?.enable_reward_points && (
        <Tabs>
          <Tab
            isActive={isFiat}
            onClick={() => {
              setValue('currency', Currency.USD, { shouldDirty: true })
            }}
          >
            {t('game-item-page.price-title.price.fiat')}
          </Tab>
          <Tab
            isActive={!isFiat}
            onClick={() => {
              if (!reward_points_price) {
                setValue('reward_points_price', 0, { shouldDirty: true })
              }
              setValue('currency', Currency.RP, { shouldDirty: true })
            }}
          >
            {t('game-item-page.price-title.price.reward-point')}
          </Tab>
        </Tabs>
      )}

      {item.id ? (
        isFiat ? (
          <FieldGroup>
            <PriceInput item={item} onClick={onClickEditPrice} />
          </FieldGroup>
        ) : (
          renderRewardPointEditor()
        )
      ) : isFiat ? (
        <FieldGroup label={t('game-item-page.price-default-label')}>
          <Input
            {...register('price', {
              required: t('validation.required'),
              min: { value: 0, message: t('game-item-page.price-min-max', { min: 0, max: PRICE_MAX }) },
              max: { value: PRICE_MAX * 100, message: t('game-item-page.price-min-max', { min: 0, max: PRICE_MAX }) },
              setValueAs: value => {
                return (parseFloat(value) || 0) * 100
              },
            })}
            step={0.01}
            type="number"
            extraLeft={<InputExtraSection side="left">USD</InputExtraSection>}
            errors={errors}
          />
        </FieldGroup>
      ) : (
        renderRewardPointEditor()
      )}
    </FormSection>
  )
}
