import i18next from 'i18next'
import {
  Button,
  FieldGroup,
  FieldValidationMessage,
  ImageUploader,
  Input,
  Modal,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Select,
  Textarea,
} from '@/ui'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { useState } from 'react'
import { useItemPropertyCreateMutate } from '@/layouts/sku-settings/api/useItemPropertyCreateMutate'
import { ItemPropertyRead } from '@/api/dashboard'
import { useItemPropertyUpdateMutate } from '@/layouts/sku-settings/api/useItemPropertyUpdateMutate'
import { getErrorText } from '@/api'
import { MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from '@/Settings'
import { NumberIcon } from '@/layouts/sku-settings/icons/NumberIcon'
import { TextIcon } from '@/layouts/sku-settings/icons/TextIcon'
import { Range } from '@/layouts/sku-settings/icons/Range'
import { useTranslation } from 'react-i18next'

interface EditItemCategoryDialogProps extends ModalProps {
  item: ItemPropertyRead
  created?: (item: ItemPropertyRead) => void
}

export default function EditItemPropertyModal(props: EditItemCategoryDialogProps) {
  const { ...rest } = props
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { mutateAsync: createMutateAsync } = useItemPropertyCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useItemPropertyUpdateMutate(companyId, gameId)

  const {
    formState: { errors },
    register,
    control,
    handleSubmit,
    watch,
    setError,
  } = useForm<ItemPropertyRead>({
    values: {
      ...props.item,
    },
  })

  const [type, min, max] = watch(['config.property_type', 'config.min', 'config.max'])

  const saveClick = async (data: ItemPropertyRead) => {
    if (type == 'range' && min! >= max) {
      setError('config.min', { message: t('validation.min-max') })
      return
    }

    try {
      if (props.item.id) {
        await updateMutateAsync({
          id: props.item.id,
          update: data,
        })
      } else {
        let { data: newCat } = await createMutateAsync({
          create: data,
        })

        props.created?.(newCat)
      }
    } catch (e) {
      setErrorMessage(getErrorText(e))
      return
    }

    props.onClose?.()
  }

  return (
    <Modal {...rest}>
      <ModalTitle>{i18next.t(props.item.id ? 'item-props.edit' : 'item-props.create')}</ModalTitle>

      <form className="mt-10" onSubmit={handleSubmit(saveClick)}>
        <FieldGroup label={i18next.t('item-props.name')}>
          <Input
            autoFocus
            {...register('name', {
              required: i18next.t('validation.required'),
              setValueAs: value => {
                return value.trim()
              },
            })}
            maxLength={MAX_NAME_LENGTH}
          />
          <ErrorMessage
            name="name"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('item-props.description')}>
          <Textarea
            {...register('description', {
              setValueAs: value => {
                return value.trim()
              },
            })}
            maxLength={MAX_DESCRIPTION_LENGTH}
          />
          <ErrorMessage
            name="description"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('item-props.type')}>
          <Controller
            control={control}
            name="config.property_type"
            rules={{ required: i18next.t('validation.required') }}
            render={({ field }) => (
              <Select
                {...field}
                options={[
                  {
                    icon: <NumberIcon />,
                    value: 'number',
                    children: i18next.t(`item-props.type.number`),
                  },
                  {
                    icon: <TextIcon />,
                    value: 'string',
                    children: i18next.t(`item-props.type.string`),
                  },
                  {
                    icon: <Range />,
                    value: 'range',
                    children: i18next.t(`item-props.type.range`),
                  },
                ]}
              />
            )}
          />
          <ErrorMessage
            name="config.property_type"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        {type === 'range' && (
          <div className="flex justify-between gap-2">
            <FieldGroup label={i18next.t('item-props.type.range.min')} className="w-1/2">
              <Input
                {...register('config.min', {
                  required: i18next.t('validation.required'),
                  setValueAs: value => {
                    return parseInt(value)
                  },
                })}
                type={'number'}
              />
              <ErrorMessage
                name="config.min"
                errors={errors}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </FieldGroup>
            <FieldGroup label={i18next.t('item-props.type.range.max')} className="w-1/2">
              <Input
                {...register('config.max', {
                  required: i18next.t('validation.required'),
                  setValueAs: value => {
                    return parseInt(value)
                  },
                })}
                type={'number'}
              />
              <ErrorMessage
                name="config.max"
                errors={errors}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </FieldGroup>
          </div>
        )}

        <FieldGroup label={i18next.t('item-props.image_url')}>
          <Controller
            control={control}
            name="icon_url"
            render={({ field }) => (
              <ImageUploader
                accept={{
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'image/png': [],
                  'image/webp': [],
                }}
                {...field}
              />
            )}
          />
          <ErrorMessage
            name="icon_url"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <div className="inline-block">
          {errorMessage && <FieldValidationMessage> {errorMessage} </FieldValidationMessage>}
        </div>

        <ModalFooter>
          <Button size="lg" onClick={() => props.onClose?.()}>
            {i18next.t('Cancel')}
          </Button>
          <Button size="lg" variant="primary" type="submit">
            {i18next.t(props.item.id ? 'Save2' : 'Add')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
