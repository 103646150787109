import { ItemOrderType, ItemRead, ItemType, ResourceState } from '@/api/dashboard'

import { generatePath, useParams } from 'react-router-dom'

import {
  Button,
  FieldGroup,
  Select,
  SelectMultiple,
  SelectMultipleOption,
  SelectMultipleProps,
  SelectOption,
  SelectProps,
  TableZeroState,
} from '@/ui'
import ImageCellNoText from '../../../../components/ImageCellNoText'
import { CONTEXT_ITEM } from '../../editors/common'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '../../../../Settings'
import { getItemName } from '../../../../api/getItemName'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { SKU_ITEMS_NEW_PATH } from '../../../../libs/routerPaths'
import { PriceView } from '@/layouts/game-items/components/PriceView'
import { dashboardClient } from '@/api'
import { useInfiniteQuery } from '@tanstack/react-query'
import { ArrowDown, InputSearch } from '@dashboard/ui'
import { useDebounce } from '@/hooks'
import { CloseOutline } from '@/icons'

// @ts-ignore
interface SelectSkuProps extends SelectProps {
  type?: ItemType | undefined
  options?: SelectOption[]
  label?: string
  error?: string
  multiselect?: boolean
  mode?: 'single' | 'multiple'
  value: string | string[] | undefined | null
  onChange: (value: SelectOption['value'] | SelectMultipleOption['value'][]) => void
  contextItemText?: string
  hideIds?: string[]
  showPrice?: boolean
  showLinkToCreateNewSKU?: boolean
  caption?: string
}

const LOAD_LIMIT = 50

const fetchPage = async (
  companyId: string,
  gameId: string,
  pageParam: number,
  search_string: string,
  type?: ItemType,
) => {
  const { data } = await dashboardClient.v1.getItems(companyId, gameId, {
    limit: LOAD_LIMIT,
    offset: pageParam * LOAD_LIMIT,
    state: ResourceState.Active,
    search_string: search_string,
    order_by: ItemOrderType.Name,
    types: type,
  })
  return data
}

export const SelectSkuItem = (props: SelectSkuProps) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const [additionalItems, setAdditionalItems] = useState<ItemRead[]>([])

  const result = useInfiniteQuery({
    queryKey: [gameId, 'infinity-items', debouncedSearch],
    initialPageParam: 0,
    queryFn: ({ pageParam = 0 }) => fetchPage(companyId, gameId, pageParam, debouncedSearch, props.type),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length === 0 || lastPage.length < LOAD_LIMIT) {
        return undefined
      }
      return pages.length
    },
  })

  const loadValueItems = async () => {
    if (!props.value) {
      return
    }

    const ids: string[] = Array.isArray(props.value) ? (props.value as string[]) : [props.value as string]
    if (!ids.length) {
      return
    }

    const allItems = result.data?.pages.flat() || []

    const notLoadedItemsIds = ids
      .filter(id => id != CONTEXT_ITEM)
      .filter(id => !additionalItems.find(it => it.id == id) && !allItems.find(it => it.id == id))

    if (!notLoadedItemsIds.length) {
      return
    }

    const { data } = await dashboardClient.v1.getItems(companyId, gameId, {
      ids: notLoadedItemsIds.join(','),
    })
    setAdditionalItems(data)
  }

  useEffect(() => {
    loadValueItems()
  }, [props.value])

  useEffect(() => {
    result.refetch()
  }, [debouncedSearch])

  let visibleItems = result.data?.pages.flat() || []

  if (props.hideIds) {
    visibleItems = visibleItems.filter(it => !props.hideIds || !props.hideIds.includes(it.id))
  }

  if (additionalItems.length) {
    for (let additionalItem of additionalItems) {
      if (!visibleItems.find(it => it.id === additionalItem.id)) {
        visibleItems.push(additionalItem)
      }
    }
  }

  let options = visibleItems.map(it => {
    let price: ReactNode = props.showPrice !== false ? <PriceView item={it} /> : <></>

    return {
      value: it.id as string,
      icon: (
        <div
          className="size-[38px] shrink-0 rounded-md bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${it.image_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM})`,
          }}
        />
      ),
      desc: <div className="w-full truncate text-xs	font-medium leading-none text-text-quarterary-hover">{it.sku}</div>,
      extraRight: price as unknown as string,
      children: getItemName(it),
    }
  })

  if (props.contextItemText) {
    options.unshift({
      value: CONTEXT_ITEM,
      icon: <ImageCellNoText image_size="38px" image_url={'/icons/item-placeholder.webp'} />,
      desc: undefined as unknown as ReactElement,
      extraRight: undefined as unknown as string,
      children: props.contextItemText,
    })
  }

  const lastPage = result.data?.pages[result.data.pages.length - 1] || []
  const hasNext = lastPage.length > 0 && lastPage.length === LOAD_LIMIT

  const loadMore = (
    <Button
      size="sm"
      variant="text"
      onClick={e => {
        if (hasNext) {
          result.fetchNextPage()
        }
        e.stopPropagation()
        e.preventDefault()
      }}
      className="mt-2 w-full"
    >
      <ArrowDown />
      {t('player.webhook-log.load-more')}
    </Button>
  )

  const extraTop = (
    <div className="border-b border-border-secondary p-3">
      <InputSearch
        value={search}
        onFocus={e => {
          e.stopPropagation()
        }}
        onMouseDown={e => {
          e.stopPropagation()
        }}
        onChange={e => {
          setSearch(e.target.value)
        }}
        placeholder={t('select-sku.search.place-holder')}
      />
    </div>
  )

  const extraDown = hasNext
    ? loadMore
    : options.length === 0 &&
      !result.isLoading &&
      search && (
        <div className="mt-2 h-[200px]">
          <TableZeroState
            title={t('sku.search.zero.title')}
            message={t('sku.search.zero.text')}
            onClick={e => e.stopPropagation()}
            buttons={
              <Button
                variant="outline"
                onClick={e => {
                  e.stopPropagation()
                  setSearch('')
                }}
              >
                <CloseOutline size={14} />
                <span>{t('sku.search.clear-filters')}</span>
              </Button>
            }
          />
        </div>
      )

  let content = props.multiselect ? (
    <SelectMultiple
      {...(props as SelectMultipleProps)}
      disableSearch={true}
      extraDown={extraDown}
      extraTop={extraTop}
      options={options}
      placeholder={t('select-sku')}
    />
  ) : (
    <Select
      {...(props as SelectProps)}
      name="select-sku"
      options={options}
      extraDown={extraDown}
      extraTop={extraTop}
      placeholder={t('select-skus')}
    />
  )

  if (!props.label) {
    return content
  }

  let caption: ReactElement | null | string | undefined = props.caption
  if (!search && visibleItems.length == 0 && props.showLinkToCreateNewSKU) {
    const link = generatePath(SKU_ITEMS_NEW_PATH, { companyId: companyId, gameId: gameId })

    caption = <Trans i18nKey="select-sku.stackable-sku" values={{ link: link }} components={{ a: <a /> }} />
  }

  return (
    <FieldGroup label={props.label} error={props.error} caption={caption}>
      {content}
    </FieldGroup>
  )
}
