import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { generatePath, useNavigate, useParams } from 'react-router-dom'
import i18next from 'i18next'
import { dashboardClient } from '../../api'
import { NewsBulkUpdate, PageLayoutType, PageRead } from '../../api/dashboard'
import {
  Badge,
  Button,
  ButtonIcon,
  Checkbox,
  ConfirmDialog,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableRowEditButton,
  TableZeroState,
  useModal,
  usePagination,
} from '@/ui'
import { usePagesQuery } from './api/usePagesQuery'
import { HUB_PAGES_DETAIL_PATH, HUB_PAGES_PARENT_PATH } from '../../libs/routerPaths'
import EditPageDialog from './EditPageDialog'
import { SidebarSection } from '../../components/ui/AppSidebar/SidebarSection'
import { AppMenuItem, PermissionType } from '../../components/ui/AppSidebar/MenuData'
import { MenuList } from '../../components/ui/AppSidebar/MenuList'
import { Collection } from '../../components/ui/AppSidebar/icons/Collection'
import { EditIcon, PlusIcon } from '../../icons/Icons'
import dayjs from 'dayjs'
import EditCollectionDialog from './EditCollectionDialog'
import { ExternalLink, Plus, Trash, XClose } from '@/icons'
import { usePageDeleteMutate } from './api/usePageDeleteMutate'
import { PagesMoveModal, PagesMoveModalData, PagesRowSkeleton } from './components'
import IconButton from '@mui/material/IconButton'
import { useWebsitesQuery } from '../../api/useWebsitesQuery'
import { TableCellCheckBox } from '../../components/ui/Table/TableCellCheckBox'
import ConfirmPublishDialog from '../components/ConfirmPublishDialog/ConfirmPublishDialog'
import { getNow } from '../../util'
import { getDateFormatParams } from '@/libs/dates'
import { AuthContext, IAuthContext } from '@/Context'
import { canEdit } from '@/security'
import { useTranslation } from 'react-i18next'

const DEFAULT_PLACEHOLDER = `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/articles-placeholder.png`

export default function PagesPage() {
  const navigate = useNavigate()
  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = canEdit(context.customer)
  const { t } = useTranslation()
  const { companyId, gameId, pageId, parent } = useParams() as {
    companyId: string
    gameId: string
    parent: string
    pageId?: string
  }

  const [selectedSet, setSelectedSet] = useState<Set<string>>(new Set())
  const [editPost, setEditPost] = useState<PageRead | undefined>(undefined)

  const { page, query, onPageChange } = usePagination(10)
  const [deleteConfirmItem, setDeleteConfirmItem] = useState<PageRead | undefined>(undefined)
  const [deleteConfirmCollection, setDeleteConfirmCollection] = useState<PageRead | undefined>(undefined)

  const [deleteConfirmBulk, setDeleteConfirmBulk] = useState(false)
  const [confirmPublish, setConfirmPublish] = useState<boolean | null>(null)

  const { mutateAsync: deleteMutateAsync } = usePageDeleteMutate(companyId, gameId)
  const { data: websites = [] } = useWebsitesQuery(companyId, gameId)
  const { data: collections = [], refetch: refetchCollections } = usePagesQuery(companyId, gameId, {
    types: [PageLayoutType.Collection],
  })

  const currentParrent = useMemo(() => collections.find(c => c.slug === parent), [collections, parent])

  const openMoveModal = useModal<PagesMoveModalData>(props => <PagesMoveModal {...props} />)

  const {
    data: items = [],
    isLoading,
    refetch,
  } = usePagesQuery(companyId, gameId, {
    types: [PageLayoutType.Content, PageLayoutType.Article],
    ...(currentParrent?.id && { parent_id: currentParrent.id }),
    ...query,
  })

  const refetchAll = async () => {
    await refetch()
    await refetchCollections()
  }

  const onDeleteConfirmCollection = async () => {
    if (deleteConfirmCollection) {
      await deleteMutateAsync({ id: deleteConfirmCollection.id })
    }
    setDeleteConfirmCollection(undefined)

    navigate(
      generatePath(HUB_PAGES_PARENT_PATH, {
        companyId,
        gameId,
        parent: 'uncategorized',
      }),
    )
  }

  const onDeleteConfirmPage = async () => {
    if (deleteConfirmItem) {
      await deleteMutateAsync({ id: deleteConfirmItem.id })
    }
    setDeleteConfirmItem(undefined)
  }

  const onConfirmDeletePages = async () => {
    setDeleteConfirmBulk(false)
    await dashboardClient.v1.bulkDeletePage(
      companyId,
      gameId,
      Array.from(selectedSet).map(id => ({ id })),
    )

    setSelectedSet(new Set())
    refetch()
  }

  useEffect(() => {
    loadPost()
  }, [pageId])

  useEffect(() => {
    setSelectedSet(new Set())
  }, [parent, page])

  const loadPost = async () => {
    if (pageId) {
      if (pageId == 'new') {
        setEditPost({} as PageRead)
      } else if (pageId == 'collection') {
        setEditPost({ type: PageLayoutType.Collection } as PageRead)
      } else {
        let { data } = await dashboardClient.v1.getPage(companyId, gameId, pageId)
        setEditPost(data)
      }
    } else {
      setEditPost(undefined)
      refetchAll()
    }
  }

  const getCommonItem = (p?: PageRead): Partial<AppMenuItem> => {
    return {
      to: generatePath(HUB_PAGES_PARENT_PATH, {
        companyId,
        gameId,
        parent: p ? p.slug : 'uncategorized',
      }),
      text: p ? p.title : t('pages.edit.uncategorized'),
      permission: PermissionType.editor,
      icon: Collection,
      className: 'group-[.active]:bg-gray-100 group-hover:bg-gray-100',
      secondaryAction:
        p && canUserEdit ? (
          <TableRowEditButton
            onChange={v => {
              switch (v) {
                case 'edit':
                  openEditCollectionModal({ item: p })
                  break
                case 'remove':
                  setDeleteConfirmCollection(p)
                  break
              }
            }}
            options={[
              {
                icon: <EditIcon />,
                children: i18next.t('grid.edit'),
                value: 'edit',
              },
              {
                icon: (
                  <span className="text-text-error-primary">
                    <Trash />
                  </span>
                ),
                children: <span className="text-text-error-primary"> {i18next.t('grid.remove')} </span>,
                value: 'remove',
              },
            ]}
          />
        ) : undefined,
    }
  }

  const menuItems: AppMenuItem[] = useMemo(
    () => [getCommonItem(), ...collections.map(c => getCommonItem(c))] as AppMenuItem[],
    [companyId, gameId, collections, parent],
  )

  const onAddPageClick = () => {
    navigate(
      generatePath(HUB_PAGES_DETAIL_PATH, {
        companyId,
        gameId,
        parent: parent || 'uncategorized',
        pageId: 'new',
      }),
    )
  }

  const getItemsForPublishUpdate = (toPublish: boolean) => {
    return items.filter(it => {
      if (!selectedSet.has(it.id)) {
        return false
      }

      return toPublish != !!it.published_at
    })
  }

  const changePublishClick = async (value: boolean) => {
    setConfirmPublish(null)
    const updateFormUpdate = getItemsForPublishUpdate(value)

    let updateArr = updateFormUpdate.map(
      it =>
        ({
          id: it.id,
          published_at: value ? getNow() : null,
        }) as NewsBulkUpdate,
    )

    await dashboardClient.v1.bulkUpdatePage(companyId, gameId, updateArr)

    refetch()
  }

  const onClickBulkMove = useCallback(() => {
    openMoveModal({
      items: items.filter(item => selectedSet.has(item.id)),
      collection: currentParrent,
    })
  }, [openMoveModal, items, selectedSet, currentParrent])

  const renderDefaultHeader = () => {
    return (
      <TableRow variant="header">
        {canUserEdit && (
          <TableCell width={32}>
            <span
              style={{
                visibility: items.length == 0 ? 'hidden' : 'visible',
              }}
            >
              <Checkbox
                checked={items.length > 0 && selectedSet.size == items.length}
                onChange={() => {
                  setSelectedSet(selectedSet.size == items.length ? new Set() : new Set(items.map(it => it.id)))
                }}
              />
            </span>
          </TableCell>
        )}
        <TableCell width="100%">{i18next.t('pages.table.title')}</TableCell>
        <TableCell width="20%">{i18next.t('pages.table.date')}</TableCell>
        <TableCell width="20%">{i18next.t('pages.table.state')}</TableCell>
        <TableCell width="10%" />
        <TableCell width="10%" />
      </TableRow>
    )
  }

  const renderEditSelection = () => {
    const hasPublishItems = items.some(it => selectedSet.has(it.id) && it.published_at)
    const hasUnPublishItems = items.some(it => selectedSet.has(it.id) && !it.published_at)

    return (
      <TableRow className="h-[45px] px-1.5">
        <TableCell width={32} className="p-1.5">
          <Checkbox
            checked={selectedSet.size == items.length}
            onChange={() => {
              setSelectedSet(selectedSet.size == items.length ? new Set() : new Set(items.map(it => it.id)))
            }}
          />
        </TableCell>
        <TableCell className="flex items-center gap-1 p-1.5">
          <span className="font-semibold text-text-primary" style={{ lineHeight: '12px' }}>
            {i18next.t('sku.items.count-selected', { count: selectedSet.size })}
          </span>
          <ButtonIcon variant="tertiary-gray" size="xs" onClick={() => setSelectedSet(new Set())}>
            <XClose />
          </ButtonIcon>

          <div className="ml-3 flex items-center gap-1">
            <Button size="md" onClick={() => changePublishClick(true)} disabled={!hasUnPublishItems}>
              {i18next.t('edit-news-dialog.publish')}
            </Button>

            <Button size="md" onClick={() => setConfirmPublish(false)} disabled={!hasPublishItems}>
              {i18next.t('edit-news-dialog.unpublish')}
            </Button>

            <Button size="md" onClick={onClickBulkMove}>
              {i18next.t('edit-news-dialog.move')}
            </Button>

            <Button onClick={() => setDeleteConfirmBulk(true)} variant="text">
              <div className="flex gap-2 text-text-error-primary">
                <Trash />
                {i18next.t('remove')}
              </div>
            </Button>
          </div>
        </TableCell>
      </TableRow>
    )
  }

  let showPaginator = !isLoading
  if (items.length === 0 && page == 1) {
    showPaginator = false
  }

  let showAddButton = canUserEdit
  if (!isLoading && items.length === 0 && page == 1) {
    showAddButton = false
  }

  const webSite = websites[0]

  let queryParams = ''
  if (page > 1) {
    queryParams = `?page=${page}`
  }

  const openEditCollectionModal = useModal<{ item: PageRead }>(props => (
    <EditCollectionDialog
      {...props}
      onCreated={createdPage => {
        navigate(
          generatePath(HUB_PAGES_PARENT_PATH, {
            companyId,
            gameId,
            parent: createdPage.slug,
          }),
        )
      }}
    />
  ))

  return (
    <>
      {editPost && (
        <EditPageDialog
          item={editPost}
          parents={collections}
          onClose={(saved: boolean) => {
            navigate(-1)
            if (saved) {
              refetchAll()
            }
          }}
        />
      )}

      {deleteConfirmItem && (
        <ConfirmDialog
          color={'error'}
          message={i18next.t('pages.confirm.delete-page')}
          confirmButtonText={i18next.t('remove')}
          subMessage={i18next.t('pages.confirm.delete-page.text', { name: deleteConfirmItem.title })}
          onCancel={() => setDeleteConfirmItem(undefined)}
          onConfirm={onDeleteConfirmPage}
        />
      )}

      {deleteConfirmCollection && (
        <ConfirmDialog
          color={'error'}
          message={i18next.t('pages.confirm.delete-collection')}
          confirmButtonText={i18next.t('remove')}
          subMessage={i18next.t('pages.confirm.delete-collection.desc')}
          onCancel={() => setDeleteConfirmCollection(undefined)}
          onConfirm={onDeleteConfirmCollection}
        />
      )}

      {deleteConfirmBulk && (
        <ConfirmDialog
          color={'error'}
          confirmButtonText={i18next.t('remove')}
          message={i18next.t('pages.confirm.remove-n-title')}
          subMessage={i18next.t('pages.confirm.remove-n-title.message', { N: selectedSet.size })}
          onCancel={() => setDeleteConfirmBulk(false)}
          onConfirm={onConfirmDeletePages}
        />
      )}

      {confirmPublish !== null && (
        <ConfirmPublishDialog
          confirmPublish={confirmPublish}
          count={getItemsForPublishUpdate(confirmPublish).length}
          forms={{
            one: i18next.t('pages.pages.one'),
            many: i18next.t('pages.pages.many'),
            few: i18next.t('pages.pages.few'),
            other: i18next.t('pages.pages.other'),
          }}
          onCancel={() => setConfirmPublish(null)}
          onConfirm={() => changePublishClick(confirmPublish)}
        />
      )}

      <div className="flex h-full flex-col">
        <PageHeader
          extra={
            showAddButton && (
              <Button variant="primary" type="button" onClick={onAddPageClick}>
                {i18next.t('pages.add')}
              </Button>
            )
          }
        >
          {i18next.t('pages.title')}
        </PageHeader>

        <div className="flex h-full rounded-none border-y border-border-primary">
          <div className="h-full">
            <nav className="flex h-full w-64 min-w-64 flex-col overflow-y-auto border-r py-3">
              <SidebarSection label={i18next.t('pages.collections')}>
                <MenuList items={menuItems} />
              </SidebarSection>
              {canUserEdit && (
                <Button
                  size="md"
                  className="mx-3 mt-4"
                  onClick={() =>
                    openEditCollectionModal({
                      item: {
                        type: PageLayoutType.Collection,
                        title: '',
                        slug: '',
                        date: dayjs().format('YYYY-MM-DD'),
                        html: '',
                        description: '',
                        text: '',
                      } as PageRead,
                    })
                  }
                >
                  <PlusIcon size={16} />
                  {i18next.t('pages.collections-add')}
                </Button>
              )}
            </nav>
          </div>
          <div className="flex size-full flex-col">
            <Table>
              {selectedSet.size == 0 ? renderDefaultHeader() : renderEditSelection()}

              {isLoading && new Array(5).fill('').map((_, index) => <PagesRowSkeleton key={`skeleton-${index}`} />)}
              {!isLoading &&
                items.map((it, idx) => (
                  <TableRow
                    key={idx}
                    to={
                      canUserEdit
                        ? generatePath(HUB_PAGES_DETAIL_PATH, {
                            companyId,
                            gameId,
                            parent: currentParrent?.slug || 'uncategorized',
                            pageId: it.id,
                          }) + queryParams
                        : ''
                    }
                  >
                    {canUserEdit && (
                      <TableCellCheckBox id={it.id} selectedSet={selectedSet} setSelectedSet={setSelectedSet} />
                    )}
                    <TableCell width="100%">
                      <div className="flex items-center gap-6">
                        <div
                          style={{
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${it.image_url || DEFAULT_PLACEHOLDER})`,
                            borderRadius: '4px',
                            height: '38px',
                            minWidth: '76px',
                          }}
                        />
                        {it.title}
                      </div>
                    </TableCell>
                    <TableCell width="20%">
                      {it.date ? i18next.t('intl.DateTime', getDateFormatParams(it.date)) : ''}
                    </TableCell>
                    <TableCell width="20%">
                      {it.published_at ? (
                        <Badge variant="green-secondary">{i18next.t('table-news.state.published')}</Badge>
                      ) : (
                        <Badge variant="gray-secondary">{i18next.t('table-news.state.draft')}</Badge>
                      )}
                    </TableCell>
                    <TableCell width="10%">
                      <IconButton
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          let base = webSite.url
                          let parentSlug = currentParrent?.slug
                          const href = `${base}/${parentSlug ? `${parentSlug}/` : ''}${it.slug}`
                          window.open(href)
                        }}
                      >
                        <ExternalLink width={24} height={24} />
                      </IconButton>
                    </TableCell>
                    {canUserEdit && (
                      <TableCell width="10%">
                        <TableRowEditButton
                          onChange={v => {
                            switch (v) {
                              case 'remove':
                                setDeleteConfirmItem(it)
                                break
                            }
                          }}
                          options={[
                            {
                              icon: <Trash className="text-text-error-primary" />,
                              children: <span className="text-text-error-primary"> {i18next.t('grid.remove')} </span>,
                              value: 'remove',
                            },
                          ]}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </Table>

            {!isLoading && items.length === 0 && (
              <div className="h-full p-4">
                <TableZeroState
                  title={i18next.t(canUserEdit ? 'pages.empty-table.title' : 'pages.empty-table.title.read-only')}
                  message={i18next.t(canUserEdit ? 'pages.empty-table' : 'pages.empty-table.read-only')}
                  buttons={
                    canUserEdit && (
                      <Button variant="primary" onClick={onAddPageClick}>
                        <Plus size={14} />
                        <span>{i18next.t('pages.add')}</span>
                      </Button>
                    )
                  }
                />
              </div>
            )}

            {showPaginator && (
              <Pagination
                hasNext={items.length >= query.limit}
                page={page}
                onPageChange={onPageChange}
                pageItems={items.length}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
