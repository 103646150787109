import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Button, Select, SelectItem, formatHookFormErrors } from '@dashboard/ui'
import { Locale, LocaleCreate } from '@/api/dashboard'
import { FieldGroup, Modal, ModalFooter, ModalProps, ModalTitle } from '@/ui'
import { LOCALIZATION_LOCALE_PATH, useGenerateCompanyPath } from '@/libs'
import { LOCALE_FLAGS } from '@/util'
import { localeCreateMutation, localesQueryOptions } from '../api'

interface L10nLocaleModalAddProps extends ModalProps {}

export const L10nLocaleModalAdd = ({ ...rest }: L10nLocaleModalAddProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { generatePath, companyId, gameId } = useGenerateCompanyPath()
  const { data: locales = [], isLoading } = useQuery(localesQueryOptions(companyId, gameId))
  const { mutateAsync } = localeCreateMutation()

  const {
    formState: { errors, isSubmitting, isSubmitSuccessful },
    handleSubmit,
    control,
  } = useForm<LocaleCreate>({
    defaultValues: {
      locale: undefined,
    },
  })

  const availableLocaleOptions: SelectItem[] = useMemo(
    () =>
      Object.values(Locale)
        .sort((a, b) => a.localeCompare(b))
        .filter(locale => !locales.map(v => v.locale).includes(locale) && locale !== Locale.En)
        .map(locale => ({
          children: t(`locales.${locale}`),
          icon: () => (
            <img className="h-[18px] rounded-sm" src={`${LOCALE_FLAGS}/${locale.toUpperCase()}.svg`} alt={locale} />
          ),
          value: locale,
        })),
    [locales],
  )

  const onSubmit = handleSubmit(async data => {
    const locale = await mutateAsync({ data })
    navigate(generatePath(LOCALIZATION_LOCALE_PATH, { localeId: locale.id }))
  })

  return (
    <Modal {...rest} isLoading={isLoading}>
      <ModalTitle>{t('localization.locale.add-modal.title')}</ModalTitle>

      <form onSubmit={onSubmit}>
        <FieldGroup label={t('localization.locale.add-modal.label')}>
          <Controller
            control={control}
            name="locale"
            rules={{ required: t('validation.required') }}
            render={({ field }) => (
              <Select
                {...field}
                {...formatHookFormErrors(errors, 'locale')}
                placeholder={
                  availableLocaleOptions.length
                    ? t('localization.locale.add-modal.placeholder')
                    : t('localization.locale.add-modal.no-available')
                }
                search={{
                  fn: (search, item) =>
                    item.value?.toString().toLowerCase().includes(search.toLowerCase()) ||
                    item.children?.toString().toLowerCase().includes(search.toLowerCase()) ||
                    false,
                  placeholder: t('search'),
                }}
                items={availableLocaleOptions}
                disabled={!availableLocaleOptions.length}
              />
            )}
          />
        </FieldGroup>

        <ModalFooter>
          <Button variant="outline" color="secondary" type="button" onClick={rest.onClose}>
            {t('Cancel')}
          </Button>
          <Button variant="default" loading={isSubmitting || isSubmitSuccessful}>
            {t('localization.locale.add-modal.confirm')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
