import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { CouponRead, HTTPValidationError, HttpResponse } from '@/api/dashboard'

type Query = {
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 100
   * @default 10
   */
  limit?: number
  /**
   * Offset
   * The number of objects to skip
   * @min 0
   */
  offset?: number
}

/**
 *
 * @param companyId
 * @param gameId
 * @param query
 */
export const useCouponQuery = (companyId: string, gameId: string, query: Query = {}) => {
  return useQuery({
    queryKey: ['coupons', gameId, query],
    queryFn: () => {
      if (query.limit == 0) {
        const res = { data: [] } as unknown as HttpResponse<CouponRead[], HTTPValidationError>
        return Promise.resolve(res)
      }

      return dashboardClient.v1.getCoupons(companyId, gameId, query)
    },
    select: response => response.data,
  })
}
