import { useContext } from 'react'
import i18next from 'i18next'
import { Node } from '../../api/dashboard'
import styled from '@emotion/styled'
import { Button } from '@/ui'
import { getNodeIcon } from './icons'
import { useTheme } from '@mui/material/styles'
import { CampaignContext, ICampaignContext } from './Context'

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 300;
  background-color: ${p => p.theme.palette.background.content};
  padding: 0 24px;
  height: 56px;
  min-height: 56px;
  border-bottom: 1px solid ${p => p.theme.palette.grey['250']};
  pointer-events: all;
`

const Buttons = styled.div`
  display: flex;
  gap: 12px;
`

export default function SettingsHeader(props: {
  node?: Node | undefined | null
  text?: string
  onClose: () => void
  onSave?: () => void
}) {
  const theme = useTheme()
  const context = useContext(CampaignContext) as ICampaignContext
  return (
    <Header>
      <HeaderText>
        {props.node && getNodeIcon(props.node.model_type, theme)}
        {props.node ? i18next.t(`campaign.block.${props.node.model_type}.name`) : props.text}
      </HeaderText>
      <Buttons>
        <Button onClick={props.onClose} data-testid="settings-close-button">
          {i18next.t('close')}
        </Button>
        {!context.readOnly && props.onSave && (
          <Button color="primary" variant="primary" data-testid="sku/save" onClick={props.onSave}>
            {i18next.t('Save2')}
          </Button>
        )}
      </Buttons>
    </Header>
  )
}
