import { NavLink } from 'react-router-dom'
import { ReactNode, createElement } from 'react'
import { Skeleton } from '@dashboard/ui'
import { AghanimIcon } from '@/icons'
import { cn, isAghanimIcon } from '@/libs'

interface SidebarLinkProps {
  children: ReactNode
  to: string
  icon?: AghanimIcon | ReactNode
  extra?: ReactNode
  /**
   * Always show the extra component.
   * > It's useful if the floating menu
   */
  extraAlways?: boolean
}

export const SidebarLink = ({ children, icon, extra, extraAlways, ...rest }: SidebarLinkProps) => {
  return (
    <NavLink
      {...rest}
      className={cn(
        'group/sidebar-link relative mb-1 flex min-h-8 items-center gap-2 rounded-md px-2 py-1 text-text-secondary last:mb-0',
        'hover:bg-fg-primary-hover hover:text-text-secondary-hover',
        'active:bg-fg-primary-alt active:text-text-primary [&.active]:bg-fg-primary-alt',
      )}
      role="option"
      aria-controls="product_form"
    >
      {icon && (
        <div className="flex size-6 shrink-0 items-center justify-center">
          {/*  @ts-ignore */}
          {isAghanimIcon(icon) ? createElement(icon, { size: 16 }) : icon}
        </div>
      )}
      <div className="w-full overflow-hidden text-caption-md">{children}</div>

      {extra && (
        <div
          className={cn(
            'absolute right-2 ml-auto flex shrink-0 items-center gap-1 pl-32 transition-[opacity,visibility,transform]',
            'bg-gradient-to-r from-transparent to-transparent',
            'invisible translate-x-1 opacity-0',
            'group-hover/sidebar-link:visible group-hover/sidebar-link:translate-x-0 group-hover/sidebar-link:to-fg-primary-hover group-hover/sidebar-link:opacity-100 group-active/sidebar-link:to-fg-primary-alt group-[&.active]/sidebar-link:to-fg-primary-alt',
            extraAlways && 'visible translate-x-0 opacity-100',
          )}
        >
          {extra}
        </div>
      )}
    </NavLink>
  )
}

export const SidebarLinkSkeleton = () => {
  return (
    <div className="mb-1 h-8 w-full last:mb-0">
      <Skeleton className="size-full" />
    </div>
  )
}
