import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const PlayerPaymentInfo: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 16 16" ref={ref}>
      <g clip-path="url(#clip0_3489_614)">
        <path
          d="M0 5.71077C0 3.71181 0 2.71233 0.389023 1.94883C0.731217 1.27724 1.27724 0.731217 1.94883 0.389023C2.71233 0 3.71181 0 5.71077 0H10.2892C12.2882 0 13.2877 0 14.0512 0.389023C14.7228 0.731217 15.2688 1.27724 15.611 1.94883C16 2.71233 16 3.71181 16 5.71077V10.2892C16 12.2882 16 13.2877 15.611 14.0512C15.2688 14.7228 14.7228 15.2688 14.0512 15.611C13.2877 16 12.2882 16 10.2892 16H5.71077C3.71181 16 2.71233 16 1.94883 15.611C1.27724 15.2688 0.731217 14.7228 0.389023 14.0512C0 13.2877 0 12.2882 0 10.2892V5.71077Z"
          fill="url(#paint0_linear_3489_614)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.75353 5.12829H12.1029C12.7824 5.12829 13.3337 5.67957 13.3337 6.35906V12.1026C13.3337 12.7821 12.7824 13.3334 12.1029 13.3334H3.89776C3.21827 13.3334 2.66699 12.7821 2.66699 12.1026V4.71803C2.66699 4.3222 2.83847 4.04335 3.49552 3.89752L10.8721 2.66675C11.5516 2.66675 12.1029 2.80777 12.1029 3.48726V4.30777L3.07244 4.71803C3.07244 5.05617 3.39295 5.12829 3.75353 5.12829ZM7.99935 6.33341C8.80568 6.33341 9.46008 6.97144 9.46008 7.75758C9.46008 8.54372 8.80568 9.18174 7.99935 9.18174C7.19303 9.18174 6.53863 8.54372 6.53863 7.75758C6.53863 6.97144 7.19303 6.33341 7.99935 6.33341ZM10.3409 11.627C9.67481 12.0728 8.86849 12.3334 8.00081 12.3334C7.13314 12.3334 6.32536 12.0728 5.65927 11.627C5.36566 11.4305 5.24004 11.0545 5.41094 10.7497C5.7659 10.1174 6.4948 9.72292 8.00081 9.72292C9.50683 9.72292 10.2357 10.1174 10.5907 10.7497C10.7601 11.0559 10.6345 11.4305 10.3409 11.627Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_3489_614" x1="8" y1="0" x2="8" y2="16" gradientUnits="userSpaceOnUse">
          <stop stop-color="#38BDF8" />
          <stop offset="1" stop-color="#0EA5E9" />
        </linearGradient>
        <clipPath id="clip0_3489_614">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </IconBase>
  )
})
