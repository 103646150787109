import { useTranslation } from 'react-i18next'
import { LocaleRead } from '@/api/dashboard'
import { ModalConfirm, ModalProps } from '@/ui'
import { localeTranslateAllMutation } from '../api'

interface L10nLocaleModalConfirmTranslateAllProps extends ModalProps {
  locale: LocaleRead
}

export const L10nLocaleModalConfirmTranslateAll = ({ locale, ...rest }: L10nLocaleModalConfirmTranslateAllProps) => {
  const { t } = useTranslation()
  const { mutateAsync } = localeTranslateAllMutation()

  const onClickConfirm = async () => {
    await mutateAsync({ data: locale })
    rest.onClose?.()
  }

  return (
    <ModalConfirm
      {...rest}
      message={t('localization.ai.all.modal-confirm.title')}
      subMessage={t('localization.ai.all.modal-confirm.text', { locale: t(`locales.${locale?.locale}`) })}
      confirmButtonText={t('localization.ai.all.modal-confirm.confirm')}
      color="primary"
      onConfirm={onClickConfirm}
    />
  )
}
