import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import { StoreItemRead } from '../../../api/dashboard'
import {
  Button,
  FieldGroup,
  FieldValidationMessage,
  Input,
  InputExtraMark,
  Modal,
  ModalFooter,
  ModalProps,
  ModalTitle,
} from '@/ui'
import { ErrorMessage } from '@hookform/error-message'
import { ItemTimelimit, validateInterval } from '@/layouts/components/ItemTimelimit'
import { InfoCircle } from '@/icons'

export type BulkPropertyName = 'discount_percent' | 'bonus_percent' | 'start_at' | 'rotation_weight'

interface BulkEditCategoryModalProps extends ModalProps {
  items: StoreItemRead[]
  onEdit: (newItems: StoreItemRead[]) => void
  propertyName: BulkPropertyName
}

export const BulkEditModal = ({ items, ...rest }: BulkEditCategoryModalProps) => {
  const { t } = useTranslation()

  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<StoreItemRead>({
    values: {
      ...items[0],
    },
  })

  const onSubmit = async (data: StoreItemRead) => {
    if (rest.propertyName == 'start_at') {
      if (data.start_at && data.end_at) {
        const err = validateInterval(data.start_at, data.end_at, t)
        if (err) {
          setError('start_at', { message: err })
          return
        }
      }
    }

    const newItems = items.map(item => {
      let newItem = { ...item }
      switch (rest.propertyName) {
        case 'discount_percent':
          newItem.discount_percent = data.discount_percent
          break
        case 'bonus_percent':
          newItem.bonus_percent = data.bonus_percent
          break
        case 'start_at':
          newItem.start_at = data.start_at
          newItem.end_at = data.end_at
          break
        case 'rotation_weight':
          if (!data.rotation_weight) {
            newItem.rotation_weight = null as unknown as number
          } else {
            newItem.rotation_weight = data.rotation_weight
          }
          break
      }
      return newItem
    })
    rest.onClose?.()
    rest.onEdit(newItems)
  }

  const [end_at, start_at] = watch(['end_at', 'start_at'])

  const renderDiscount = () => {
    return (
      <FieldGroup label={t('store.item.discount')}>
        <Input
          autoFocus
          extraLeft={<InputExtraMark>%</InputExtraMark>}
          type={'number'}
          min={1}
          {...register('discount_percent', {
            validate: value => {
              if (value) {
                if (value < 0 || value > 99) {
                  return t('campaign.block.ItemDiscountOfferActionNode.discount-percent.error')
                }
                return true
              }
            },
          })}
        />
        <ErrorMessage
          name="discount_percent"
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      </FieldGroup>
    )
  }

  const renderBonus = () => {
    return (
      <FieldGroup label={t('store.item.bonus')}>
        <Input
          autoFocus
          extraLeft={<InputExtraMark>%</InputExtraMark>}
          type={'number'}
          min={1}
          {...register('bonus_percent', {
            required: false,
            min: 1,
            max: 1000,
          })}
        />
        <ErrorMessage
          name="bonus_percent"
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      </FieldGroup>
    )
  }

  const renderTimeLimitations = () => {
    return (
      <>
        <ItemTimelimit
          useSettingsContainer={false}
          showDuration={false}
          setValue={(prop, value) => {
            clearErrors('start_at')
            setValue(prop as 'start_at' | 'end_at', value, { shouldDirty: true })
          }}
          start_at={start_at}
          end_at={end_at}
        />
        <ErrorMessage
          name="start_at"
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      </>
    )
  }

  const renderWeightSettings = () => {
    return (
      <FieldGroup label={t('store.item.weight')}>
        <Input
          autoFocus
          {...register('rotation_weight', {
            min: { value: 0, message: t('store.item.weight.validation') },
            max: { value: 1000, message: t('store.item.weight.validation') },
          })}
          min={0}
          type={'number'}
        />
        <ErrorMessage
          name="rotation_weight"
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
        <div className="mt-1.5 flex items-start gap-2 text-text-secondary">
          <InfoCircle size={14} className="mt-1 min-w-[14px]" />
          <span className="text-sm leading-normal">{t('store.item.rotation_weight.desc')}</span>
        </div>
      </FieldGroup>
    )
  }

  const renderContent = () => {
    switch (rest.propertyName) {
      case 'discount_percent':
        return renderDiscount()
      case 'bonus_percent':
        return renderBonus()
      case 'start_at':
        return renderTimeLimitations()
      case 'rotation_weight':
        return renderWeightSettings()
    }
  }

  return (
    <Modal className="max-w-[600px]" {...rest}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalTitle>{t('store.item.confirm_bulk_edit', { count: items.length })}</ModalTitle>
        {renderContent()}
        <ModalFooter>
          <Button type="button" size="lg" onClick={rest.onClose}>
            {t('Cancel')}
          </Button>
          <Button type="submit" variant="primary" size="lg">
            {t('apply')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
