import { ForwardedRef, HTMLProps, useCallback, useState } from 'react';
import {
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
} from '@floating-ui/react';

export interface UseFloatingMenuParams {
  initialOpen?: boolean;
  placement?: Placement;
  onOpenChange?: (open: boolean) => void;
}

export const useFloatingMenu = <T extends HTMLElement>(
  ref: ForwardedRef<T>,
  { initialOpen = false, placement = 'bottom-start', onOpenChange: _onOpenChange }: UseFloatingMenuParams = {},
) => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const onOpenChange = useCallback(
    (open: boolean) => {
      setIsOpen(open);
      _onOpenChange?.(open);
    },
    [_onOpenChange, setIsOpen],
  );

  const floating = useFloating({
    open: isOpen,
    whileElementsMounted: autoUpdate,
    placement: placement,
    middleware: [
      shift({ crossAxis: false, padding: 4 }),
      offset(4),
      flip({ mainAxis: true }),
      size(() => ({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${Math.max(rects.reference.width, 176)}px`,
            width: `${rects.reference.width}px`,
          });
        },
      })),
    ],
    onOpenChange,
  });

  const referenceMergedRef = useMergeRefs([floating.refs.setReference, ref]);

  const { getReferenceProps: _getReferenceProps, getFloatingProps: _getFloatingProps } = useInteractions([
    useClick(floating.context),
    useDismiss(floating.context),
  ]);

  const getReferenceProps = useCallback(
    (props?: HTMLProps<Element>) => {
      return _getReferenceProps({
        ...props,
        // @ts-ignore
        'data-open': isOpen,
        ref: referenceMergedRef,
        onKeyDown: e => {
          // TODO: @ds.pankov refactor this, create keyux plugin.
          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
            (e.currentTarget as HTMLButtonElement).click();
          }
        },
      });
    },
    [isOpen, _getReferenceProps, referenceMergedRef],
  );

  const getFloatingProps = useCallback(
    (props?: HTMLProps<Element>) => {
      return _getFloatingProps({
        ...props,
        ref: floating.refs.setFloating,
        style: floating.floatingStyles,
      });
    },
    [_getFloatingProps, floating.refs.setFloating, floating.floatingStyles],
  );

  return {
    state: { isOpen, setIsOpen },
    floating,
    getReferenceProps,
    getFloatingProps,
  };
};
