import { useContext, useEffect, useMemo, useState } from 'react'
import { BrowserPopupNotificationActionNode, HubPopupContentType, Node } from '@/api/dashboard'
import { BlockError } from '../types'

import {
  TEMPLATE_PROPS,
  findPrevEntityNode,
  getMergedPreviewProps,
  getPreviewHubItemParams,
  renderTitleBodyProps,
} from '../util'
import { InputVars } from '../components/InputVars/InputVars'
import { useParams } from 'react-router-dom'
import { useWebsitesQuery } from '@/api/useWebsitesQuery'
import { CampaignContext, ICampaignContext } from '../Context'
import { useStoreItemsQuery } from '@/layouts/store/api/useStoreItemsQuery'
import { FieldGroup, FieldValidationMessage, ImageUploader, Input, Select } from '@/ui'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import SettingsHeader from '@/layouts/campaigns/SettingsHeader'
import { Settings } from '@/components/ui/Settings'
import SelectSkuPanel from '@/layouts/components/SelectSkuPanel'
import Divider from '@/components/Divider'
import { SpinnerApple } from '@/components/ui/SpinnerApple'
import { useTranslation } from 'react-i18next'
import { getContextItemText } from '@/layouts/campaigns/editors/common'
import { useGameItems } from '@/api/useGameItems'

let updateId: ReturnType<typeof setTimeout> | null = null

export default function HubPopupNodeEditor(props: {
  node: BrowserPopupNotificationActionNode
  error?: BlockError | null
  setNode: (node: Node) => void
  onClose: () => void
}) {
  const { t } = useTranslation()
  const context = useContext(CampaignContext) as ICampaignContext
  const [isLoadingPreview, setIsLoadingPreview] = useState(true)
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: websites = [] } = useWebsitesQuery(companyId, gameId)
  const { items = [] } = useGameItems()

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    control,
    getValues,
    register,
  } = useForm<BrowserPopupNotificationActionNode>({
    values: {
      ...props.node,
    },
  })

  const [
    link_created_offer,
    content_type,
    button_text,
    button_link,
    image_url,
    item_id,
    title,
    body,
    store_item_id,
    store_id,
  ] = watch([
    'link_created_offer',
    'content_type',
    'button_text',
    'button_link',
    'image_url',
    'item_id',
    'title',
    'body',
    'store_item_id',
    'store_id',
  ])

  const { data: storeItems = [] } = useStoreItemsQuery(companyId, gameId, store_id!)

  const [url, setUrl] = useState('')

  useEffect(() => {
    if (!websites.length) {
      return
    }

    if (updateId) {
      clearTimeout(updateId)
    }

    updateId = setTimeout(() => {
      let baseWebSiteUrl = websites[0].url

      if (window.location.href.startsWith('http://localhost')) {
        baseWebSiteUrl = 'http://localhost:3001'
      }

      let params: string[] = []
      //@ts-ignore
      let { merged_props, item, prev_node } = getMergedPreviewProps(getValues(), context, storeItems)

      if (content_type == HubPopupContentType.EventItem) {
        item = items[0]
      }

      if (item) {
        params.push(
          ...getPreviewHubItemParams(
            item,
            prev_node,
            storeItems?.find(it => it.id === store_item_id),
          ),
        )
      }

      if (title) {
        params.push(`title=${renderTitleBodyProps(title, merged_props)}`)
      }
      if (body) {
        params.push(`body=${renderTitleBodyProps(body, merged_props)}`)
      }

      if (button_link) {
        params.push(`extra_button_link=${button_link}`)
      }
      if (button_text) {
        params.push(`extra_button_text=${button_text}`)
      }
      if (image_url && content_type == HubPopupContentType.Custom) {
        params.push(`extra_image_url=${encodeURIComponent(image_url)}`)
      }

      let searchParams = params.join('&')
      setUrl(`${baseWebSiteUrl}/preview/popup?${searchParams}`)
      setIsLoadingPreview(true)
    }, 1500)
  }, [
    item_id,
    websites,
    title,
    body,
    link_created_offer,
    store_item_id,
    store_id,
    button_link,
    button_text,
    image_url,
    content_type,
  ])

  const prevEntityNode = useMemo(
    () => findPrevEntityNode(context.graph.nodes!, props.node.id),
    [context.graph.nodes, props.node.id],
  )

  const onSaveClick = (data: BrowserPopupNotificationActionNode) => {
    props.setNode(data as Node)
    props.onClose()
  }

  const contextItemText = getContextItemText(context.campaign) as string

  return (
    <div>
      <SettingsHeader onSave={handleSubmit(onSaveClick)} onClose={props.onClose} node={props.node as Node} />

      <div className="flex flex-col gap-3 p-6">
        <Settings text={t('coupon.general')}>
          <FieldGroup label={t('campaign.block.BrowserPopupNotificationActionNode.type')}>
            <Select
              value={link_created_offer ? 'offer' : content_type}
              onChange={value => {
                switch (value) {
                  case HubPopupContentType.Item:
                    setValue('link_created_offer', false)
                    setValue('content_type', HubPopupContentType.Item)
                    break
                  case 'offer':
                    setValue('link_created_offer', true)
                    setValue('content_type', null as unknown as HubPopupContentType)
                    setValue('item_id', null as unknown as string)
                    setValue('store_item_id', null as unknown as string)
                    setValue('store_id', null as unknown as string, { shouldDirty: true })
                    break
                  default:
                    setValue('content_type', value as HubPopupContentType)
                    setValue('link_created_offer', false)
                    setValue('item_id', null as unknown as string)
                    setValue('store_item_id', null as unknown as string)
                    setValue('store_id', null as unknown as string, { shouldDirty: true })
                    break
                }
              }}
              options={[
                {
                  value: HubPopupContentType.Custom,
                  children: t('campaign.block.BrowserPopupNotificationActionNode.custom'),
                },
                {
                  value: HubPopupContentType.Item,
                  children: t('campaign.block.BrowserPopupNotificationActionNode.item'),
                },
                contextItemText
                  ? {
                      value: HubPopupContentType.EventItem,
                      children: contextItemText,
                    }
                  : undefined,
                prevEntityNode
                  ? {
                      value: 'offer',
                      children: t('campaign.block.BrowserPopupNotificationActionNode.link_created_offer'),
                    }
                  : undefined,
              ]}
            />
          </FieldGroup>
        </Settings>

        <Divider />

        <Settings text={t('campaign.block.BrowserPopupNotificationActionNode.content')}>
          <div className="mb-4 flex flex-col gap-4">
            <InputVars
              value={title}
              onChange={e => {
                setValue('title', e.target.value)
              }}
              maxLength={50}
              items={[...TEMPLATE_PROPS.ITEM, ...TEMPLATE_PROPS.COUPON]}
              label={t('campaign.block.EmailActionNode.title')}
            />

            <InputVars
              maxLength={200}
              value={body}
              onChange={e => {
                setValue('body', e.target.value)
              }}
              items={[...TEMPLATE_PROPS.ITEM, ...TEMPLATE_PROPS.COUPON]}
              label={t('campaign.block.EmailActionNode.body')}
            />
          </div>

          {content_type == HubPopupContentType.Custom && (
            <>
              <FieldGroup label={t('campaign.block.BrowserPopupNotificationActionNode.custom_image')}>
                <Controller
                  control={control}
                  name="image_url"
                  render={({ field }) => (
                    <ImageUploader
                      accept={{
                        'image/jpeg': ['.jpeg', '.jpg'],
                        'image/png': [],
                        'image/webp': [],
                      }}
                      {...field}
                    />
                  )}
                />
                <ErrorMessage
                  name="image_url"
                  errors={errors}
                  render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
                />
              </FieldGroup>

              <FieldGroup label={t('campaign.block.BrowserPopupNotificationActionNode.button_text')}>
                <Input
                  {...register('button_text', {
                    maxLength: {
                      message: t('validation.maxLength', {
                        field: t('campaign.block.BrowserPopupNotificationActionNode.button_text'),
                        value: 55,
                      }),
                      value: 55,
                    },
                  })}
                  maxLength={60}
                />
                <ErrorMessage
                  name="button_text"
                  errors={errors}
                  render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
                />
              </FieldGroup>

              <FieldGroup label={t('campaign.block.BrowserPopupNotificationActionNode.button_link')}>
                <Input {...register('button_link')} />
              </FieldGroup>
            </>
          )}

          {content_type == HubPopupContentType.Item && (
            <Controller
              control={control}
              name="item_id"
              rules={{ required: t('validation.required') }}
              render={({ field }) => (
                <SelectSkuPanel
                  onChange={(item_id, store_id, store_item_id) => {
                    field.onChange(item_id)
                    setValue('store_item_id', store_item_id as unknown as string)
                    setValue('store_id', store_id as unknown as string)
                  }}
                  itemId={field.value}
                  storeId={store_id}
                  storeItemId={store_item_id}
                >
                  <ErrorMessage
                    name="item_id"
                    errors={errors}
                    render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
                  />
                </SelectSkuPanel>
              )}
            />
          )}
        </Settings>

        <Divider />

        <div>
          <FieldGroup label={t('campaign.block.BrowserPopupNotificationActionNode.preview')}>
            <div className="flex h-[700px] min-w-[300px] items-center justify-center overflow-hidden rounded-xl">
              {isLoadingPreview && (
                <div
                  style={{
                    backgroundColor: '#090808',
                  }}
                  className="flex size-full items-center justify-center text-text-quarterary"
                >
                  <SpinnerApple />
                </div>
              )}
              <iframe
                onLoad={() => {
                  setIsLoadingPreview(false)
                }}
                className="h-[700px] w-full"
                src={url}
                frameBorder={0}
                scrolling="no"
                style={{
                  background: "url('/icons/waiter.svg') center center no-repeat #fff",
                  overflowY: 'hidden',
                  pointerEvents: 'none',
                  display: isLoadingPreview ? 'none' : 'block',
                }}
              />
            </div>
          </FieldGroup>
        </div>
      </div>
    </div>
  )
}
