import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getHotKeyHint, likelyWithKeyboard } from 'keyux'
import { ErrorMessage } from '@hookform/error-message'
import { LocaleRead, State, TranslationRead } from '@/api/dashboard'
import { SSETranslationCallback, fetchSSETranslation } from '@/api/ai'
import { LexicalController, lexicalProseClassnames } from '@/components/lexical'
import { ButtonIcon, FieldValidationMessage, ModalRushTitle } from '@/ui'
import { AiTranslateOutline, ChevronRightOutline, XClose } from '@/icons'
import {
  LOCALIZATION_LOCALE_PATH,
  LOCALIZATION_TRANSLATION_PATH,
  cn,
  useGenerateCompanyPath,
  useNavigateBack,
} from '@/libs'
import { translationCreateOrUpdateMutation } from '../api'
import { isHtmlTranslation, stripHtml } from '../libs'
import { LocaleKnob } from './LocaleKnob'
import { Button, Skeleton } from '@dashboard/ui'

interface TranslationFormProps {
  locale: LocaleRead
  translation: TranslationRead
  onClose: () => void
}

const hotkeys = {
  submit: 'alt+enter',
}

const defaultAiTranslateState: { parts: string[]; isDone: boolean; isError: boolean } = {
  parts: [],
  isDone: false,
  isError: false,
}

export const TranslationForm = ({ translation, locale, onClose }: TranslationFormProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { generatePath } = useGenerateCompanyPath()
  const { mutateAsync } = translationCreateOrUpdateMutation()
  const [aiTranslate, setAiTranslate] = useState<{ parts: string[]; isDone: boolean; isError: boolean } | null>(null)

  const {
    formState: { isSubmitting, isSubmitSuccessful, isValid, errors },
    control,
    setValue,
    handleSubmit,
    register,
  } = useForm({
    defaultValues: {
      ...translation,
      content: translation.id === translation.original_id ? '' : translation.content,
    },
    mode: 'onChange',
  })

  const isHtmlContent = useMemo(() => (translation ? isHtmlTranslation(translation) : false), [translation])

  const title = useMemo(
    () => stripHtml(translation.label || translation.original_content || ''),
    [translation.label, translation.original_content],
  )

  const handleSSEEvent: SSETranslationCallback = useCallback(
    e => {
      switch (e.type) {
        case 'done':
          setAiTranslate(null)
          break
        case 'error':
          setAiTranslate(prev => ({ ...(prev || defaultAiTranslateState), isError: true }))
          break
        case 'part':
          setAiTranslate(prev => {
            const state = {
              ...(prev || defaultAiTranslateState),
              parts: [...(prev || defaultAiTranslateState).parts, e.payload],
            }
            setValue(
              'content',
              state.parts
                .filter(part => part !== '[done]')
                .map(part => (part === '' ? ' ' : part))
                .join(''),
              { shouldDirty: true, shouldValidate: true },
            )

            return state
          })

          break
      }
    },
    [setAiTranslate, setValue],
  )

  const onClickAiTranslate = () => {
    if (!translation.original_content) {
      return
    }

    setAiTranslate(defaultAiTranslateState)

    fetchSSETranslation({
      locale: locale.locale,
      content: translation.original_content,
      callback: handleSSEEvent,
    })
  }

  const onSubmit = handleSubmit(async data => {
    const isCreate = translation.id === translation.original_id

    const newTranslation = await mutateAsync({
      localeId: locale.id,
      data: {
        ...data,
        /**
         * If the translation is the original, we don't need to send the id.
         */
        id: isCreate ? undefined : translation.id,
        content: isHtmlContent ? data.content : stripHtml(data.content || ''),
        locale: locale.locale,
        state: State.Done,
      },
    })

    if (isCreate && newTranslation.id) {
      navigate(generatePath(LOCALIZATION_TRANSLATION_PATH, { localeId: locale.id, translationId: newTranslation.id }), {
        replace: true,
      })
    }

    onClose()
  })

  return (
    <form className="flex h-full flex-col" onSubmit={onSubmit}>
      <ModalRushTitle
        extra={
          <>
            <Button
              type="submit"
              loading={isSubmitting || isSubmitSuccessful}
              disabled={!isValid}
              aria-keyshortcuts={hotkeys.submit}
            >
              {t('Save2')}
              {likelyWithKeyboard(window) && <kbd>{getHotKeyHint(window, hotkeys.submit)}</kbd>}
            </Button>
            <ButtonIcon variant="secondary-gray" size="md-v2" onClick={onClose}>
              <XClose />
            </ButtonIcon>
          </>
        }
      >
        <span>{t(`localization.translation.type.${translation.object_type.toLowerCase()}`, { count: 1 })}</span>
        <ChevronRightOutline className="shrink-0 text-text-secondary" size={24} />
        <span className="truncate text-text-secondary">{title}</span>
      </ModalRushTitle>

      <div className="grid grid-cols-2 gap-5">
        <div className="mb-2.5 flex items-center gap-3">
          <div className="flex items-center gap-1.5 px-3 py-1.5">
            <LocaleKnob locale="en" />
            <span className="text-caption-sm">{t(`locales.en`)}</span>
          </div>
        </div>
        <div className="mb-2.5 flex items-center gap-3">
          <div className="flex items-center gap-1.5 px-3 py-1.5">
            <LocaleKnob locale={locale.locale} target={true} />
            <span className="text-caption-sm">{t(`locales.${locale.locale}`)}</span>
          </div>
          <Button variant="outline" size="sm" type="button" loading={!!aiTranslate} onClick={onClickAiTranslate}>
            <AiTranslateOutline size={14} />
            <span>{t('localization.ai.button')}</span>
          </Button>
        </div>
      </div>

      <div className="grid h-full grid-cols-2 gap-5 overflow-hidden">
        <div
          className={cn(
            'prose size-full max-w-none overflow-y-auto rounded-xl border border-border-primary bg-fg-secondary',
            lexicalProseClassnames,
          )}
        >
          {isHtmlContent ? (
            <div className="h-full overflow-y-auto">
              <div
                className="p-5"
                dangerouslySetInnerHTML={{
                  __html: translation.original_content || '',
                }}
              />
            </div>
          ) : (
            <div className="h-full overflow-y-auto">
              <div className="p-5">{translation.original_content}</div>
            </div>
          )}
        </div>
        <div className="flex h-full flex-col overflow-hidden">
          {isHtmlContent ? (
            <LexicalController
              control={control}
              rules={{ required: t('validation.required') }}
              name="content"
              className="h-full"
              isStream={!!aiTranslate}
            />
          ) : (
            <textarea
              {...register('content', {
                required: t('validation.required'),
                maxLength: {
                  message: t('localization.translation.validation.content-max-length', { value: 500 }),
                  value: 500,
                },
              })}
              className="prose size-full  max-w-none rounded-xl border border-border-primary p-5 outline-none"
              placeholder={translation.original_content}
              autoFocus={true}
            />
          )}

          <ErrorMessage
            name="content"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </div>
      </div>
    </form>
  )
}

export const TranslationFormSkeleton = () => {
  const back = useNavigateBack({ fallback: LOCALIZATION_LOCALE_PATH })

  return (
    <div className="flex h-full flex-col">
      <ModalRushTitle
        extra={
          <ButtonIcon variant="secondary-gray" size="md-v2" onClick={back.onClick}>
            <XClose />
          </ButtonIcon>
        }
      >
        <Skeleton className="h-6 w-96" />
      </ModalRushTitle>

      <div className="grid grid-cols-2 gap-5">
        <div className="mb-2.5 flex items-center gap-3 px-3 py-1.5">
          <Skeleton className="h-[18px] w-6" />
          <Skeleton className="h-4 w-1/6" />
        </div>
        <div className="mb-2.5 flex items-center gap-3 px-3 py-1.5">
          <Skeleton className="h-[18px] w-6" />
          <Skeleton className="h-4 w-1/6" />
        </div>
      </div>

      <div className="grid h-full grid-cols-2 gap-5">
        <Skeleton className="size-full rounded-xl" />
        <Skeleton className="size-full rounded-xl" />
      </div>
    </div>
  )
}
